import React, { JSX }  from "react";

import { Col, Row } from "antd";
import { useOutletContext } from "react-router-dom";

import PhotoCarousel from "../../../service/Photo-carousel";
import { reactiveVarHelper } from "common/helpers";
import StandType from "components/stand-type";

import type { TLocales } from "common/types";
import type { TUser } from "graphql/type/user-gql-types";

const StandTypeInfoSubPage : React.FC = () : JSX.Element => {

    const rVar = reactiveVarHelper(),
          me : TUser = rVar.get('me');

    const { stand } = useOutletContext<{ stand: any}>(),
          standType = stand.relStandType.find( ( { lang } : { lang : TLocales } ) => lang === me.lang_api);

    return(
        <Row className="page-content stand-type-info" gutter={[ 24, 16 ]}>
            <Col
                {...{ xxl : 6, xl : 7, lg : { order : 1, span : 8 }, xs : { order : 1, span : 12 }, span : 24 }}
            >
                <PhotoCarousel
                    photos={ standType.getImages }
                />
            </Col>
            <Col
                {...{ xxl : 8, xl : 8, lg : { order : 2, span : 8 }, xs : { order : 3 }, span : 24, }}
            >
                <StandType.Blocks.Description
                    standType = { standType }
                />
            </Col>
            <Col
                {...{ xxl : 6, xl : 7, lg : { order : 3, span : 8 }, xs : { order : 2, span : 12 }, span : 24 }}
            >
                <StandType.Blocks.Files
                    standTypeGroupId = { standType.group_id }
                />
                <StandType.Blocks.Notes
                    standTypeId={ stand.id }
                />
            </Col>
        </Row>
    );
};

export default StandTypeInfoSubPage;