import React, { JSX } from 'react';

import { useQuery } from "@apollo/client";
import { Table } from 'antd';

import { GET_STANDS } from "graphql/query/stand-q.gql";

import StandHelpers from "../helpers";
import { reactiveVarHelper } from "common/helpers";
import { whereConditionBuilder } from "common/utils";
import { Loader } from "components/request-result";

import type { TFilterFields } from "common/types";
import type { TID } from "graphql/type/common-gql-types";


type IStandsTableProps = {
    eeId    : TID
    filters : TFilterFields
};

const StandsTable : React.FC<IStandsTableProps> = ({ filters, eeId }) : JSX.Element  => {

    const rVar = reactiveVarHelper(),
        me = rVar.get('me');

    const { data : { standsCursor } = { },
        loading,
        // error
    } = useQuery( GET_STANDS, {
        variables : {
            text : filters.text,
            where : whereConditionBuilder({
                teamRole : !eeId ? {} : {
                    column : "EE_ID",
                    operator : "EQ",
                    value : eeId
                },
            }),
            orderBy : [ { column : "ID", order : "DESC" } ]
        },
        fetchPolicy : "cache-and-network"
    });

    const { edges : stands = [] } = standsCursor || {};

    const { columns, dataMap, totalColumnWidth } = StandHelpers.table;

    return(
        <div className="stands-table-wrap">
            { !!stands.length &&
                <Table
                    className   = "stands-table"
                    columns     = { columns! }
                    dataSource  = { dataMap( stands, me.lang_api ) }
                    scroll      = {{ x : totalColumnWidth() }}
                    pagination  = { false }
                />
            }
            { loading && <Loader type={ "block" } /> }
        </div>
    );
};

export default StandsTable;