import React, { JSX } from "react";

import { ECheckListType } from "../../check-list-types";
import CheckListFields from "../../fields";
import CheckListHelpers from "../../helpers";

import type {  IFieldBlockProps } from "../../check-list-types";


const fieldStructure = {
    responsible : undefined,
    when : undefined,
    coordinatedBy : undefined
};

interface ICheckListPlanningProps extends IFieldBlockProps{
    placeholder ?: string
}

const { parseValues } = CheckListHelpers;

const CheckListPlanningGroupBlock : React.FC<ICheckListPlanningProps> = ({
        values,
        ...props
    }) : JSX.Element => {

    const fields = parseValues<typeof values, typeof fieldStructure>(
        {
            values,
            structure : fieldStructure
    });

    return(
        <div className="check-list-group grid-three-col">
            <div className="col-one">
                <CheckListFields.Text
                    value={ fields.responsible }
                    multiField  = {{
                        current : 'responsible',
                        structure : fields
                    }}
                    placeholder={ "Verantwortlich" }
                    { ...props }
                />
            </div>
            <div className="col-two">
                <CheckListFields.DatePicker
                    value       = { fields.when }
                    placeholder = { "When" }
                    type        = { ECheckListType.DAYDATETIME }
                    multiField  = {{
                        current : 'when',
                        structure : fields
                    }}
                    { ...props }
                />
            </div>
            <div className="col-three">
                <CheckListFields.Text
                    value={ fields.coordinatedBy }
                    multiField  = {{
                        current : 'coordinatedBy',
                        structure : fields
                    }}
                    placeholder={ "Termine abgestimmt" }
                    { ...props }
                />
            </div>
        </div>
    );
};

export default CheckListPlanningGroupBlock;