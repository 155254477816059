import React, { JSX, useEffect, useState } from "react";

import { Input } from "antd";

import SearchIcon from "../icons/Search-icon";
import { useDebounce } from "components/use-hook";

import { TFilterFields } from "common/types";


type TSearchFieldProps = {
    filters: TFilterFields,
    setFilters : (props : TFilterFields) => void
};

const SearchField: React.FC<TSearchFieldProps> = ({ filters, setFilters }) : JSX.Element => {

    const [ text, setText ] = useState("" );

    const debouncedFilter = useDebounce(
        (newText) => {
                setFilters({
                    ...filters, text : newText,
                } as TFilterFields);
            }, 500
        );

    useEffect(() => {
        debouncedFilter(text);
    }, [ text ]);

    return(
        <div className="search-field">
            <Input
                placeholder={ "Type to search" }
                prefix={<SearchIcon />}
                className = "search-input"
                onChange  = { (e) =>  setText(e.target.value) }
                value = { text }
                name = "search-in-table"
            />
        </div>
    );
};

export default SearchField;