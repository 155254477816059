import ExhibitionEditForm from "./Exhibition-edit-form";
import ExhibitionHallEditForm from "./Exhibition-hall-edit-form";


const ExhibitionForms = {
    Edit : ExhibitionEditForm,
    EditHall : ExhibitionHallEditForm
};


export default ExhibitionForms;