import { gql } from '@apollo/client';


export const GET_ORGANIZERS = gql`
    query GetOrganizers ( 
        $text    : String
        $where   : QueryOrganizersCursorWhereWhereConditions
        $orderBy : [QueryOrganizersCursorOrderByOrderByClause!] 
        $after   : String
    ){
        organizersCursor(
            text    : $text
            where   : $where
            orderBy : $orderBy
            first   : 50
            after   : $after
        ) {
            pageInfo {
                hasNextPage
                startCursor
                endCursor
                total
                currentPage
            }
            edges {
                node{
                    id
                    title
                    logo_path
                    et_default_id
                    status
                    exhibitionTypes{
                        id
                        title
                        status
                        organizer_id
                    }
                }
            }
        }
    }
`;
