import TeamBlocks from "./blocks";
import TeamFields from "./fields";
import TeamModals from "./modals";


const Team = {
    Modals : TeamModals,
    Fields : TeamFields,
    Blocks : TeamBlocks
};

export default Team;