import React, { JSX } from "react";

import { Select } from "antd";

import { reactiveVarHelper } from "common/helpers";
import { Icons } from "components/layout";

import type { TVariableItem } from "graphql/type/common-gql-types";


const { Option } = Select;

interface ITaskTypeSelectProps {
    type     ?: string
    disabled  : boolean
}

const TaskImportanceSelect : React.FC<ITaskTypeSelectProps> = ({  type, ...props }) : JSX.Element => {

    const variableLists = reactiveVarHelper().get('variableLists') as TVariableItem[];

    const taskVariables = variableLists.filter(
        (listItem :  TVariableItem) => listItem.group_name === 'task type'
    ) ;

    return(
        <Select
            { ...props }
            defaultValue={ type || "" }
            suffixIcon    = { <Icons.Arrow /> }
        >
            <Option key={ 0 } value={ "" }>Not selected</Option>
            { taskVariables.map(
                ({ id, value, slug }) => {
                    return(
                        <Option key={ id } value={ slug }>
                            { value }
                        </Option>
                    );
                })
            }
        </Select>
    );
};

export default React.memo(TaskImportanceSelect);