import { gql, useApolloClient } from "@apollo/client";

import type { TID } from "graphql/type/common-gql-types";


const useExhibitionTypeFragment = ({ etId }  :  { etId : TID }) => {

    const client = useApolloClient();

    return client.readFragment({
        id : `ExhibitionType:${ etId }`,
        fragment : gql`
            fragment ExhibitionTypeFields on ExhibitionType {
                id
                title
            }
        `
    });
};

export default useExhibitionTypeFragment;