import { errorNotification, successNotification } from "../request-result";
import { reactiveVarHelper } from "common/helpers";

import type { IErrorNotification, ISuccessNotification } from "common/types";
import type { TNotificationState } from "graphql/reactive-variables";


const useNotification = () => {

    const { errorOn, successOn }  = reactiveVarHelper().get("notifications") as TNotificationState;

    return{
        "nError" : (
            error: IErrorNotification,
            forced?: boolean
        ) => {
            if(errorOn || forced){
                errorNotification(error);
            }

        },
        "nSuccess" : (
            title       : string,
            description ?: string,
            duration    ?: number,
            forced      ?: boolean
        ) => {
            if(successOn || forced){
                successNotification({
                    title,
                    description,
                    duration
                } as ISuccessNotification);
            }
        }
    };
};

export default useNotification;