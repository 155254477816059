import StandCadStatusSelect from "./Stand-cad-status-select";
import StandOrientationSelect from "./Stand-orientation-select";
import StandTypeSelect from "./Stand-type-select";

import "./stand-fields.scss";


const StandFields = {
    TypeSelect : StandTypeSelect,
    OrientationSelect : StandOrientationSelect,
    CadStatus : StandCadStatusSelect
};

export default StandFields;