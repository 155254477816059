import React, { JSX } from "react";

import { useMutation } from "@apollo/client";
import { Button, Form, Input, message } from "antd";

import { TASK_COMMENT_CREATE } from "graphql/mutation/task-m.gql";

import { Icons } from "components/layout";
import { useNotification } from "components/use-hook";

import type { TID } from "graphql/type/common-gql-types";

interface ITaskCommentEditFormProps {
    taskId : TID
}

const { TextArea } = Input;

const TaskCommentEditForm : React.FC<ITaskCommentEditFormProps> = ({ taskId }) : JSX.Element => {

    const { nError, nSuccess } = useNotification();
    const [ messageApi, contextHolder ] = message.useMessage();

    const [ taskCommentCreate, { loading } ] = useMutation(
        TASK_COMMENT_CREATE,
        {
            update(cache, { data }){
                const { "taskCommentCreate" : {
                    label,
                    message,
                }  } = data;

                nSuccess(label, message);

                cache.modify({
                    id : cache.identify({ __ref : `Task:${ taskId }` }),
                    fields : {
                        total_comment(currentCount) {
                            return currentCount + 1;
                        },
                    },
                });

            },
            refetchQueries : [ 'GetTaskComments' ],
            onError(error) {
                console.error(error);
            }
        }
    );

    const isCreate = true;
    const [ form ] = Form.useForm();

    return(
        <Form
            className = { "task-comment-edit-form inner-forms" }
            form      = { form }
            layout    = "vertical"
            onFinish={(values) => {
                if (!loading) {
                    taskCommentCreate({
                        variables : {
                            input : {
                                task_id : taskId,
                                description : values.description
                            }
                        }
                    }).then( () => {
                        form.resetFields();
                    } ).catch(nError);
                }
            }}
        >
            <Form.Item
                name  = "description"
            >
                <TextArea
                    placeholder = "Leave comment..."
                    autoSize    = {{ minRows : 4, maxRows : 6 }}
                />
            </Form.Item>
            <div className="form-actions">
                <div>
                    { contextHolder }
                    <Button
                        className = "attach-file"
                        type      = "text"
                        onClick   = { () => {
                            messageApi.open({
                                type : 'warning',
                                content : 'This element in progress',
                            });
                        }}
                    >
                        <Icons.Attach /> Attach file
                    </Button>
                </div>
                <div>
                    <Button
                        type="primary"
                        htmlType="submit"
                    >
                        <Icons.Plus loading={loading}/>
                        {isCreate ? 'Add comment' : 'Save comment'}
                    </Button>
                </div>
            </div>
        </Form>
    );
};

export default TaskCommentEditForm;