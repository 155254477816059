import CheckListFieldGroupBlock from "./check-list-field-group-block";
import CheckListFormRowBlock from "./Check-list-form-row-block";
import CheckListMenuLabelBlock from "./Check-list-menu-label-block";

import "./check-list-blocks.scss";


const CheckListBlocks = {
    FormRow : CheckListFormRowBlock,
    FieldGroup : CheckListFieldGroupBlock,
    MenuLabel : CheckListMenuLabelBlock
};

export default CheckListBlocks;