import React, { JSX }  from "react";

import { Outlet, useOutletContext, useParams } from "react-router-dom";

import { Blocks, Menus } from "components/layout";
import { ROUTES } from "components/routes";


const ExhibitionDetailsPage : React.FC = () : JSX.Element | null => {

    const pParams = useParams(),
        { ee } = useOutletContext<{ee : any}>(),
        isEeCreate = pParams.eeId === "create";

    return(
        <div className="page exhibition-page">
            <Blocks.PageHeader
                title={
                    isEeCreate || !ee ?
                        "Create exhibition" :
                        ee.title
                }
            />
            <Menus.ThirdLvl
                items={[
                    {
                        path : ROUTES.EXHIBITION_DETAILS_FULL,
                        label : "Exhibition info",
                        end : true
                    },
                    {
                        path : ROUTES.EXHIBITION_HALLS_FULL,
                        label : "Halls",
                        disabled : isEeCreate
                    },
                    {
                        path : ROUTES.EXHIBITION_TEAM_FULL,
                        label : "Team",
                        disabled : isEeCreate
                    },
                    {
                        path : ROUTES.EXHIBITION_STATISTICS_FULL,
                        label : "Statistics",
                        disabled : isEeCreate
                    }

                ]}
            />
            <div className="page-content-wrap">
                <Outlet
                    context={{
                        ee : ee
                    }}
                />
            </div>
        </div>
    );
};

export default ExhibitionDetailsPage;