import React, { JSX } from "react";


interface ITableHeaderProps {
    actions ?: JSX.Element | null
    filters ?: JSX.Element | null
}

const TableHeaderBlock : React.FC<ITableHeaderProps> = ({ filters, actions }) : JSX.Element => {

    return(
        <div className="table-header-block">
            <div className="filter-part">
                { filters || null }
            </div>
            <div className="action-part">
                { actions || null }
            </div>
        </div>
    );
};

export default TableHeaderBlock;