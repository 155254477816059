import React, { JSX } from 'react';

import { Form } from 'antd';

import CheckListBlocks from "../../blocks";
import { ECheckListType } from "../../check-list-types";

import type { IFormProps } from "../../check-list-types";


const { FormRow, FieldGroup } = CheckListBlocks;

const CheckListRecalculationForm : React.FC<IFormProps > = ({ formData, eeId, listGroup }) : JSX.Element => {

    const [ form ] = Form.useForm();

    const defaultFields = {
        ee_id : eeId,
        list_group : listGroup,
        active : false,
        flag : false,
        list_value : ""
    };

    return (
        <div className="check-list-form ">
            <Form
                className={"inner-forms"}
                form={form}
                layout="vertical"
            >
                <FormRow
                    rowData={{
                        title : "CheckSupplierInvoicesInNavision",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "CheckSupplierInvoicesInNavision"),
                        list_type : ECheckListType.MONEY,
                    }}
                >
                    <FieldGroup.SingleMoney/>
                </FormRow>
                <FormRow
                    rowData={{
                        title : "Proceeds",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "Proceeds"),
                        list_type : ECheckListType.MONEY,
                    }}
                >
                    <FieldGroup.SingleMoney/>
                </FormRow>
                <FormRow
                    rowData={{
                        title : "PurchaseCosts",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "PurchaseCosts"),
                        list_type : ECheckListType.MONEY,
                    }}
                >
                    <FieldGroup.SingleMoney/>
                </FormRow>
                <FormRow
                    rowData={{
                        title : "DB1",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "DB1"),
                        list_type : ECheckListType.MONEY,
                    }}
                >
                    <FieldGroup.SingleMoney/>
                </FormRow>
                <FormRow
                    rowData={{
                        title : "Resources",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "Resources"),
                        list_type : ECheckListType.MONEY,
                    }}
                >
                    <FieldGroup.SingleMoney/>
                </FormRow>
                <FormRow
                    rowData={{
                        title : "DB2",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "DB2"),
                        list_type : ECheckListType.MONEY,
                    }}
                >
                    <FieldGroup.SingleMoney/>
                </FormRow>

            </Form>
        </div>
    );
};

export default CheckListRecalculationForm;