import React, { FC, JSX } from "react";

import { useMutation } from "@apollo/client";
import { getOperationName } from "@apollo/client/utilities";
import { Upload } from "antd";

import { useNotification } from "components/use-hook";

import type { DocumentNode } from "graphql";
 

interface IUploadFile {
    variables: {
        input ?: any
        [ key : string ] : any
    }
    mutation    : DocumentNode
    extraClass ?: string
    accept     ?: string
    onSuccess   ?: ( data : any ) => void
    children    : (( props ?: any ) => JSX.Element) | JSX.Element
}

const UploadFile : FC<IUploadFile> = ({
        variables = {},
        mutation,
        extraClass ,
        accept,
        onSuccess,
        children,
        ...props
    }) : JSX.Element => {

    const { nError, nSuccess } = useNotification();

    const [ uploadMutation, { loading } ] = useMutation( mutation,
        {
            update(cache, { data }) {

                const operation = getOperationName(mutation);

                const { [ operation! ] : {
                    label,
                    message,
                }  } = data;

                onSuccess && onSuccess(data);

                nSuccess(label, message);

            }
        }
    );


    return(
        <Upload
            className  = { extraClass || "" }
            disabled   = { loading }
            accept     = { accept || "image/*" }
            showUploadList = { false }
            beforeUpload   = {
                (file) => {

                    let uploadVar;

                    if(variables.input !== undefined){
                        uploadVar = { ...variables };
                        uploadVar.input.file = file;
                    } else {
                        uploadVar = { ...variables, file };
                    }

                    uploadMutation({
                        variables : {
                            ...uploadVar
                        }
                    }).catch(nError);

                    return false;
                }
            }
            { ...props }
        >
            { typeof children === "function" ?
                children({ loading }) : children
            }
        </Upload>
    );
};


export default UploadFile;