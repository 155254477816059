import React, { JSX } from "react";

import TaskModals from "../modals";

import type { TID } from "graphql/type/common-gql-types";

interface ITaskListHeaderProps {
    standId    : TID
    teamId     : TID
    totalTasks : number
}

const TaskListHeaderBlock : React.FC<ITaskListHeaderProps> = ({
        standId,
        teamId,
        totalTasks,
    }) : JSX.Element => {
    return (
        <div className="tasks-header-block">
            <div className="title">
                <h3>{ totalTasks } { totalTasks === 1 ? 'Task' : 'Tasks' }</h3>
            </div>
            <div className="action">
                <TaskModals.Edit
                    standId = { standId }
                    teamId  = { teamId }
                />
            </div>
        </div>
    );
};

export default TaskListHeaderBlock;