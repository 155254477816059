import React, { JSX } from "react";

import { Input } from "antd";

import { ICheckListItem, IFieldProps } from "../check-list-types";


const CheckListTextField : React.FC<IFieldProps> = ({
        value,
        loading,
        handleMutate,
        placeholder = "—",
        multiField
    }) : JSX.Element => {


    return(
        <Input
            placeholder  = { placeholder }
            defaultValue = { value }
            onBlur       = {
                (e : React.ChangeEvent<HTMLInputElement>) => {
                    const eValue = e.target.value;

                    if(!loading && value !== eValue && handleMutate !== undefined){
                        let listValue = eValue;

                        if(multiField){
                            listValue = JSON.stringify({
                                ...multiField.structure,
                                [multiField.current] : eValue,
                            });
                        }

                        handleMutate<Pick<ICheckListItem, 'list_value'>>(
                            { list_value : listValue }
                        );
                    }
                }
            }
        />
    );
};


export default CheckListTextField;
