import React, { JSX } from "react";

import StandHelpers from "../helpers";
import StandModals from "../modals";
import { Blocks } from "components/layout";
import { Lists } from "components/layout";

import type { TID } from "graphql/type/common-gql-types";
import type { TStandComment } from "graphql/type/stand-gql-types";


interface IStandEquipmentCommentBlockProps {
    standId  : TID
    message ?: TStandComment
}

const StandEquipmentCommentBlock : React.FC<IStandEquipmentCommentBlockProps> = ({ standId, message }) : JSX.Element => {

    return(
        <Blocks.Card
            Header = { "Comment" }
            Action = {
                <StandModals.CommentEdit
                    standId  = { standId }
                    type     = { "equipment" }
                    comment  = { message }
                    onUpdate = {
                        (id, comment, cache) =>
                            cache.modify({
                                id : cache.identify({ __ref : `Stand:${ standId }` }),
                                fields : {
                                    relStandCommentEquipment() {
                                        return comment;
                                    },
                                },
                            })
                    }
                />
            }
        >
            { message && message.comment !== null ?
                <Lists.Messages
                    className  = "stand-equipment-comment"
                    withInfo   = { false }
                    messages   = { StandHelpers.commentMap([ message ]) }
                /> :
                <div className="no-data">
                    <p className="label">No comments yet</p>
                </div>

            }
        </Blocks.Card>
    );
};

export default StandEquipmentCommentBlock;