import { gql, TypedDocumentNode } from "@apollo/client";

import { IQueryVariables } from "../type/common-gql-types";

import type { TStandTypeFilesCursorData } from "../type/stand-type-gql-types";


export const GET_STAND_TYPES = gql`
    query GetStandTypes (
        $text    : String
        $where   : QueryStandTypesCursorWhereWhereConditions
        $whereExhibitionTypeStandTypes : QueryStandTypesCursorWhereExhibitionTypeStandTypesWhereHasConditions
        $whereOrientations : QueryStandTypesCursorWhereOrientationsWhereHasConditions
        $orderBy : [QueryStandTypesCursorOrderByOrderByClause!]
        $after   : String
    ){
        standTypesCursor(
            text    : $text
            where   : $where
            whereExhibitionTypeStandTypes : $whereExhibitionTypeStandTypes
            whereOrientations : $whereOrientations
            orderBy : $orderBy
            first   : 100
            after   : $after
        ) {
            pageInfo {
                hasNextPage
                startCursor
                endCursor
                total
                currentPage
            }
            edges {
                node{
                    id
                    group_id
                    title
                    lang
                    navision_number
                    size_min
                    size_max
                    price
                    complexity
                    total_notes
                    getImages {
                        id
                        title
                        server_name
                        sizes
                    }
                    orientations {
                        id
                        orientation_key
                        img_path
                    }
                    meta_fields {
                        id
                        stand_type_id
                        meta_key
                        meta_value
                        meta_group
                    }
                    created_at
                    updated_at
                }
            }
        }
    }
`;

export const GET_STAND_TYPE_FILES: TypedDocumentNode<{ standTypeFilesCursor : TStandTypeFilesCursorData }, IQueryVariables > = gql`
    query GetStandTypeFiles (
        $text    : String
        $where   : QueryStandTypeFilesCursorWhereWhereConditions
        $orderBy : [QueryStandTypeFilesCursorOrderByOrderByClause!]
        $after   : String
    ){
        standTypeFilesCursor(
            text    : $text
            where   : $where
            orderBy : $orderBy
            first   : 50
            after   : $after
        ) {
            pageInfo {
                hasNextPage
                startCursor
                endCursor
                total
                currentPage
            }
            edges {
                node{
                    id
                    stand_type_group
                    server_name
                    original_name
                    extension
                    size
                    title
                    description
                    order
                    user_id
                    path
                    created_at
                    updated_at
                }
            }
        }
    }
`;