import React, { JSX }  from "react";

import { useQuery } from "@apollo/client";
import { Outlet, useOutletContext, useParams } from "react-router-dom";

import { GET_STAND } from "graphql/query/stand-q.gql";

import { Blocks, Menus } from "components/layout";
import { Loader } from "components/request-result";
import { ROUTES } from "components/routes";
import Stand from "components/stand";


const StandPage : React.FC = () : JSX.Element | null => {

    const pParams = useParams(),
        { ee } = useOutletContext<{ee : any}>(),
        isStandCreate = pParams.standId === "create";

    const { data : { stand } = { },
        loading,
        error
    } = useQuery( GET_STAND, {
        variables : {
            id : pParams.standId!
        },
        skip : isStandCreate,
        fetchPolicy : "cache-first"
    });

    if(error) return null;

    return(
        <div className="page stand-page">
            <Blocks.PageHeader
                loading = { loading }
                title   = {
                    (isStandCreate || !stand) ?
                        "Create Stand" :
                        <Stand.Blocks.PageTitle
                            stand={ stand }
                        />
                }
            />
            <Menus.ThirdLvl
                items = {[
                    {
                        path : ROUTES.STAND_ITEM_FULL,
                        label : "Stand Details",
                        end : true
                    },
                    {
                        path : ROUTES.STAND_ITEM_EQUIPMENT_FULL,
                        label : "Equipments",
                        disabled : isStandCreate
                    },
                    {
                        path : ROUTES.STAND_ITEM_GRAPHIC_FULL,
                        label : "Graphics",
                        disabled : isStandCreate
                    },
                    {
                        path : ROUTES.STAND_ITEM_TASKS_FULL,
                        label : "Tasks",
                        disabled : isStandCreate
                    },
                    {
                        path : ROUTES.STAND_ITEM_TYPE_INFO_FULL,
                        label : "Stand type info",
                        disabled : isStandCreate
                    }
                ]}
            />
            <div className="page-content-wrap">
                { loading ?
                    <Loader /> :
                    <Outlet
                        context={{
                            ee : ee,
                            stand,
                        }}
                    />
                }
            </div>
        </div>
    );
};

export default StandPage;