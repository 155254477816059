import React, { JSX } from "react";

import { Collapse } from 'antd';

import { Icons } from "components/layout";

import type { TID } from "graphql/type/common-gql-types";


interface IStandCadVersionProps {
    standId : TID
}

const StandCadVersionList : React.FC<IStandCadVersionProps> = ({ standId }) : JSX.Element => {

    return(
        <Collapse
            className  = "stand-cad-version-list"
            expandIcon = {
                () => <button className="plus-minus-toggle"/>
            }
            ghost
            accordion
            items={[
                {
                    key : 3,
                    label :
                        <div className="version">
                            <p>Version 3.3 (12.06.24)</p>
                            <a className="link-text blue"
                               href="#"
                               target="_blank"
                               onClick={(event) => {
                                  event.stopPropagation();
                            }}>
                                <Icons.Download />
                            </a>
                        </div>,
                    children :
                        <ul className="version-list">
                            <li className="version">
                                <p>Version 3.2 (12.06.24)</p>
                                <a className="link-text blue"
                                   href="#"
                                   target="_blank"
                                >
                                    <Icons.Download/>
                                </a>
                            </li>
                            <li className="version">
                                <p>Version 3.1 (11.06.24)</p>
                                <a className="link-text blue"
                                   href="#"
                                   target="_blank"
                                >
                                    <Icons.Download/>
                                </a>
                            </li>
                            <li className="version">
                                <p>Version 3.0 (10.06.24)</p>
                                <a className="link-text blue"
                                   href="#"
                                   target="_blank"
                                >
                                    <Icons.Download/>
                                </a>
                            </li>
                        </ul>
                },

                {
                    key : 2,
                    label :
                        <div className="version">
                            <p>Version 2.2 (08.06.24)</p>
                            <a className="link-text blue"
                               href="#"
                               target="_blank"
                               onClick={(event) => {
                                   event.stopPropagation();
                               }}>
                                <Icons.Download/>
                            </a>
                        </div>,
                    children :
                        <ul className="version-list">
                            <li className="version">
                                <p>Version 2.1 (01.06.24)</p>
                                <a className="link-text blue"
                                   href="#"
                                   target="_blank"
                                >
                                    <Icons.Download/>
                                </a>
                            </li>
                            <li className="version">
                                <p>Version 2.0 (27.05.24)</p>
                                <a className="link-text blue"
                                   href="#"
                                   target="_blank"
                                >
                                    <Icons.Download/>
                                </a>
                            </li>
                        </ul>
                },
                {
                    key : 1,
                    label :
                        <div className="version">
                        <p>Version 1.0 ({ standId }.05.24)</p>
                            <a className="link-text blue"
                               href="#"
                               target="_blank"
                            >
                                <Icons.Download/>
                            </a>
                        </div>,
                    collapsible : "disabled"
                },
            ]}
        />
    );
};

export default StandCadVersionList;