import React, { JSX } from "react";

import { DatePicker } from 'antd';

import { getDate } from "common/utils";

import type { IDateTimeProp } from "common/types";


const { RangePicker } = DatePicker;

const RangeDate : React.FC<IDateTimeProp> = ({
        withTime   = false,
        withDay    = false,
        allowClear = false,
        format,
        fromToday  = true,
        ...props
    }) : JSX.Element => {

    const { dateTypes, format : dateFormat } = getDate;

    const showTime : object | boolean = withTime ? { minuteStep : 15 } : false;

    let formatType = withTime ? dateTypes.DATETIME : dateTypes.DATE;

    if(withDay){
        formatType = withTime ? dateTypes.DAYDATETIME : dateTypes.DAYDATE;
    }

    return(
        <RangePicker
            showTime     = { showTime }
            bordered     = { false }
            separator    = { <>-</> }
            format       = { dateFormat(format || formatType) }
            allowEmpty   = { [ false, false ] }
            allowClear   = { allowClear }
            suffixIcon   = { null }
            disabledDate = { fromToday ? getDate.disabled : () => false }
            {...props}

        />
    );
};

export default RangeDate;