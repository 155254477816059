import React, { JSX } from "react";

import { Badge, Button } from "antd";
import { NavLink } from "react-router-dom";

import { Icons } from "components/layout";
import { ROUTES } from "components/routes";
import { useLogout, useMe } from "components/use-hook";


const UserMenu : React.FC = () : JSX.Element => {

    const { logout } = useLogout(),
          { me } = useMe();

    return (
        <nav className="user-menu-wrap">
            <ul className="user-menu">
                <li>
                    <Button className="notifications" type="link">
                        <div className="icon wBadge">
                            <Icons.Notifications />
                            <Badge count={10} />
                        </div>
                        <div className="text">
                            Notifications
                        </div>
                    </Button>
                </li>
                <li>
                    <Button
                        className="logout"
                        type="link"
                        onClick={ () => logout({ redirectRoute : "/" }) }
                    >
                        <div className="icon">
                            <Icons.Logout />
                        </div>
                        <div className="text">
                            Log out
                        </div>
                    </Button>
                </li>
                <li>
                    <NavLink to={ ROUTES.USER_PROFILE } className="profile">
                        <div className="icon">
                            <Icons.Profile />
                        </div>
                        { me ?
                            <div className="text">
                                { me.name } { me.surname }
                            </div> : null
                        }
                    </NavLink>
                </li>
            </ul>
        </nav>
    );
};

export default React.memo(UserMenu);