import React, { JSX } from "react";

import { ECheckListType } from "../../check-list-types";
import CheckListFields from "../../fields";
import CheckListHelpers from "../../helpers";

import type { IFieldBlockProps } from "../../check-list-types";


const fieldStructure = {
    from : undefined,
    to : undefined
};

const { parseValues } = CheckListHelpers;

const CheckListDateRangeGroupBlock : React.FC<IFieldBlockProps> = ({
        values,
        type,
        placeholder,
        ...props
    }) : JSX.Element => {

    const dateType = type === ECheckListType.DATE_RANGE ? ECheckListType.DATE : ECheckListType.DAYDATE;

    const fields = parseValues<typeof values, typeof fieldStructure>(
        {
            values,
            structure : fieldStructure
    });

    return(
        <div className="check-list-group grid-two-col">
            <div className="col-one">
                <CheckListFields.DatePicker
                    value       = { fields.from }
                    placeholder = { typeof placeholder === "string" ? placeholder : "From" }
                    type        = { dateType }
                    multiField  = {{
                        current : 'from',
                        structure : fields
                    }}
                    { ...props }
                />
            </div>
            <div className="col-two">
                <CheckListFields.DatePicker
                    value       = { fields.to }
                    placeholder = { typeof placeholder === "string" ? placeholder : "To" }
                    type        = { dateType }
                    multiField  = {{
                        current : 'to',
                        structure : fields
                    }}
                    { ...props }
                />
            </div>
        </div>
    );
};

export default CheckListDateRangeGroupBlock;