import React, { JSX } from "react";

import { Select } from "antd";

import TaskHelpers from "../helpers";
import { reactiveVarHelper } from "common/helpers";
import { Icons } from "components/layout";

import type { TVariableItem } from "graphql/type/common-gql-types";


const { Option } = Select;

interface ITaskStatusSelectProps {
    status   ?: string
    loading   : boolean
    onChange  : ( value : string) => void
}

const TaskStatusSelect : React.FC<ITaskStatusSelectProps> = ({ status, ...props }) : JSX.Element => {

    const variableLists = reactiveVarHelper().get('variableLists') as TVariableItem[];

    const taskVariables = variableLists.filter(
        (listItem :  TVariableItem) => listItem.group_name === 'task status'
    ) ;

    return(
        <Select
            { ...props }
            className     = { `select-status-${TaskHelpers.status(status)}` }
            defaultValue  = { status || "" }
            suffixIcon    = { <Icons.Arrow /> }
        >
            { !taskVariables.length &&
                <Option key={ 0 } value={ "" }>Not selected</Option>
            }
            { taskVariables.map(
                ({ id, value, slug }) => {
                    return(
                        <Option key={ id } value={ slug }>
                            { value }
                        </Option>
                    );
                })
            }
        </Select>
    );
};

export default React.memo(TaskStatusSelect);