import React, { JSX } from 'react';

import { Form } from 'antd';

import CheckListBlocks from "../../blocks";
import { ECheckListType, IFormProps } from "../../check-list-types";


const { FormRow, FieldGroup } = CheckListBlocks;

const CheckListInvoiceForm : React.FC<IFormProps > = ({ formData, eeId, listGroup }) : JSX.Element => {

    const [ form ] = Form.useForm();

    const defaultFields = {
        ee_id : eeId,
        list_group : listGroup,
        active : false,
        flag : false,
        list_value : ""
    };

    return (
        <div className="check-list-form ">
            <Form
                className={"inner-forms"}
                form={form}
                layout="vertical"
            >
                <FormRow
                    rowData={{
                        title : "TurnoverOrderBacklog",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "TurnoverOrderBacklog"),
                        list_type : ECheckListType.MONEY_DATE,
                    }}
                >
                    <FieldGroup.MoneyDate />
                </FormRow>
                <FormRow
                    rowData={{
                        title : "1stPaymentOnAccount",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "1stPaymentOnAccount"),
                        list_type : ECheckListType.MONEY_DATE,
                    }}
                >
                    <FieldGroup.MoneyDate />
                </FormRow>
                <FormRow
                    rowData={{
                        title : "2stPaymentOnAccount",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "2stPaymentOnAccount"),
                        list_type : ECheckListType.MONEY_DATE,
                    }}
                >
                    <FieldGroup.MoneyDate />
                </FormRow>
                <FormRow
                    rowData={{
                        title : "FinalInvoice",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "FinalInvoice"),
                        list_type : ECheckListType.MONEY_DATE,
                    }}
                >
                    <FieldGroup.MoneyDate />
                </FormRow>
                <FormRow
                    rowData={{
                        title : "RecordLastMinuteInNavision",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "RecordLastMinuteInNavision"),
                        list_type : ECheckListType.TEXT_DATE,
                    }}
                >
                    <FieldGroup.TextDate />
                </FormRow>
                <FormRow
                    rowData={{
                        title : "RecordDamages",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "RecordDamages"),
                        list_type : ECheckListType.TEXT_DATE,
                    }}
                >
                    <FieldGroup.TextDate />
                </FormRow>
                <FormRow
                    rowData={{
                        title : "SettleAisleCarpet",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "SettleAisleCarpet"),
                        list_type : ECheckListType.TEXT_DATE,
                    }}
                >
                    <FieldGroup.TextDate />
                </FormRow>
                <FormRow
                    rowData={{
                        title : "OutwardAndReturnTransportationForStoredMaterialWhichWasDelivered",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "OutwardAndReturnTransportationForStoredMaterialWhichWasDelivered"),
                        list_type : ECheckListType.TEXT_DATE,
                    }}
                >
                    <FieldGroup.TextDate />
                </FormRow>
            </Form>
        </div>
    );
};

export default CheckListInvoiceForm;