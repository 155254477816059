import React, { JSX } from "react";


const SidebarBlock : React.FC< { children : JSX.Element } > = ({ children }) : JSX.Element => {

    return(
        <aside className="sidebar-block">
            { children }
        </aside>
    );
};


export default SidebarBlock;