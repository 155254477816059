import React, { JSX, useState } from "react";

import { useParams } from "react-router-dom";

import Exhibition from "components/exhibition";
import { Blocks, Fields } from "components/layout";
import { LinkWithIcon } from "components/service";

import { TFilterFields } from "common/types";


const ExhibitionHallsSubPage : React.FC = () : JSX.Element => {

    const [ filters, setFilters ] = useState<TFilterFields>({ text : "" });
    const { eeId } = useParams();

    return(
        <div className="page-content">
            <Blocks.TableHeader
                filters = {<>
                    <Fields.Search
                        filters    = { filters }
                        setFilters = { setFilters }
                    />
                </>}
                actions = {<>
                    <LinkWithIcon
                        route       = { "EXHIBITION_HALL_CREATE_FULL" }
                        routeParams = { { eeId } }
                    >
                        Add Hall
                    </LinkWithIcon>
                </>}
            />
            <Exhibition.Tables.Halls
                filters = { filters }
                eeId    = { Number(eeId) }
            />
        </div>
    );
};

export default ExhibitionHallsSubPage;