import React, { JSX } from 'react';

import { Form } from 'antd';

import CheckListBlocks from "../../blocks";
import { ECheckListType } from "../../check-list-types";
import Localize from "components/service/Localize";

import type { IFormProps } from "../../check-list-types";


const { FormRow, FieldGroup } = CheckListBlocks;
const CheckListCraftsFurnitureForm : React.FC<IFormProps> = (
    {
        formData,
        eeId,
        listGroup
    }) : JSX.Element => {

    const [ form ] = Form.useForm();

    const defaultFields = {
        ee_id : eeId,
        list_group : listGroup,
        active : false,
        flag : false,
        list_value : ""
    };

    return (
        <div className="check-list-form ">
            <Form
                className     = { "inner-forms" }
                form          = { form }
                layout        = "vertical"
            >
                <Localize tag="h3" wrap>
                    { `CHECKLIST.Form_Title_${listGroup}` }
                </Localize>
                <FormRow
                    rowData={{
                        title : "CarpetSupplier",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "CarpetSupplier"),
                        list_type : ECheckListType.STR,
                    }}
                >
                    <FieldGroup.SingleText
                        placeholder={"Offen"}
                    />
                </FormRow>
                <FormRow
                    rowData={{
                        title : "StandardColorCarpetExhibitionStands",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "StandardColorCarpetExhibitionStands"),
                        list_type : ECheckListType.STR,
                    }}
                >
                    <FieldGroup.SingleText
                        placeholder={"Offen"}
                    />
                </FormRow>
                <FormRow
                    rowData={{
                        title : "AisleCarpetYesNo",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "AisleCarpetYesNo"),
                        list_type : ECheckListType.STR,
                    }}
                >
                    <FieldGroup.SingleText
                        placeholder={"Offen"}
                    />
                </FormRow>
                <FormRow
                    rowData={{
                        title : "StandardColorAisleCarpet",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "StandardColorAisleCarpet"),
                        list_type : ECheckListType.STR,
                    }}
                >
                    <FieldGroup.SingleText
                        placeholder={"Offen"}
                    />
                </FormRow>
                <FormRow
                    rowData={{
                        title : "ForecastCommunicateCarpetQuantitiesToSupplier",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "ForecastCommunicateCarpetQuantitiesToSupplier"),
                        list_type : ECheckListType.DAYDATE_TIME,
                    }}
                >
                    <FieldGroup.DateTime />
                </FormRow>

                <FormRow
                    rowData={{
                        title : "CarpetListWithHallPlanToSupplier",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "CarpetListWithHallPlanToSupplier"),
                        list_type : ECheckListType.DAYDATE_TIME,
                    }}
                >
                    <FieldGroup.DateTime />
                </FormRow>
                <FormRow
                    rowData={{
                        title : "PlanAndOrderCarpetReserve",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "PlanAndOrderCarpetReserve"),
                        list_type : ECheckListType.DAYDATE_TIME,
                    }}
                >
                    <FieldGroup.DateTime />
                </FormRow>
                <FormRow
                    rowData={{
                        title : "CommunicateStartAndOfInstallationDate",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "CommunicateStartAndOfInstallationDate"),
                        list_type : ECheckListType.DAYDATE_TIME,
                    }}
                >
                    <FieldGroup.DateTime />
                </FormRow>
                <FormRow
                    rowData={{
                        title : "CommunicateEndOfInstallationDate",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "CommunicateEndOfInstallationDate"),
                        list_type : ECheckListType.DAYDATE_TIME,
                    }}
                >
                    <FieldGroup.DateTime />
                </FormRow>
                <FormRow
                    rowData={{
                        title : "CommunicateAisleLayingDate",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "CommunicateAisleLayingDate"),
                        list_type : ECheckListType.DAYDATE_TIME,
                    }}
                >
                    <FieldGroup.DateTime />
                </FormRow>
                <FormRow
                    rowData={{
                        title : "CommunicateDateForCarpetCollectionAfterDismantling",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "CommunicateDateForCarpetCollectionAfterDismantling"),
                        list_type : ECheckListType.DAYDATE_TIME,
                    }}
                >
                    <FieldGroup.DateTime />
                </FormRow>
            </Form>
        </div>
    );
};

export default CheckListCraftsFurnitureForm;