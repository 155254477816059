import React, { JSX, useEffect, useState } from "react";

import { Button, Form, FormItemProps, Select } from "antd";

import FlooringBlocks from "../blocks";
import FlooringLists from "../lists";
import { Icons } from "components/layout";
import { DrawerStandard } from "components/service";

import type { TFilterProps } from "../blocks/Flooring-filter-block";
import type { IFlooringSelect } from "../flooring-types";
import type { NamePath } from "rc-field-form/es/interface";


type TStandTypeProps = {
    etId             ?: number | undefined
    orgId            ?: number | undefined
    standTypeId      ?: number | undefined
    standTypeGroupId ?: number | undefined
    currentFlooring  ?: IFlooringSelect | undefined
};

const FlooringSelect : React.FC<TStandTypeProps> = ({
        etId = undefined,
        orgId = undefined,
        standTypeId ,
        standTypeGroupId,
        currentFlooring,
        ...props
    }) : JSX.Element => {

    const form = Form.useFormInstance(),
        [ openDrawer, setOpenDrawer ] = useState( false ),
        [ flooring, setFlooring ] = useState<IFlooringSelect | undefined>( currentFlooring );

    useEffect(() => {
        setFlooring(currentFlooring);
    }, [ currentFlooring ]);

    const confirmSelect = (options ?: IFlooringSelect  ) => {

        if(options){

            const { id } = props as FormItemProps;

            form.setFieldValue(
                id! as NamePath,
                {
                    label : `${ options.title }`,
                    value : options.id,
                }
            );
        }

        setOpenDrawer(false);

    };

    return(
        <>
            <Select
                { ...props }
                defaultValue  = { ""! }
                onClick       = { () => setOpenDrawer(true) }
                dropdownStyle = { { display : "none" } }
                suffixIcon    = { <Icons.Arrow /> }
                options={ [
                    { value : '', label : 'Not selected' }
                ]! }
            />
            <DrawerStandard
                title      = { <h2>Select stand flooring</h2> }
                isOpen     = { openDrawer }
                extraClass = { "select-modal radio" }
                close      = { () => setOpenDrawer(false) }

                extra={
                    <div className="form-actions">
                        <Button onClick={ () => setOpenDrawer(false)  }>Cancel</Button>
                        <Button type="primary"
                                onClick={ () =>  confirmSelect( flooring ) }
                        >
                            Choose
                        </Button>
                    </div>
                }
            >
                <FlooringBlocks.SelectFilter
                    filterProps = {{
                        etId,
                        orgId,
                        standTypeId
                    }}
                >
                    { (filteredProps : TFilterProps) => (
                        <FlooringLists.Radio
                            { ...filteredProps}
                            flooring    = { flooring }
                            setFlooring = { setFlooring }
                            standTypeId = { filteredProps.standTypeId ? standTypeGroupId : undefined }
                        />
                    ) }

                </FlooringBlocks.SelectFilter>
            </DrawerStandard>
        </>
    );
};

export default React.memo(FlooringSelect);