import ExhibitionHallsTable from "./Exhibition-halls-table";
import ExhibitionsTable from "./Exhibitions-table";

import "./exhibition-tables.scss";


const ExhibitionTables = {
    All : ExhibitionsTable,
    Halls : ExhibitionHallsTable
};

export default ExhibitionTables;