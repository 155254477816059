import React, { useState } from "react";

import { Carousel, Image } from 'antd';

import { Icons } from "components/layout";

import type { TImageData } from "graphql/type/common-gql-types";


interface IPhotoCarousel {
    photos : TImageData[]
}

const PhotoCarouselItem : React.FC<TImageData> = ({ sizes, server_name }) => {

    const [ visible, setVisible ] = useState<boolean>(false);

    return (
        <div className="photo-carousel-item">
            <button
                className="img-wrap"
                onClick={ () => setVisible(true) }
            >
                <img
                    src={ sizes['1024x1024'] }
                    alt={ server_name }
                />
                <Icons.Zoom className="zoom-icon" />
            </button>
            <Image
                src    =  { sizes['200x200'] }
                preview = {{
                    visible,
                    src : sizes['original'],
                    onVisibleChange : (value) => {
                        setVisible(value);
                    },
                }}
            />
        </div>
    );
};

const PhotoCarousel : React.FC<IPhotoCarousel> = ({ photos }) => {

    const settings = {
        customPaging : (i : number)  => {
            return (
                <button className="photo-carousel-thumb">
                    <img src={ photos[i].sizes['200x200'] } />
                </button>
            );
        },
        dots : {
            className : "photo-carousel-thumb-nav"
        },
        infinite : false,
        speed : 500,
        slidesToShow : 1,
        slidesToScroll : 1,
        className : 'photo-carousel'
    };

    return(
        <Carousel {...settings} >
            { photos.map(
                ( photo ) => {

                return (
                    <PhotoCarouselItem
                        key={ photo.id }
                        {...photo }
                    />
                );
            })}
        </Carousel>
    );
};

export default PhotoCarousel;