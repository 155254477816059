import React, { JSX } from "react";

import { DatePicker, type DatePickerProps } from 'antd';

import { getDate } from 'common/utils';

import type { IDateTimeProp } from "common/types";


interface IDateTimePickerProp extends IDateTimeProp{
    onChange      ?: DatePickerProps['onChange']
}

const DateTimePicker : React.FC<IDateTimePickerProp> = ({
        withTime   = false,
        withDay    = false,
        allowClear = false,
        fromToday  = true,
        format ,
        placeHolder,
        ...props
    }) : JSX.Element => {

    const { dateTypes, format : dateFormat } = getDate;

    const showTime : object | boolean = withTime ? { minuteStep : 15 } : false;

    let formatType = withTime ? dateTypes.DATETIME : dateTypes.DATE;

    if(withDay){
        formatType = withTime ? dateTypes.DAYDATETIME : dateTypes.DAYDATE;
    }

    return(
        <DatePicker
            showTime     = { showTime }
            bordered     = { false }
            format       = { dateFormat(format || formatType) }
            allowClear   = { allowClear }
            suffixIcon   = { null }
            disabledDate = { fromToday ? getDate.disabled : () => false }
            placeholder  = { placeHolder }
            {...props}

        />
    );
};

export default DateTimePicker;