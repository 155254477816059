import { gql } from '@apollo/client';


export const USER_LOGIN = gql`
    mutation UserLogin($data: UserLoginInput!) {
        userLogin(input: $data){
            access_token
            user{
                id
                name
                surname
                email
                phone
                status
                lang_api
                lang_app
                job_type
                is_admin
                is_online
                roles {
                    id
                    title
                }
                permissions {
                    id
                    slug
                }
                supplier {
                    id
                    title
                    type
                    number
                }
                teams {
                    id
                    title
                }
                last_visit_at
                created_at
                updated_at
            }
        }
    }
`;


export const USER_LOGOUT = gql`
    mutation UserLogout{
        userLogout {
            status
            message
        }
    }
`;