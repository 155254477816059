import CheckListBlocks from "./blocks";
import CheckListButtons from "./buttons";
import CheckListFields from "./fields";
import CheckListForms from "./forms";
import CheckListHelpers from "./helpers";

const Checklist  = {
    Buttons : CheckListButtons,
    Fields : CheckListFields,
    Forms : CheckListForms,
    helpers : CheckListHelpers,
    Blocks : CheckListBlocks
};

export default Checklist;
