interface IParseValuesHelperProps<V, S> {
    values : V
    structure: S
}

const checkListParseValuesHelper  = <V extends string, S>({ values, structure }: IParseValuesHelperProps<V, S>): S  => {

    let fields;

    try{
        fields = JSON.parse(values);

        if(fields === null){
            fields = structure;
        }
    } catch {
        fields = structure;
    }

    return fields;
};


export default checkListParseValuesHelper;