import React, { JSX }  from "react";


const ExhibitionTeamSubPage : React.FC = () : JSX.Element => {

    return(
        <div className="page-content">
            EXHIBITION TEAM
        </div>
    );
};

export default ExhibitionTeamSubPage;