import React, { FunctionComponent, JSX } from 'react';

import Icon from '@ant-design/icons';

import { IIcon } from "./icons-interfaces";


const CheckListSvg : React.FC = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path style={ { opacity : 0.5 } }  d="M28.3,8.6c-0.1-2.1-1.9-3.9-4-3.9s-3.9,1.7-4,3.9h-2.9c-0.9,0-1.6,0.7-1.6,1.6v3.6c0,0.9,0.7,1.6,1.6,1.6h14
			c0.9,0,1.6-0.7,1.6-1.6v-3.6c0-0.9-0.7-1.6-1.6-1.6H28.3z"/>
        <path d="M35.5,10.9h-2.6v2.9c0,0.9-0.7,1.6-1.6,1.6h-14c-0.9,0-1.6-0.7-1.6-1.6v-2.9H13c-1.5,0-2.7,1.2-2.7,2.7v26.2
			c0,1.5,1.2,2.7,2.7,2.7h22.5c1.5,0,2.7-1.2,2.7-2.7V13.6C38.2,12.1,37,10.9,35.5,10.9z M31.7,24.3L23,33l-5.6-5.6
			c-0.6-0.6-0.6-1.4,0-2c0.6-0.6,1.4-0.6,2,0L23,29l6.6-6.6c0.6-0.6,1.4-0.6,2,0C32.2,22.9,32.2,23.8,31.7,24.3z"/>
    </svg>
);


const CheckListIcon : FunctionComponent<IIcon> = ({ className }) : JSX.Element => {

    return <Icon component={ CheckListSvg } className={ className } />;

};


export default CheckListIcon;