import React, { JSX, useState } from "react";

import { gql, useApolloClient } from "@apollo/client";
import { Select } from "antd";

import { Fields, Icons } from "components/layout";


export type TFilterProps = {
    eeId         ?: number
    etId          : number | undefined
    orgId         : number | undefined
    standTypeId   : number | undefined
    text         ?: string
};

type TStandTypeFilterProps = {
    readonly children  : ( props ?: any ) => JSX.Element
    filterProps : TFilterProps
};


const FlooringFilterBlock : React.FC<TStandTypeFilterProps> = ({ children, filterProps }) : JSX.Element => {

    const client = useApolloClient(),
        [ filterType,  setFilterType ] = useState(0),
        [ searchText, setSearchText ] = useState({ text : "" });

    const organizer = client.readFragment({
        id : `Organizer:${ filterProps.orgId }`,
        fragment : gql`
            fragment OrganizerFieldsForFlooring on Organizer {
                id
                title
            }
        `
    });

    const exhibitionType = client.readFragment({
        id : `ExhibitionType:${ filterProps.etId }`,
        fragment : gql`
            fragment ExhibitionTypeFieldsForFlooring on ExhibitionType {
                id
                title
            }
        `
    });

    const standType = client.readFragment({
        id : `StandType:${ filterProps.standTypeId }`,
        fragment : gql`
            fragment StandTypeFieldsForFlooring on StandType {
                id
                title
            }
        `
    });

    const filterOptions = [
        { value : 0, label : `Show Floorings for ${ standType.title } stand type`, filterFields : [ "etId", 'orgId' ] },
        { value : 1, label : `Show Floorings for ${ exhibitionType.title } exhibition type`, filterFields : [ 'orgId', 'standTypeId' ] },
        { value : 2, label : `Show Floorings for ${ organizer.title } organizer`, filterFields : [ 'etId', 'standTypeId' ] },
        { value : 3, label : 'All Floorings', filterFields : [ "etId", 'orgId', 'standTypeId' ] },
    ];

    const filteredProps = { ...filterProps, ...searchText };

    filterOptions[filterType].filterFields.forEach( (item ) => {
            delete filteredProps[item as keyof TFilterProps];
        }
    );

    return(
        <>
            <div className="drawer-filter">
                <Select
                    popupClassName = "drawer-filter-dropdown"
                    suffixIcon  = { <Icons.Arrow /> }
                    onChange    = { (value) => setFilterType(value) }
                    value       = { filterType! }
                    options     = { filterOptions! }
                />
            </div>
            <div className="drawer-search">
                <Fields.Search filters    = { searchText }
                               setFilters = { setSearchText }
                />
            </div>
            <div className="drawer-content">
                { children( filteredProps ) }
            </div>
        </>
    );
};

export default FlooringFilterBlock;