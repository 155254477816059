import React, { FunctionComponent, JSX } from 'react';

import Icon from '@ant-design/icons';

import { IIcon } from "./icons-interfaces";


const CalendarSvg : React.FC = () => (
    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1">
        <path d="M43.4,6.2h-6.2v-2.3c0-1.1-.9-2-2-2s-2,.9-2,2v2.3H14.9v-2.3c0-1.1-.9-2-2-2s-2,.9-2,2v2.3h-6.5c-1.1,0-2,.9-2,2v33c0,1.1.9,2,2,2h39c1.1,0,2-.9,2-2V8.2c0-1.1-.9-2-2-2ZM10.9,10.2v1.3c0,1.1.9,2,2,2s2-.9,2-2v-1.3h18.3v1.3c0,1.1.9,2,2,2s2-.9,2-2v-1.3h4.2v4.8H6.5v-4.8h4.5ZM6.5,39.2v-20.2h35v20.2H6.5Z"/>
        <path d="M15.7,20.7h-4.4c-1.1,0-2,.9-2,2s.9,2,2,2h4.4c1.1,0,2-.9,2-2s-.9-2-2-2Z"/>
        <path d="M15.7,26.7h-4.4c-1.1,0-2,.9-2,2s.9,2,2,2h4.4c1.1,0,2-.9,2-2s-.9-2-2-2Z"/>
        <path d="M15.7,32.7h-4.4c-1.1,0-2,.9-2,2s.9,2,2,2h4.4c1.1,0,2-.9,2-2s-.9-2-2-2Z"/>
        <path d="M25.7,20.7h-4.4c-1.1,0-2,.9-2,2s.9,2,2,2h4.4c1.1,0,2-.9,2-2s-.9-2-2-2Z"/>
        <path d="M25.7,26.7h-4.4c-1.1,0-2,.9-2,2s.9,2,2,2h4.4c1.1,0,2-.9,2-2s-.9-2-2-2Z"/>
        <path d="M25.7,32.7h-4.4c-1.1,0-2,.9-2,2s.9,2,2,2h4.4c1.1,0,2-.9,2-2s-.9-2-2-2Z"/>
        <path d="M35.7,20.7h-4.4c-1.1,0-2,.9-2,2s.9,2,2,2h4.4c1.1,0,2-.9,2-2s-.9-2-2-2Z"/>
        <path d="M35.7,26.7h-4.4c-1.1,0-2,.9-2,2s.9,2,2,2h4.4c1.1,0,2-.9,2-2s-.9-2-2-2Z"/>
    </svg>
);

const CalendarIcon: FunctionComponent<IIcon> = (props): JSX.Element => {

    return <Icon component={CalendarSvg} className={props.className}/>;

};


export default CalendarIcon;