import CheckListCraftsForm from "./check-list-crafts-form";
import CheckListExpoDatesForm from "./check-list-expo-dates-form";
import CheckListInvoiceForm from "./check-list-invoice-form";
import CheckListParticipantsForm from "./check-list-participants-form";
import CheckListRecalculationForm from "./check-list-recalculation-form";
import CheckListSitePlanningForm from "./check-list-site-planning-form";

import "./check-list-forms.scss";


const CheckListForms = {
    ExpoDates : CheckListExpoDatesForm,
    Recalculation : CheckListRecalculationForm,
    Invoice : CheckListInvoiceForm,
    SitePlanning : CheckListSitePlanningForm,
    Crafts : CheckListCraftsForm,
    Participants : CheckListParticipantsForm
};

export default CheckListForms;