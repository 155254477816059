import React, { JSX }  from "react";

import { Col, Row } from "antd";
import { useOutletContext } from "react-router-dom";

import Exhibition from "components/exhibition";


const ExhibitionInfoSubPage : React.FC = () : JSX.Element => {

    const { ee } = useOutletContext<{ee : any}>();

    const span = {
        xxl : 12, xl : 16, lg : 18, span : 24
    };

    return(
        <Row className="page-content">
            <Col {...span}>
                <Exhibition.Forms.Edit
                    isCreate={ !ee }
                    ee={ ee }
                />
            </Col>
        </Row>
    );
};

export default ExhibitionInfoSubPage;