import TaskChangeStatusForm from "./Task-change-status-form";
import TaskCommentEditForm from "./Task-comment-edit-form";
import TaskEditForm from "./Task-edit-form";

import "./task-forms.scss";


const TaskForms = {
    CommentEdit : TaskCommentEditForm,
    Edit : TaskEditForm,
    Status : TaskChangeStatusForm,
};

export default TaskForms;