import { gql } from '@apollo/client';


export const GET_TEAMS = gql`
    query GetTeams (
        $text    : String
        $where   : QueryTeamsCursorWhereWhereConditions
        $orderBy : [QueryTeamsCursorOrderByOrderByClause!]
        $after   : String
    ){
        teamsCursor(
            text    : $text
            where   : $where
            orderBy : $orderBy
            first   : 50
            after   : $after
        ) {
            pageInfo {
                hasNextPage
                startCursor
                endCursor
                total
                currentPage
            }
            edges {
                node{
                    id
                    title
                }
            }
        }
    }
`;
