import React, { FunctionComponent, JSX } from 'react';

import Icon from '@ant-design/icons';

import { IIcon } from "./icons-interfaces";
import { Loader } from "components/request-result";


const ImportanceSvg : React.FC = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M44.9,39.1l-9.6-16.6L25.8,5.9c-.8-1.4-2.8-1.4-3.5,0l-9.6,16.6L3.1,39.1c-.8,1.4.2,3.1,1.8,3.1h38.3c1.6,0,2.6-1.7,1.8-3.1ZM24,38c-1.2,0-2.1-.9-2.1-2.1s.9-2.1,2.1-2.1,2.1.9,2.1,2.1-.9,2.1-2.1,2.1ZM25.8,29.8c0,1-.8,1.8-1.8,1.8s-1.8-.8-1.8-1.8l-.5-13.5c0-1,1.1-1.9,2.3-1.9s2.4.9,2.3,1.9l-.6,13.5Z"/>
    </svg>
);

const ImportanceIcon: FunctionComponent<IIcon> = (props): JSX.Element => {

    if (props.loading) {
        return <Loader/>;
    }

    return <Icon component={ ImportanceSvg } className={ props.className }/>;

};


export default ImportanceIcon;