import React, { ReactElement } from "react";


const cloneChildren = ( children: ReactElement, props : any ) => {

    if(!children){
        return null;
    }

    if(Array.isArray(children)){

        return children.map(
            ( child, index ) => {

                if (typeof child.type === "function" || typeof child.type === "object") {
                    return React.cloneElement(
                        child,
                        {
                            key : index,
                            ...props
                        }
                    );
                }

                return React.cloneElement(child, { key : index });
            }
        );

    }

    return  typeof children.type === "function" || typeof children.type === "object" ?
        React.cloneElement( children, props) : children;
};


export default cloneChildren;