import React, { JSX, ReactNode } from "react";

import { App, ConfigProvider } from "antd";
import deDe from 'antd/locale/de_DE';
import enUs from 'antd/locale/en_US';
// eslint-disable-next-line import/order
import dayjs from 'dayjs';

import "dayjs/locale/de";
import "dayjs/locale/en";

// eslint-disable-next-line import/order
import { reactiveVarHelper } from "common/helpers";

import type { TLocales } from "common/types";


const supportedLocale = {
    en : enUs,
    de : deDe
};

const AntdProvider : React.FC<{ children : ReactNode }> = ({ children }) : JSX.Element => {

    const rVar = reactiveVarHelper(),
          currentLocale : TLocales = rVar.get("defaultLanguage");

    dayjs.locale(currentLocale);

    return(
        <ConfigProvider
            theme={{ hashed : false }}
            locale={ supportedLocale[currentLocale] }
        >
            <App>
                { children }
            </App>
        </ConfigProvider>
    );
};

export default AntdProvider;