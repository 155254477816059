import getFieldStatistic from "./check-list-field-statistic-helper";
import checkListGetDateData from "./check-list-get-date-data";
import checkListParseValuesHelper from "./check-list-parse-values-helper";
import checkListStructureHelper from "./check-list-structure-helper";


const CheckListHelpers = {
    structure : checkListStructureHelper,
    fieldStatistic : getFieldStatistic,
    getDateData : checkListGetDateData,
    parseValues : checkListParseValuesHelper
};

export default CheckListHelpers;
