import SignInForm from "./sign-in-form";

import "./auth-forms.scss";


const AuthForms = {
    SignIn : SignInForm
};


export default AuthForms;