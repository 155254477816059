import React, { JSX } from 'react';

import { Form } from 'antd';

import CheckListBlocks from "../../blocks";
import { ECheckListType } from "../../check-list-types";
import Localize from "components/service/Localize";

import type { IFormProps } from "../../check-list-types";

const { FormRow, FieldGroup } = CheckListBlocks;

const CheckListParticipantsOrganizerForm : React.FC<IFormProps > = ({
        formData,
        eeId,
        listGroup
    }) : JSX.Element => {

    const [ form ] = Form.useForm();


    const defaultFields = {
        ee_id : eeId,
        list_group : listGroup,
        active : false,
        flag : false,
        list_value : ""
    };

    return (
        <div className="check-list-form ">
            <Form
                className={"inner-forms"}
                form={form}
                layout="vertical"
            >
                <Localize tag="h3" wrap>
                    { `CHECKLIST.Form_Title_${listGroup}` }
                </Localize>
                <FormRow
                    rowData={{
                        title : "Organizer",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "Organizer"),
                        list_type : ECheckListType.CONTACT,
                    }}
                >
                    <FieldGroup.Contact/>
                </FormRow>
                <FormRow
                    rowData={{
                        title : "PlaceOfTakingPlace",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "PlaceOfTakingPlace"),
                        list_type : ECheckListType.CONTACT,
                    }}
                >
                    <FieldGroup.Contact/>
                </FormRow>
            </Form>
        </div>
    );
};

export default CheckListParticipantsOrganizerForm;