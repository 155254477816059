import React, { JSX, ReactNode } from "react";

//import { useTranslation } from "components/use-hook";
import { translations } from "../app/Private-app";


interface ILocalizeParams{
    children          : ReactNode
    tag              ?: keyof JSX.IntrinsicElements
    className        ?: string,
    wrap             ?: boolean
    [ key : string ]  : string | number | ReactNode
}


export const localizeByKey = ( key : string, params : object = {}) : string => {

    let translation : any = translations[key] ? translations[key] : key;

    if( Object.keys(params).length ){

        for( const [ paramKey, value ] of Object.entries( params )){

            const regExp = new RegExp(`{ ${ paramKey } }`, "g");

            if( typeof value === "object" ){

                translation = translation.split(`{ ${paramKey} }`).map( ( item: string, idx: number, arr: [] ) => {
                    if(arr.length - 1 > idx){
                        return [ item, { ...value, key : `${ paramKey }_${ idx }` } ];
                    }

                    return item;
                });

            } else {

                translation = translation.replace(regExp, value);
            }
        }
    }

    return translation;
};

const Localize : React.FC<ILocalizeParams> = (
    {
        children : tKey,
        tag = `span`,
        wrap = false,
        className = "",
        ...params
    } ) : JSX.Element => {

    const translation = localizeByKey(
        tKey as string,
        params
    );

    if(wrap){
        const CustomTag = tag ;

        return (
            <CustomTag className={ className }>
                { translation }
            </CustomTag>
        );
    }

    return <>{ translation }</>;
};


export default Localize;