import dayjs from "dayjs";


export const prepareEditInitDataHelper = ( ee : any ) => {

    const hasConstructionDate = ee.construction_start_at && ee.construction_end_at,
          hasRunningDate = ee.running_start_at && ee.running_end_at,
          hasDeconstructionDate = ee.deconstruction_start_at && ee.deconstruction_end_at;

    const {
        projectManager,
        constructionManager,
        team_id
    } = ee;

    return {
        ...ee,
        team_id : team_id || "",
        constructionDate : hasConstructionDate ? [
            dayjs(ee.construction_start_at),
            dayjs(ee.construction_end_at),
        ] : undefined,
        runningDate : hasRunningDate ? [
            dayjs(ee.running_start_at),
            dayjs(ee.running_end_at),
        ] : undefined,
        deconstructionDate : hasDeconstructionDate ? [
            dayjs(ee.deconstruction_start_at),
            dayjs(ee.deconstruction_end_at),
        ] : undefined,
        project_manager_id : ee.project_manager_id ? {
            value : projectManager.id,
            label : `${ projectManager.name } ${ projectManager.surname }`
        } : "",
        construction_manager_id : ee.construction_manager_id ? {
            value : constructionManager.id,
            label : `${ constructionManager.name } ${ constructionManager.surname }`
        } : ""
    };
};


export const prepareEditFormDataHelper = ( values : any ) => {

    const {
        constructionDate,
        runningDate,
        deconstructionDate,
        project_manager_id : pmId,
        construction_manager_id : cmId,
        ...newValues
    } = values;

    return {
        construction_start_at : constructionDate ? constructionDate[0].format("YYYY-MM-DD") : undefined,
        construction_end_at : constructionDate ? constructionDate[1].format("YYYY-MM-DD") : undefined,
        running_start_at : runningDate ? runningDate[0].format("YYYY-MM-DD hh:mm:ss")  : undefined,
        running_end_at : runningDate ? runningDate[1].format("YYYY-MM-DD hh:mm:ss")  : undefined,
        deconstruction_start_at : deconstructionDate ? deconstructionDate[0].format("YYYY-MM-DD") : undefined,
        deconstruction_end_at : deconstructionDate ? deconstructionDate[1].format("YYYY-MM-DD") : undefined,
        project_manager_id : pmId ? pmId.value : undefined,
        construction_manager_id : cmId ? cmId.value : undefined,
        ...newValues
    };
};