import React, { JSX } from "react";

import getDate from "common/utils/date-format";

import Icons from "../../icons";

import { IMessageItem } from "common/types";
import type { TID } from "graphql/type/common-gql-types";


interface IMessageListProps {
    id         ?: TID
    messages    : IMessageItem[]
    className  ?: string
    withInfo   ?: boolean
    actionMenu ?: (comment : IMessageItem) => JSX.Element | null
}

const MessageList : React.FC<IMessageListProps> = ({
        messages,
        className,
        withInfo = true,
        actionMenu,
    }) : JSX.Element => {


    return (
        <ul className= { `message-list ${ className }` } >
            { messages.map(
                ( item ) => {
                    const { name, surname } = item.user;
                    return(
                        <li key={ item.id }
                            className="message-list-item"
                        >
                            { withInfo &&
                                <div className="info">
                                    <div className="created">
                                        <p className="created-at">
                                            { getDate.formated(item.created_at) }
                                        </p>
                                        <p className="author">
                                            { name } { surname }
                                        </p>
                                    </div>
                                    { actionMenu &&
                                        <div className="action">
                                            { actionMenu( item ) }
                                        </div>
                                    }
                                </div>
                            }
                            <div className="message">
                                { item.message }
                            </div>
                            {item.attachments &&
                                <div className="attachments">
                                    { item.attachments.map(
                                        ( attachment ) => {
                                            return(
                                                <a href={ attachment.server_name }>
                                                    <Icons.Document />
                                                    { attachment.title }
                                                </a>
                                            );
                                        })
                                    }
                                </div>
                            }
                        </li>
                    );
                })
            }
        </ul>
    );
};

export default MessageList;