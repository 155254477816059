import React, { JSX } from 'react';

import { Form } from 'antd';

import CheckListBlocks from "../../blocks";
import { ECheckListType } from "../../check-list-types";
import Localize from "components/service/Localize";

import type { IFormProps } from "../../check-list-types";

const { FormRow, FieldGroup } = CheckListBlocks;
const CheckListExpoDatesOfficialForm : React.FC<IFormProps> = ({ formData, eeId, listGroup }) : JSX.Element => {

    const [ form ] = Form.useForm();

    const defaultFields = {
        ee_id : eeId,
        list_group : listGroup,
        active : false,
        flag : false,
        list_value : ""
    };

    return (
        <div className="check-list-form ">
            <Form
                className     = { "inner-forms" }
                form          = { form }
                layout        = "vertical"
            >
                <Localize
                    tag="h3"
                    wrap
                >{ `CHECKLIST.Form_Title_${listGroup}` }</Localize>
                <FormRow
                    rowData={{
                        title : "StartOfConstruction",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "StartOfConstruction"),
                        list_type : ECheckListType.DATETIME,
                    }}
                >
                    <FieldGroup.SingleDateTime />
                </FormRow>
                <FormRow
                    rowData={{
                        title : "OpeningTime",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "OpeningTime"),
                        list_type : ECheckListType.STR,
                    }}
                >
                    <FieldGroup.SingleText />
                </FormRow>
                <FormRow
                    rowData={{
                        title : "HandoverDate",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "HandoverDate"),
                        list_type : ECheckListType.DATETIME,
                    }}
                >
                    <FieldGroup.SingleDateTime />
                </FormRow>
                <FormRow
                    rowData={{
                        title : "ExhibitionBegins",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "ExhibitionBegins"),
                        list_type : ECheckListType.DATETIME,
                    }}
                >
                    <FieldGroup.SingleDateTime />
                </FormRow>
                <FormRow
                    rowData={{
                        title : "ExhibitionEnds",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "ExhibitionEnds"),
                        list_type : ECheckListType.DATETIME,
                    }}
                >
                    <FieldGroup.SingleDateTime />
                </FormRow>
                <FormRow
                    rowData={{
                        title : "StartOfDeconstruction",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "StartOfDeconstruction"),
                        list_type : ECheckListType.DATETIME,
                    }}
                >
                    <FieldGroup.SingleDateTime />
                </FormRow>
                <FormRow
                    rowData={{
                        title : "EndOfDeconstruction",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "EndOfDeconstruction"),
                        list_type : ECheckListType.DATETIME,
                    }}
                >
                    <FieldGroup.SingleDateTime />
                </FormRow>
            </Form>
        </div>
    );
};

export default CheckListExpoDatesOfficialForm;