import React from "react";

import { useQuery } from "@apollo/client";
import { Radio } from "antd";

import { GET_FLOORINGS } from "graphql/query/flooring-q.gql";

import { whereConditionBuilder } from "common/utils";
import { Loader } from "components/request-result";

import type { IFlooringSelect } from "../flooring-types";


export type TFlooringListProps = {
    eeId          ?: number | undefined
    etId           : number | undefined
    orgId          : number | undefined
    standTypeId    : number | undefined
    setFlooring    : (props : IFlooringSelect) => void
    flooring      ?: IFlooringSelect | undefined
    text          ?: string | undefined
};

const StandTypeRadioList : React.FC<TFlooringListProps> = ({
        etId,
        orgId,
        standTypeId,
        setFlooring,
        flooring,
        text
    }) => {

    const { data, loading } = useQuery( GET_FLOORINGS, {
        variables : {
            text : text && text.length ? text : undefined,
            whereStandTypeFlooring : whereConditionBuilder({
                standType : !standTypeId ? {} : {
                    column : "STAND_TYPE_GROUP",
                    operator : "EQ",
                    value : standTypeId
                },
            }),
            whereExhibitionTypeFlooring : whereConditionBuilder({
                teamRole : !etId ? {} : {
                    column : "ET_ID",
                    operator : "EQ",
                    value : etId
                },
                teamId : !orgId ? {} : {
                    column : "ORGANIZER_ID",
                    operator : "EQ",
                    value : orgId
                },
            }),
            orderBy : [ { column : "TITLE", order : "ASC" } ]
        },
        fetchPolicy : "cache-first",
    });

    const {
        flooringsCursor : {
            edges : floorings = []
        } = {}
    } = data || {};

    return(
        <div className={ `radio-select-wrap ${ loading ? "loading" : "" }`} >
            { loading ?
                <Loader /> :
                <Radio.Group
                    onChange={ (e) => {

                        const flooring = floorings.find(
                            ({ node }) => Number(node.id) === Number(e.target.value)
                        );

                        if(flooring && flooring.node){
                            setFlooring({
                                id : flooring.node.id,
                                title : flooring.node.title
                            });
                        }

                    } }
                    value={ flooring ?  flooring.id : undefined }
                >
                    { floorings.map( (
                        { node : { id, title, article, images } }
                    ) => {


                        return(
                            <Radio key={ id } value={ id } >
                                <div className="img-wrap">
                                    <img src={ images[0].sizes['200x200'] } alt={ images[0].server_name } />
                                </div>
                                <div className="info">
                                    <span>{ title }</span>
                                    <span className="label">{ article } </span>
                                </div>
                                <div className="action"></div>
                            </Radio>
                        );
                    } )}

                </Radio.Group>
            }
        </div>
    );
};

export default StandTypeRadioList;