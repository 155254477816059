import { prepareEditFormDataHelper, prepareEditInitDataHelper } from "./task-form-edit-helper";
import TaskImportanceHelper from "./Task-importance-helper";
import taskStatusClassHelper from "./task-status-helper";


const TaskHelpers = {
    editForm : {
        prepareData : prepareEditFormDataHelper,
        initialData : prepareEditInitDataHelper
    },
    Importance : TaskImportanceHelper,
    status : taskStatusClassHelper
};

export default TaskHelpers;
