import React, { JSX, /*useState*/ } from "react";

import Exhibition from "components/exhibition";
import { DrawerFilter } from "components/service/";


type TStandTypeFilterProps = {
    eeId  ?: number
    etId   : number | undefined
    orgId  : number | undefined
    text  ?: string
};

type TStandTypeFilterBlockProps = {
    readonly children  : ( props ?: any ) => JSX.Element
    filterProps : TStandTypeFilterProps
};

const { useHook } = Exhibition;


const StandTypeFilterBlock : React.FC<TStandTypeFilterBlockProps> = ({ children, filterProps }) : JSX.Element => {

    const organizer = useHook.organizer({ orgId : Number(filterProps.orgId) }),
          exhibitionType =  useHook.exhibitionType({ etId : Number(filterProps.etId) });

    const filterOptions = [
        { value : 0, label : `Show Stand types for ${ exhibitionType.title } exhibition type`, filterFields : [ 'orgId' ] },
        { value : 1, label : `Show Stand types for ${ organizer.title } organizer`, filterFields : [ 'etId' ] },
        { value : 2, label : 'All Stand types', filterFields : [ "etId", 'orgId' ] },
    ];

    return(
        <DrawerFilter<TStandTypeFilterProps>
            drawContent   = { (filteredProps) => children(filteredProps) }
            filterOptions = { filterOptions }
            filterProps   = { filterProps }
        />
    );
};

export default StandTypeFilterBlock;