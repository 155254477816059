import React, { JSX, useState } from "react";

import { Form, FormItemProps, Select } from "antd";

import TeamBlocks from "../blocks";
import TeamLists from "../lists";
import { Icons } from "components/layout";
import { ModalStandard } from "components/service";

import type { TTeamMember } from "../team-types";
import type { ETeamRoles } from "common/types";
import type { NamePath } from "rc-field-form/es/interface";


type TTeamUserProps = {
    teamId         ?: number | undefined
    teamRole       ?: ETeamRoles | undefined
    modalTitle      : string
    currentMember  ?: TTeamMember | undefined
};


const TeamUserSelect : React.FC<TTeamUserProps> = ({
        teamId = undefined,
        teamRole = undefined,
        modalTitle,
        currentMember,
        ...props
    }) : JSX.Element => {

    const form = Form.useFormInstance(),
          [ openModal, setOpenModal ] = useState( false ),
          [ member, setMember ] = useState( currentMember );


    const confirmSelect = (options ?: TTeamMember  ) => {

        if(options){

            const { id } = props as FormItemProps;

            form.setFieldValue(
                id! as NamePath,
                {
                    label : `${ options.name } ${ options.surname }`,
                    value : options.id
                }
            );
        }

        setOpenModal(false);

    };

    return(
        <>
            <Select
                { ...props }
                defaultValue  = { ""! }
                onClick       = { () => setOpenModal(true) }
                dropdownStyle = { { display : "none" } }
                suffixIcon    = { <Icons.Arrow /> }
                options={ [
                    { value : '', label : 'Not selected' }
                ]! }
            />
            <ModalStandard
                title      = { <h2>{ modalTitle }</h2> }
                isOpen     = { openModal }
                extraClass = { "select-modal radio" }
                close      = { () => setOpenModal(false) }
                confirm    = { () => confirmSelect( member ) }
            >
                <TeamBlocks.UserFilter
                    filterProps = {{
                        teamId,
                        teamRole
                    }}
                >
                    { (filteredProps : TTeamUserProps) => (
                        <TeamLists.UserRadio
                            member    = { member }
                            setMember = { setMember }
                            { ...filteredProps}
                        />
                    ) }

                </TeamBlocks.UserFilter>
            </ModalStandard>
        </>
    );
};

export default React.memo(TeamUserSelect);