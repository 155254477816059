import React, { JSX, ReactNode } from "react";


type TTagStandardProps = {
    className ?: string
    children : ReactNode
};

const TagStandard: React.FC<TTagStandardProps> = ({ className = "new", children }) :JSX.Element => {
    return(
        <div className={ `tag-standard ${ className }` }>
            { children }
        </div>
    );
};


export default TagStandard;