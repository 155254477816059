import React, { JSX } from 'react';

import { Form } from 'antd';

import CheckListBlocks from "../../blocks";
import { ECheckListType, IFormProps } from "../../check-list-types";
import Localize from "components/service/Localize";


const { FormRow, FieldGroup } = CheckListBlocks;

const CheckListParticipantsSubcontractorsForm : React.FC<IFormProps > = ({
        formData,
        eeId,
        listGroup
    }) : JSX.Element => {

    const [ form ] = Form.useForm();

    const defaultFields = {
        ee_id : eeId,
        list_group : listGroup,
        active : false,
        flag : false,
        list_value : ""
    };

    return (
        <div className="check-list-form ">
            <Form
                className={"inner-forms"}
                form={form}
                layout="vertical"
            >
                <Localize tag="h3" wrap>
                    { `CHECKLIST.Form_Title_${listGroup}` }
                </Localize>
                <FormRow
                    rowData={{
                        title : "ForwarderOutwardAndReturnTransportation",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "ForwarderOutwardAndReturnTransportation"),
                        list_type : ECheckListType.CONTACT,
                    }}
                >
                    <FieldGroup.Contact/>
                </FormRow>
                <FormRow
                    rowData={{
                        title : "LogisticsCompanyOnSite",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "LogisticsCompanyOnSite"),
                        list_type : ECheckListType.CONTACT,
                    }}
                >
                    <FieldGroup.Contact/>
                </FormRow>
                <FormRow
                    rowData={{
                        title : "CarpetCompanyLaying",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "CarpetCompanyLaying"),
                        list_type : ECheckListType.CONTACT,
                    }}
                >
                    <FieldGroup.Contact/>
                </FormRow>
                <FormRow
                    rowData={{
                        title : "FurnitureSupplier1",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "FurnitureSupplier1"),
                        list_type : ECheckListType.CONTACT,
                    }}
                >
                    <FieldGroup.Contact/>
                </FormRow>
                <FormRow
                    rowData={{
                        title : "FurnitureSupplier2",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "FurnitureSupplier2"),
                        list_type : ECheckListType.CONTACT,
                    }}
                >
                    <FieldGroup.Contact/>
                </FormRow>
                <FormRow
                    rowData={{
                        title : "InstallationCompany1",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "InstallationCompany1"),
                        list_type : ECheckListType.CONTACT,
                    }}
                >
                    <FieldGroup.Contact/>
                </FormRow>
                <FormRow
                    rowData={{
                        title : "InstallationCompany2",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "InstallationCompany2"),
                        list_type : ECheckListType.CONTACT,
                    }}
                >
                    <FieldGroup.Contact/>
                </FormRow>
                <FormRow
                    rowData={{
                        title : "InstallationCompany3",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "InstallationCompany3"),
                        list_type : ECheckListType.CONTACT,
                    }}
                >
                    <FieldGroup.Contact/>
                </FormRow>
                <FormRow
                    rowData={{
                        title : "CleaningCompany",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "CleaningCompany"),
                        list_type : ECheckListType.CONTACT,
                    }}
                >
                    <FieldGroup.Contact/>
                </FormRow>
            </Form>
        </div>
    );
};

export default CheckListParticipantsSubcontractorsForm;