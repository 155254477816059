import React, { FunctionComponent, JSX } from 'react';

import Icon from '@ant-design/icons';

import { IIcon } from "./icons-interfaces";
import { Loader } from "../../request-result";


const CheckSvg : React.FC = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M19.32,39.97h0c-1.06,0-2.08-.42-2.83-1.17L2.66,24.97c-1.56-1.56-1.56-4.1,0-5.66,1.56-1.56,4.09-1.56,
                 5.66,0l11,11,20.18-20.18c1.56-1.56,4.09-1.56,5.66,0,1.56,1.56,1.56,4.1,0,5.66l-23.01,23.01c-.75.75-1.77,
                 1.17-2.83,1.17Z"
        />
    </svg>
);

const CheckIcon: FunctionComponent<IIcon> = (props): JSX.Element => {

    if (props.loading) {
        return <Loader/>;
    }

    return <Icon component={ CheckSvg } className={ props.className }/>;
};

export default CheckIcon;