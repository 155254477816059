import React, { JSX } from "react";

import { Input } from "antd";

import StandFields from "../fields";


interface IStandCadInfoFormProps {
    standId : string | number
}

const StandCadInfoForm : React.FC<IStandCadInfoFormProps> = ({ standId }) : JSX.Element => {
    
    return(
        <div className="stand-cad-info-form grid-two-col">
            <div className="cad-number-wrap">
                <h5 className="label">CAD number</h5>
                <Input name="cad-number"/>
            </div>
            <div className="">
                <h5 className="label">Readiness status</h5>
                <StandFields.CadStatus standId={ standId } />
            </div>
        </div>
    );
};

export default StandCadInfoForm;