import React from 'react';

import { ApolloProvider } from "@apollo/client";
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";

import client from "graphql/client";

import App from 'components/app';
import Auth from "components/auth";
import { AntdProvider, InitHookSetup } from "components/service";

import "scss/main.scss";


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
      <ApolloProvider client={ client }>
          <AntdProvider>
              <BrowserRouter>
                  <InitHookSetup />
                  <Auth
                    public = {
                      <App.Public />
                    }
                    private ={
                      <App.Private />
                    }
                  />
              </BrowserRouter>
          </AntdProvider>
      </ApolloProvider>
  </React.StrictMode>
);
