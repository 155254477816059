import React, { FunctionComponent, JSX } from 'react';

import Icon from '@ant-design/icons';

import { IIcon } from "./icons-interfaces";
import { Loader } from "components/request-result";


const AttachSvg : React.FC = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M27.6,3.2c-5.3,0-9.6,3.9-9.6,8.6v20.8c0,3.3,3,6,6.6,6s6.6-2.7,6.6-6v-16.5c0-1.1-.9-2-2-2s-2,.9-2,2v16.5c0,1.1-1.2,2-2.6,2s-2.6-.9-2.6-2V11.8c0-2.5,2.5-4.6,5.6-4.6s5.6,2.1,5.6,4.6v22.6c0,3.9-3.7,7-8.3,7s-8.3-3.2-8.3-7v-18.3c0-1.1-.9-2-2-2s-2,.9-2,2v18.3c0,6.1,5.5,11,12.3,11s12.3-5,12.3-11V11.8c0-4.7-4.3-8.6-9.6-8.6Z"/>
    </svg>
);

const AttachIcon: FunctionComponent<IIcon> = (props): JSX.Element => {

    if (props.loading) {
        return <Loader/>;
    }

    return <Icon component={AttachSvg} className={props.className}/>;
};

export default AttachIcon;