import React, { FunctionComponent, JSX } from 'react';

import Icon from '@ant-design/icons';

import { IIcon } from "./icons-interfaces";


interface IStandCount extends IIcon{
    type ?: "total" | "inProcess" | "completed"
}

const StandTotalSvg : React.FC = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M38.6,43.1H8.9c-0.8,0-1.5-0.5-1.8-1.2c-0.3-0.7-0.2-1.6,0.4-2.2l15.2-16L7.7,7.5C7.2,6.9,7,6.1,7.4,5.3
	c0.3-0.7,1-1.2,1.8-1.2h29.9c1.1,0,2,0.9,2,2v5.3c0,1.1-0.9,2-2,2s-2-0.9-2-2V8.1H13.7l13.1,14.3c0.7,0.8,0.7,2,0,2.7l-13.3,14h23
	v-3.4c0-1.1,0.9-2,2-2s2,0.9,2,2v5.4C40.6,42.2,39.7,43.1,38.6,43.1z"/>
    </svg>
);

const StandInProcessSvg : React.FC = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M43.3,8.3c-1.1-0.3-2.2,0.4-2.4,1.5l-1.7,7.3c-1.7-4.3-5-7.8-9.3-9.8C25.5,5.3,20.5,5,15.9,6.7c-4.6,1.7-8.3,5-10.4,9.5
	c-2.1,4.5-2.3,9.4-0.6,14.1c1.7,4.6,5,8.3,9.5,10.4c2.5,1.2,5.1,1.7,7.8,1.7c2.1,0,4.2-0.4,6.3-1.1c1-0.4,1.6-1.5,1.2-2.6
	c-0.4-1-1.5-1.6-2.6-1.2c-3.6,1.3-7.5,1.1-11-0.5c-3.5-1.6-6.1-4.5-7.4-8.1c-1.3-3.6-1.1-7.5,0.5-11c1.6-3.5,4.5-6.1,8.1-7.4
	c3.6-1.3,7.5-1.1,11,0.5c3.3,1.5,5.8,4.2,7.2,7.6l-6.1-1.4c-1.1-0.3-2.2,0.4-2.4,1.5c-0.2,1.1,0.4,2.2,1.5,2.4l13.2,3.1l3.1-13.4
	C45,9.6,44.4,8.6,43.3,8.3z"/>
    </svg>
);

const StandCompletedSvg : React.FC = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M24.1,43.1C13.6,43.1,5,34.5,5,24S13.6,4.9,24.1,4.9c2.6,0,5.1,0.5,7.4,1.5c1,0.4,1.5,1.6,1.1,2.6c-0.4,1-1.6,1.5-2.6,1.1
		c-1.9-0.8-3.8-1.2-5.9-1.2C15.8,8.9,9,15.7,9,24s6.8,15.1,15.1,15.1S39.2,32.3,39.2,24c0-1.1,0.9-2,2-2s2,0.9,2,2
		C43.2,34.5,34.6,43.1,24.1,43.1z"/>
        <path d="M24.3,31.4L14.1,21.1c-0.8-0.8-0.8-2,0-2.8c0.8-0.8,2-0.8,2.8,0l7.4,7.4l14.2-14.2c0.8-0.8,2-0.8,2.8,0
		c0.8,0.8,0.8,2,0,2.8L24.3,31.4z"/>

    </svg>
);


const StandCountIcon : FunctionComponent<IStandCount> = ({ className, type }) : JSX.Element => {

    if(type === "inProcess"){
        return <Icon component={ StandInProcessSvg } className={ className } />;
    }

    if(type === "completed"){
        return <Icon component={ StandCompletedSvg } className={ className } />;
    }

    return <Icon component={ StandTotalSvg } className={ className } />;

};


export default StandCountIcon;