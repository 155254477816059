import React, { JSX, useState } from "react";

import { gql, useApolloClient } from "@apollo/client";
import { Select } from "antd";

import { Icons } from "../../layout";

import { ETeamRoles } from "common/types";
 

type TFilterProps = {
    teamId : number | undefined
    teamRole : ETeamRoles | undefined
};

type TUserFilterProps = {
    readonly children  : ( props ?: any ) => JSX.Element
             filterProps : TFilterProps
};


const TeamUserFilterBlock : React.FC<TUserFilterProps> = ({ children, filterProps }) : JSX.Element => {

    const client = useApolloClient(),
          [ filterType,  setFilterType ] = useState(0);

    const team = client.readFragment({
        id : `Team:${ filterProps.teamId }`,
        fragment : gql`
            fragment TeamFields on Team {
                id
                title
            }
        `
    });

    const filterOptions = [];

    if(team){
        if(filterProps.teamRole === ETeamRoles.DRAFTSMEN){
            filterOptions.push(
                { value : 0, label : `Show just ${ team.title } team Autocad engineers`, filterFields : [] },
                { value : 1, label : 'Autocad engineers from all teams', filterFields : [ "teamId" ] }
            );
        } else {
            filterOptions.push(
                { value : 0, label : `Show just ${ team.title } team managers`, filterFields : [] },
                { value : 1, label : 'Managers from all teams', filterFields : [ "teamId" ] },
            );
        }
    } else {
        filterOptions.push(
            { value : 0, label : 'Members from all teams', filterFields : [ "teamId" ] }
        );
    }


    const filteredProps = { ...filterProps };

    filterOptions[filterType].filterFields.forEach( (item ) => {
            delete filteredProps[item as keyof TFilterProps];
        }
    );

    return(
        <>
            <div className="modal-filter">
                <Select
                    popupClassName = "modal-filter-dropdown"
                    suffixIcon  = { <Icons.Arrow /> }
                    onChange    = { (value) => setFilterType(value) }
                    value       = { filterType! }
                    options     = { filterOptions! }
                />
            </div>
            { children( filteredProps ) }
        </>
    );
};

export default TeamUserFilterBlock;