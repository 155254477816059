import React, { JSX } from "react";


interface IPageHeaderProps{
    title       : JSX.Element | string
    loading    ?: boolean
    classname  ?: string
    backButton ?: JSX.Element
}


const PageHeaderBlock : React.FC<IPageHeaderProps> = ({ title, loading, classname = "" }) : JSX.Element => {

    return(
        <div className={`page-header-block ${ classname }`}>
            {/*<div className="row">*/}
            {/*    <div className="back-wrap"></div>*/}
            {/*    <div className="exhibition-selector"></div>*/}
            {/*</div>*/}
            <h1 className="page-title">
                { !loading && title }
            </h1>
        </div>
    );
};


export default PageHeaderBlock;