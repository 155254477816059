import { gql } from '@apollo/client';


export const GET_CHECKLISTS = gql`
    query CheckLists (
        $text    : String
        $where   : QueryCheckListsWhereWhereConditions
        $orderBy : [QueryCheckListsOrderByOrderByClause!]
    ){
        checkLists(
            text    : $text
            where   : $where
            orderBy : $orderBy
            first   : 150
            page    : 1
        ) {
            data {
                id
                title
                ee_id
                list_group
                list_type
                active
                list_value
                flag
                comment
                created_at
                updated_at
            }
        }
    }
`;
