import MessageList from "./message-list";

import "./layout-lists.scss";


const LayoutList = {
    Messages : MessageList
};

export default LayoutList;
