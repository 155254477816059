import CheckListCommentField from "./Check-list-comment-field";
import CheckListDatePickerField from "./Check-list-date-picker-field";
import CheckListMoneyField from "./Check-list-money-field";
import CheckListTextField from "./Check-list-text-field";
import CheckListTimePickerField from "./Check-list-time-field";

import "./check-list-fields.scss";


const CheckListFields = {
    Comment : CheckListCommentField,
    Money : CheckListMoneyField,
    Text : CheckListTextField,
    DatePicker : CheckListDatePickerField,
    TimePicker : CheckListTimePickerField
};

export default CheckListFields;