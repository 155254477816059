import React, { JSX } from "react";

import CheckListFields from "../../fields";
import CheckListHelpers from "../../helpers";

import type { IFieldBlockProps } from "../../check-list-types";


const fieldStructure = {
    companyName : undefined,
    personName : undefined,
    phone_1 : undefined,
    phone_2 : undefined,
    email : undefined
};

interface ICheckListContactProps extends IFieldBlockProps{
    placeholder ?: string
}

const { parseValues } = CheckListHelpers;

const CheckListContactGroupBlock : React.FC<ICheckListContactProps> = ({
        values,
        ...props
    }) : JSX.Element => {

    const fields = parseValues<typeof values, typeof fieldStructure>(
        {
            values,
            structure : fieldStructure
        });

    return(
        <div className="check-list-group grid-two-col">
            <div className="col-one">
                <div>
                    <CheckListFields.Text
                        value={ fields.companyName }
                        multiField  = {{
                            current : 'companyName',
                            structure : fields
                        }}
                        placeholder={ "Company name" }
                        { ...props }
                    />
                </div>
                <div className="grid-two-col">
                    <div>
                        <CheckListFields.Text
                            value={ fields.phone_1 }
                            multiField  = {{
                                current : 'phone_1',
                                structure : fields
                            }}
                            placeholder={ "Phone 1" }
                            { ...props }
                        />
                    </div>
                    <div>
                        <CheckListFields.Text
                            value={ fields.phone_2 }
                            multiField  = {{
                                current : 'phone_2',
                                structure : fields
                            }}
                            placeholder={ "Phone 2" }
                            { ...props }
                        />
                    </div>
                </div>
            </div>
            <div className="col-two">
                <div>
                    <CheckListFields.Text
                        value={ fields.personName }
                        multiField  = {{
                            current : 'personName',
                            structure : fields
                        }}
                        placeholder={ "Person name" }
                        { ...props }
                    />
                </div>
                <div>
                    <CheckListFields.Text
                        value={ fields.email }
                        multiField  = {{
                            current : 'email',
                            structure : fields
                        }}
                        placeholder={ "E-mail" }
                        { ...props }
                    />
                </div>
            </div>
        </div>
    );
};

export default CheckListContactGroupBlock;