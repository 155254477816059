import React, { JSX }  from "react";

import { useOutletContext } from "react-router-dom";

import CheckList from "components/check-list";
import type { TCheckListGroups } from "components/check-list/check-list-types";


const { Crafts } = CheckList.Forms;

const CheckListCraftsSubPage : React.FC = () : JSX.Element => {

    const { ee, checkLists } = useOutletContext<{ee : any, checkLists: TCheckListGroups  }>();

    const { fieldGroups } = checkLists.Crafts;

    return(
        <div className="page-content">
            <Crafts.Logistic
                eeId      = { ee.id }
                listGroup = "Logistics"
                formData  = { fieldGroups.Logistics }
            />
            <Crafts.Assembly
                eeId      = { ee.id }
                listGroup = "Assembly"
                formData  = { fieldGroups.Assembly }
            />
            <Crafts.StandConstruction
                eeId      = { ee.id }
                listGroup = "StandConstruction"
                formData  = { fieldGroups.StandConstruction }
            />
            <Crafts.Flooring
                eeId      = { ee.id }
                listGroup = "FloorCoverings"
                formData  = { fieldGroups.FloorCoverings }
            />
            <Crafts.Furniture
                eeId      = { ee.id }
                listGroup = "Furniture"
                formData  = { fieldGroups.Furniture }
            />
            <Crafts.Graphic
                eeId      = { ee.id }
                listGroup = "Graphics"
                formData  = { fieldGroups.Graphics }
            />
            <Crafts.Electric
                eeId      = { ee.id }
                listGroup = "Electric"
                formData  = { fieldGroups.Electric }
            />
            <Crafts.Team
                eeId      = { ee.id }
                listGroup = "Team"
                formData  = { fieldGroups.Team }
            />
            <Crafts.Documents
                eeId      = { ee.id }
                listGroup = "Documents"
                formData  = { fieldGroups.Documents }
            />
        </div>
    );
};

export default CheckListCraftsSubPage;