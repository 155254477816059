import PrivateApp  from './Private-app';
import PublicApp from "./Public-app";

import "./app.scss";


const App = {
    Public : PublicApp,
    Private : PrivateApp
};


export default App;