import { gql } from "@apollo/client";

export const CHECKLIST_CREATE = gql`
    mutation CheckListCreate($input: CheckListFieldsInput!) {
        checkListCreate(input: $input){
            label
            message
            checkList {
                id
                title
                ee_id
                list_group
                list_type
                active
                list_value
                flag
                comment
                created_at
                updated_at
            }
        }
    }
`;

export const CHECKLIST_UPDATE = gql`
    mutation CheckListUpdate($input: CheckListFieldsInput!) {
        checkListUpdate(input: $input){
            label
            message
            checkList {
                id
                title
                ee_id
                list_group
                list_type
                active
                list_value
                flag
                comment
                created_at
                updated_at
            }
        }
    }
`;

export const CHECKLIST_DELETE = gql`
    mutation CheckListDelete($id: ID!) {
        checkListDelete(id: $id){
            label
            message
        }
    }
`;