import React, { JSX } from "react";

import { gql, useApolloClient } from "@apollo/client";
import { Select } from "antd";

import Localize from "../../service/Localize";
import { Icons } from "components/layout";


const { Option } = Select;

type TStandOrientationProps = {
    standTypeId : string
};

const StandOrientationSelect : React.FC<TStandOrientationProps> = ({ standTypeId, ...props }) : JSX.Element | null => {

    const client = useApolloClient();

    const standType = client.readFragment({
        id : `StandType:${ standTypeId }`,
        fragment : gql`
            fragment StandTypeFields on StandType {
                id
                title
                orientations{
                    id
                    orientation_key
                    img_path
                }
            }
        `
    });

    return(
        <Select
            { ...props }
            className      = "stand-orientation-select"
            popupClassName = "stand-orientation-select-popup"
            defaultValue   = { ""! }
            suffixIcon     = { <Icons.Arrow /> }
        >
            <Option key={ 0 } value={ "" }>Not selected</Option>
            {standType && standType.orientations && standType.orientations.map(
                ({ id, orientation_key, img_path } : { id: number, orientation_key: string, img_path: string }) => {
                    return(
                        <Option key={ id } value={ orientation_key } >
                            <span className="img-wrap">
                                <img src={img_path} alt={`${ orientation_key } image`} />
                            </span>
                            <Localize wrap className="title">
                                { `VARIABLE_ITEM.${ orientation_key }` }
                            </Localize>
                        </Option>
                    );
                }
            )
            }
        </Select>
    );
};

export default React.memo(StandOrientationSelect);