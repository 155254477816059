import React, { JSX, useEffect }  from "react";

import { useQuery } from "@apollo/client";
import { Navigate, Outlet, useParams } from "react-router-dom";
 
import { GET_EE } from "graphql/query/exhibition-q.gql";

import { reactiveVarHelper } from "common/helpers";
import { Blocks, Menus } from "components/layout";
import { Loader } from "components/request-result";
import { ROUTES } from "components/routes";


const ExhibitionSection : React.FC = () : JSX.Element => {

    const rVar = reactiveVarHelper(),
          pParams = useParams(),
          isEeCreate = pParams.eeId === "create";

    const { data : { exhibitionEvent  } = { },
        loading,
        error
    } = useQuery( GET_EE, {
        variables : {
            id : pParams.eeId
        },
        skip : isEeCreate,
        fetchPolicy : "cache-first"
    });

    useEffect( () => {

        rVar.set({
            mainBlockClass : [ "wSidebar" ]
        });

        return () => {
            rVar.set({
                mainBlockClass : []
            });
        };
    });

    if((error || isNaN(Number(pParams.eeId)) && !isEeCreate) || (!exhibitionEvent && !isEeCreate && !loading)){
        return <Navigate to={ ROUTES.EXHIBITION_ITEM_CREATE } replace={ true } />;
    }

    return(
        <div className="section exhibition">
            { loading ? <Loader type={ "block" } /> :
                <>
                    <Blocks.Sidebar>
                        <Menus.Exhibition />
                    </Blocks.Sidebar>
                    <Outlet
                        context={{
                            ee : exhibitionEvent
                        }}
                    />
                </>
            }
        </div>
    );
};

export default ExhibitionSection;