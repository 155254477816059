import React, { JSX, useState } from "react";

import { Button } from "antd";
import { TEquipmentItem, TEquipmentVariationItem } from "graphql/type/equipment-gql-types";

import EquipmentBlocks from "../blocks";
import { Icons } from "components/layout";
import { DrawerStandard } from "components/service";


interface EquipmentSelectModalProps {
    equipment : TEquipmentItem
    variation : TEquipmentVariationItem
    btnClass       ?: string
}

const EquipmentInfoDrawer : React.FC<EquipmentSelectModalProps> = ({
        btnClass = 'blue',
        ...props
    }) : JSX.Element => {


    const [ openDrawer, setOpenDrawer ] = useState( false );
    return (
        <>
            <Button
                type      = "text"
                className = { btnClass }
                onClick   = { () => setOpenDrawer(true) }
            >
                <Icons.Info type={ 'squared' } /> Info
            </Button>
            <DrawerStandard
                title      = { <h2>Equipment info</h2> }
                isOpen     = { openDrawer }
                extraClass = { "select-modal radio" }
                close      = { () => setOpenDrawer(false) }
            >
                <EquipmentBlocks.info
                    { ...props }
                />
            </DrawerStandard>
        </>

    );
};

export default EquipmentInfoDrawer;