import CheckListCheckFieldBtn from "./Check-list-check-field-btn";
import CheckListDeleteCommentBtn from "./Check-list-delete-comment-btn";
import CheckListImportantFieldBtn from "./Check-list-important-field-btn";

import "./check-list-buttons.scss";


const CheckListButtons = {
    DeleteComment : CheckListDeleteCommentBtn,
    ImportantField : CheckListImportantFieldBtn,
    CheckField : CheckListCheckFieldBtn

};

export default CheckListButtons;