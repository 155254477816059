import React, { JSX } from "react";

import StandTypeLists from "../lists";
import { Blocks } from "components/layout";

interface IStandTypeFilesBlockProps {
    standTypeGroupId : string
}

const StandTypeFilesBlock : React.FC<IStandTypeFilesBlockProps> = ({ standTypeGroupId }) : JSX.Element => {
    
    return(
        <Blocks.Card
            Header = { "Files" }
        >
            <StandTypeLists.File
                standTypeGroupId = { standTypeGroupId }
            />
        </Blocks.Card>
    );
};

export default StandTypeFilesBlock;