import CheckListCraftsSubPage from "./Check-list-crafts-sub-page";
import CheckListExpoDatesSubPage from "./Check-list-expo-dates-sub-page";
import CheckListInvoiceSubPage from "./Check-list-invoice-sub-page";
import CheckListParticipantsSubPage from "./Check-list-participants-sub-page";
import CheckListRecalculationSubPage from "./Check-list-recalculation-sub-page";
import CheckListSitePlanningSubPage from "./Check-list-site-planning-sub-page";


const CheckListSub = {
    Crafts : CheckListCraftsSubPage,
    ExpoDates : CheckListExpoDatesSubPage,
    Invoice : CheckListInvoiceSubPage,
    Participants : CheckListParticipantsSubPage,
    Recalculation : CheckListRecalculationSubPage,
    SitePlanning : CheckListSitePlanningSubPage,
};

export default CheckListSub;