const taskStatusClassHelper = (name ?: string) => {
    switch (name){
        case 'task_status_in_work'   : return 'in-progress';
        case 'task_status_cancelled' : return 'cancelled';
        case 'task_status_blocked'   : return 'blocked';
        case 'task_status_ready'     : return 'ready';
        default                      : return 'new';
    }
};

export default taskStatusClassHelper;