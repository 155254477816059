import React, { JSX } from "react";

import { Select } from "antd";

import TaskHelpers from "../helpers";
import { reactiveVarHelper } from "common/helpers";
import { Icons } from "components/layout";

import type { TVariableItem } from "graphql/type/common-gql-types";


const { Option } = Select;

interface ITaskImportanceSelectProps {
    importance  ?: string
}

const TaskImportanceSelect : React.FC<ITaskImportanceSelectProps> = ({  importance, ...props }) : JSX.Element => {

    const variableLists = reactiveVarHelper().get('variableLists') as TVariableItem[];

    const taskVariables = variableLists.filter(
        (listItem :  TVariableItem) => listItem.group_name === 'task importance'
    ) ;

    return(
        <Select
            { ...props }
            defaultValue={ importance || "" }
            suffixIcon    = { <Icons.Arrow /> }
        >
            { !importance &&
                <Option key={ 0 } value={ "" }>Not selected</Option>
            }
            { taskVariables.map(
                ({ id, slug }) => {
                    return(
                        <Option key={ id } value={ slug }>
                            <TaskHelpers.Importance
                                name     = { slug }
                                onlyIcon = { false }
                            />
                        </Option>
                    );
                })
            }
        </Select>
    );
};

export default React.memo(TaskImportanceSelect);