//import React, { JSX } from "react";
import { fieldGroupSectionConnection } from "./helpers/check-list-structure-helper";


export type TChecklistTypeName = keyof typeof fieldGroupSectionConnection;
export type TCheckListSectionNames = typeof fieldGroupSectionConnection[TChecklistTypeName];

export interface ICheckListFieldStatistic {
    total : number,
    finished : number
    unFinished? : number
}

export interface IGroupItem {
    fieldStatistic: ICheckListFieldStatistic
    fieldGroups : {
        [ key in TChecklistTypeName ]? : TCheckList
    }
}

export type TCheckListGroups = {
    [ key in TCheckListSectionNames ] : IGroupItem
};


export interface ICheckListItem  {
    id : number
    title ?: string
    ee_id : number
    list_group: TChecklistTypeName
    list_type: ECheckListType
    active : boolean
    list_value : any
    flag : boolean
    comment : string
    created_at ?: string
    updated_at ?: string
    __typename ?: string
}


export type TCheckList = ICheckListItem[];

export interface IFormProps {
    eeId : number
    formData ?: TCheckList
    listGroup ?: TChecklistTypeName
}

export enum ECheckListType {
    'STR'= 'string',
    'NUM'= 'number',
    'DATE'= 'date',
    'TIME'= 'time',
    'DATE_RANGE' = 'date_range',
    'CONTACT' = 'contact',
    'TEXT_DATE' = 'text_date',
    'DATE_AND_TIME' = 'date_and_time',
    'DATETIME' = 'datetime',
    'DAYDATE' = 'daydate',
    'DAYDATETIME' = 'daydatetime',
    'DAYDATE_TIME' = 'daydate_time',
    'MONEY' = 'money',
    'MONEY_DATE' = 'money_date',
    'PLANNING' = 'planning'
}

export interface IFieldProps {
    value        ?: string | number
    type         ?: ECheckListType,
    handleMutate ?: <T, >(props : T) => void,
    loading      ?: boolean
    placeholder  ?: string
    multiField   ?: {
        current : string
        structure : object
    }
}

export interface IFieldBlockProps {
    values       ?: any
    type         ?: ECheckListType,
    handleMutate ?: <T, >(props : T) => void,
    loading      ?: boolean
    placeholder  ?: string | object
}
