import EquipmentBlocks from "./blocks";
import EquipmentHelpers from "./helpers";
import EquipmentLists from "./lists";
import EquipmentModals from "./modals";
import EquipmentTables from "./tables";


const Equipment  = {
    Blocks : EquipmentBlocks,
    Lists : EquipmentLists,
    Helpers : EquipmentHelpers,
    Tables : EquipmentTables,
    Modals : EquipmentModals,
};

export default Equipment;