import React, { JSX } from 'react';

import { useQuery } from "@apollo/client";
import { Table } from 'antd';

import { GET_STAND_EQUIPMENT_ORDERS } from "graphql/query/equipment-q.gql";

import EquipmentHelpers from "../helpers";
import { reactiveVarHelper } from "common/helpers";
import { whereConditionBuilder } from "common/utils";

import type { TFilterFields } from "common/types";
import type { TID } from "graphql/type/common-gql-types";
import type { TEquipmentOrderType } from "graphql/type/equipment-gql-types";


interface IStandsTableProps {
    filters ?: TFilterFields
    standId  : TID
    etId     : TID
    orgId    : TID
    type     : TEquipmentOrderType
}

const EquipmentOrderTable : React.FC<IStandsTableProps> = (
        { filters = { text : '' },
        type,
        standId,
        etId,
        orgId
    }) : JSX.Element  => {

    const rVar = reactiveVarHelper(),
          me = rVar.get('me');

    const { data : { standEquipmentOrdersCursor } = { },
        loading,
        // error
    } = useQuery( GET_STAND_EQUIPMENT_ORDERS, {
        variables : {
            text : filters.text,
            where : whereConditionBuilder({
                type : !type ? {} : {
                    column : "SET_TYPE",
                    operator : "EQ",
                    value : type
                },
                teamId : ! standId ? {} : {
                    column : "STAND_ID",
                    operator : "EQ",
                    value : standId
                },
            }),
            orderBy : [ { column : "ID", order : "DESC" } ]
        },
        fetchPolicy : "cache-and-network"
    });

    const { edges : equipmentOrders = [] } = standEquipmentOrdersCursor || {};

    const { columns, dataMap } = EquipmentHelpers.orderTable;

    return(
        <div className="equipment-order-table-wrap">
            <Table
                className   = "equipment-order-table"
                columns     = {
                    columns.filter(
                        (item) => item.dataIndex !== 'defaultQuantity' || type === 'default'
                    )!
                }
                dataSource  = { dataMap( equipmentOrders, me.lang_api, etId, orgId, type ) }
                pagination  = { false }
                loading     = { loading && !equipmentOrders.length }
            />
        </div>
    );
};

export default EquipmentOrderTable;