import React, { JSX } from "react";

import { generatePath, NavLink, useParams } from "react-router-dom";

import { Icons } from "components/layout";
import { ROUTES } from "components/routes";


const ExhibitionMenu : React.FC = () : JSX.Element => {

    const pParams = useParams(),
          isEeCreate = pParams.eeId === "create";

    return (
        <nav className="exhibition-menu-wrap">
            <ul className="second-lvl-menu exhibition-menu">
                <li>
                    <NavLink to={
                        generatePath(ROUTES.EXHIBITION_DETAILS_FULL,  pParams)
                    }>
                        <Icons.ExpoDetails />
                        <span className="label">
                            Expo details
                        </span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={ generatePath(ROUTES.STAND_LIST_FULL,  pParams) }
                             className={ isEeCreate ? "link-disabled" : "" }
                             onClick={ (event) => isEeCreate ? event.preventDefault() : "" }
                    >
                        <Icons.StandList />
                        <span className="label">
                            Stand List
                        </span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={ generatePath(ROUTES.GRAPHIC_LIST_FULL,  pParams) }
                             className={ isEeCreate ? "link-disabled" : "" }
                             onClick={ (event) => isEeCreate ? event.preventDefault() : "" }
                    >
                        <Icons.GraphicList />
                        <span className="label">
                            Graphic list
                        </span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={ generatePath(ROUTES.CHECK_LIST_FULL,  pParams) }
                             className={ isEeCreate ? "link-disabled" : "" }
                             onClick={ (event) => isEeCreate ? event.preventDefault() : "" }
                    >
                        <Icons.CheckList />
                        <span className="label">
                            Check list
                        </span>
                    </NavLink>
                </li>
            </ul>
        </nav>
    );
};

export default  React.memo(ExhibitionMenu);