import TaskAllList from "./Task-all-list";
import TaskCommentList from "./Task-comment-list";

import "./task-lists.scss";


const TaskLists = {
    Comments : TaskCommentList,
    All : TaskAllList,
};

export default TaskLists;
