import React, { FunctionComponent, JSX } from 'react';

import Icon from '@ant-design/icons';

import { IIcon } from "./icons-interfaces";


const ArrowSvg : React.FC = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M37.3,24c0,1.1-0.4,2.1-1.2,2.9L17.5,44.8c-1.6,1.5-4.1,1.5-5.7-0.1c-1.5-1.6-1.5-4.1,0.1-5.7L27.6,24L11.9,9
	c-1.6-1.5-1.6-4.1-0.1-5.7c1.5-1.6,4.1-1.6,5.7-0.1l18.7,17.9C36.9,21.9,37.3,22.9,37.3,24z"/>
    </svg>
);

const ArrowFullSvg : React.FC = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M7.6,22.9h29.2L22.9,9.3c-0.6-0.6-0.6-1.5,0-2.1c0.6-0.6,1.5-0.6,2.1,0l16.5,16.2c0.3,0.3,0.5,0.7,0.5,1.1s-0.2,0.8-0.5,1.1
	        L25,41.6c-0.3,0.3-0.7,0.4-1,0.4c-0.4,0-0.8-0.2-1.1-0.5c-0.6-0.6-0.6-1.5,0-2.1l13.9-13.6H7.6c-0.8,0-1.5-0.7-1.5-1.5
	        S6.7,22.9,7.6,22.9z"
        />
    </svg>
);

const ArrowIcon : FunctionComponent<IIcon> = ( props ): JSX.Element => {

    if(props.type === "full"){
        return <Icon className={ props.className } component={ ArrowFullSvg } />;
    }

    return <Icon className={ props.className } component={ ArrowSvg } />;

};


export default ArrowIcon;