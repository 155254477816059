import { TTranslations } from "common/types";

const deutsch : TTranslations  = {
    'FORM_RULES.Required_Login' : 'Bitte Login eingeben',
    'FORM_RULES.Required_Password' : 'Bitte Passwort eingeben',
    'FORMS.Input_Label_Login' : 'Anmelden',
    'FORMS.Input_Label_Password' : 'Passwort',
    'FORMS.Button_Text_Login' : 'Login',
    'CHECKLIST.Menu_Label_ExpoDates' : 'Expo dates { status }',
    'CHECKLIST.Menu_Label_Crafts' : 'Crafts { status }',
    'CHECKLIST.Menu_Label_SitePlanning' : 'Site planning { status }',
    'CHECKLIST.Menu_Label_Participants' : 'Participants { status }',
    'CHECKLIST.Menu_Label_Invoice' : 'Invoice { status }',
    'CHECKLIST.Menu_Label_Recalculation' : 'Recalculation { status }',
    'CHECKLIST.Form_Title_OfficialDates' : 'Official dates',
    'CHECKLIST.Form_Title_AdditionalDates' : 'Additional dates',
    'CHECKLIST.Form_Title_InternalDates' : 'Internal dates',
    'CHECKLIST.Form_Title_Logistics' : 'Transportation/logistics/empties',
    'CHECKLIST.Form_Title_Assembly' : 'Assembly',
    'CHECKLIST.Form_Title_StandConstruction' : 'mp-internal / stand construction',
    'CHECKLIST.Form_Title_FloorCoverings' : 'Floor coverings',
    'CHECKLIST.Form_Title_Furniture' : 'Furniture',
    'CHECKLIST.Form_Title_Graphics' : 'Graphics',
    'CHECKLIST.Form_Title_Electric' : 'Electric',
    'CHECKLIST.Form_Title_Team' : 'Team',
    'CHECKLIST.Form_Title_Documents' : 'Documents',
    'CHECKLIST.Form_Title_Organizer' : 'Organizer',
    'CHECKLIST.Form_Title_Subcontractors' : 'Subcontractors',
    'CHECKLIST.Form_Title_TeamOnSite' : 'mp team on site',
    'CHECKLIST.FieldGroup_Label_StartOfConstruction' : 'Start of construction',
    'CHECKLIST.FieldGroup_Label_OpeningTime' : 'Opening time (e.g 24 h)',
    'CHECKLIST.FieldGroup_Label_HandoverDate' : 'Handover date',
    'CHECKLIST.FieldGroup_Label_ExhibitionBegins' : 'Exhibition begins',
    'CHECKLIST.FieldGroup_Label_ExhibitionEnds' : 'Exhibition ends',
    'CHECKLIST.FieldGroup_Label_StartOfDeconstruction' : 'Start of deconstruction',
    'CHECKLIST.FieldGroup_Label_EndOfDeconstruction' : 'End of deconstruction',
    'CHECKLIST.FieldGroup_Label_EarlyConstruction' : 'Early construction',
    'CHECKLIST.FieldGroup_Label_AdditionalCostsForEarlyConstruction' : 'Additional costs for early construction',
    'CHECKLIST.FieldGroup_Label_UnloadingTrucks' : 'Unloading trucks',
    'CHECKLIST.FieldGroup_Label_ExtendedDeconstruction' : 'Extended deconstruction',
    'CHECKLIST.FieldGroup_Label_AdditionalCostsForExtendedDeconstruction' : 'Additional costs for extended deconstruction',
    'CHECKLIST.FieldGroup_Label_LoadingTrucksForReturnTransport' : 'Loading trucks for return transport',
    'CHECKLIST.FieldGroup_Label_DeadlineForChangesToStandConstruction' : 'Deadline for changes to stand construction',
    'CHECKLIST.FieldGroup_Label_DeadlineForSubmittingGraphicsDataForExhibitors' : 'Deadline for submitting graphics data for exhibitors',
    'CHECKLIST.FieldGroup_Label_DeadlineForSubmittingGraphicDataForSpecialAreas' : 'Deadline for submitting graphic data for special areas',
    'CHECKLIST.FieldGroup_Label_ReleasePicking' : 'Release picking',
    'CHECKLIST.FieldGroup_Label_PickingFromTo' : 'Picking (from/ to)',
    'CHECKLIST.FieldGroup_Label_CreateCarnetLists' : 'Create carnet lists (optional)',
    'CHECKLIST.FieldGroup_Label_LIHKAppointment' : 'LIHK appointment (carnet application) (optional)',
    'CHECKLIST.FieldGroup_Label_IHKCarnetExhibition' : 'IHK carnet exhibition (optional)',
    'CHECKLIST.FieldGroup_Label_CustomsTauchaAppointmentsWithTrucks' : 'Customs Taucha appointments with trucks (optional)',
    'CHECKLIST.FieldGroup_Label_Loading' : 'Loading',
    'CHECKLIST.FieldGroup_Label_ArrivalTruckExhibitionGrounds' : 'Arrival truck exhibition grounds',
    'CHECKLIST.FieldGroup_Label_UnloadingTruckExhibitionTrucks' : 'Unloading truck exhibition trucks',
    'CHECKLIST.FieldGroup_Label_CheckSupplierInvoicesInNavision' : 'Check supplier invoices in Navision',
    'CHECKLIST.FieldGroup_Label_Proceeds' : 'Proceeds',
    'CHECKLIST.FieldGroup_Label_PurchaseCosts' : 'Purchase costs',
    'CHECKLIST.FieldGroup_Label_DB1' : 'DB1',
    'CHECKLIST.FieldGroup_Label_Resources' : 'Resources',
    'CHECKLIST.FieldGroup_Label_DB2' : 'DB2',
    'CHECKLIST.FieldGroup_Label_TurnoverOrderBacklog' : 'Turnover/order backlog in EURO',
    'CHECKLIST.FieldGroup_Label_1stPaymentOnAccount' : '1st payment on account',
    'CHECKLIST.FieldGroup_Label_2stPaymentOnAccount' : '2st payment on account',
    'CHECKLIST.FieldGroup_Label_FinalInvoice' : 'Final invoice',
    'CHECKLIST.FieldGroup_Label_RecordLastMinuteInNavision' : 'Record last minute (in Navision)',
    'CHECKLIST.FieldGroup_Label_RecordDamages' : 'Record damages',
    'CHECKLIST.FieldGroup_Label_SettleAisleCarpet' : 'Settle aisle carpet',
    'CHECKLIST.FieldGroup_Label_OutwardAndReturnTransportationForStoredMaterialWhichWasDelivered' : 'Outward and return transportation for stored material which was delivered to the trade fair (poken stands, technology boxes etc.)',
    'CHECKLIST.FieldGroup_Label_Measurement' : 'Measurement',
    'CHECKLIST.FieldGroup_Label_ElectricalConnections' : 'Electrical connections',
    'CHECKLIST.FieldGroup_Label_Unloading' : 'Unloading',
    'CHECKLIST.FieldGroup_Label_CarpetLaying' : 'Carpet laying',
    'CHECKLIST.FieldGroup_Label_EmptiesHandling' : 'Empties handling',
    'CHECKLIST.FieldGroup_Label_EmptiesStorage' : 'Empties storage',
    'CHECKLIST.FieldGroup_Label_Assembly' : 'Assembly',
    'CHECKLIST.FieldGroup_Label_GraphicDesign' : 'Graphic design',
    'CHECKLIST.FieldGroup_Label_Furniture' : 'Furniture',
    'CHECKLIST.FieldGroup_Label_Organizer' : 'Organizer:',
    'CHECKLIST.FieldGroup_Label_PlaceOfTakingPlace' : 'Place of taking place:',
    'CHECKLIST.FieldGroup_Label_ProjectManager1' : 'Project manager 1',
    'CHECKLIST.FieldGroup_Label_ProjectManager2' : 'Project manager 2',
    'CHECKLIST.FieldGroup_Label_ConstructionManager1' : 'Construction manager 1',
    'CHECKLIST.FieldGroup_Label_ConstructionManager2' : 'Construction manager 2',
    'CHECKLIST.FieldGroup_Label_ForwarderOutwardAndReturnTransportation' : 'Forwarder (outward and return transportation)',
    'CHECKLIST.FieldGroup_Label_LogisticsCompanyOnSite' : 'Logistics company on site (unloading/loading/forklift)',
    'CHECKLIST.FieldGroup_Label_CarpetCompanyLaying' : 'Carpet company (laying)',
    'CHECKLIST.FieldGroup_Label_FurnitureSupplier1' : 'Furniture supplier 1',
    'CHECKLIST.FieldGroup_Label_FurnitureSupplier2' : 'Furniture supplier 2',
    'CHECKLIST.FieldGroup_Label_InstallationCompany1' : 'Installation company 1',
    'CHECKLIST.FieldGroup_Label_InstallationCompany2' : 'Installation company 2',
    'CHECKLIST.FieldGroup_Label_InstallationCompany3' : 'Installation company 3',
    'CHECKLIST.FieldGroup_Label_CleaningCompany' : 'Cleaning company',
    'CHECKLIST.FieldGroup_Label_TransportsViaForwardingAgency' : 'Transports via forwarding agency',
    'CHECKLIST.FieldGroup_Label_OrderTrucks' : 'Order trucks',
    'CHECKLIST.FieldGroup_Label_NumberOfTrucks' : 'Number of trucks:',
    'CHECKLIST.FieldGroup_Label_LoadingScheduleWarehouse' : 'Loading schedule warehouse',
    'CHECKLIST.FieldGroup_Label_UnloadingScheduleExhibitionGrounds' : 'Unloading schedule exhibition grounds',
    'CHECKLIST.FieldGroup_Label_LogisticalForUnloading' : 'Logistical for unloading?',
    'CHECKLIST.FieldGroup_Label_InformLogisticsAboutUnloadingAndSetupAndDismantlingTimes' : 'Inform logistics about unloading/and set-up and dismantling times',
    'CHECKLIST.FieldGroup_Label_WhereToStoreEmpties' : 'Where to store empties?',
    'CHECKLIST.FieldGroup_Label_LogisticEmptiesWho' : 'Logistic empties / who?',
    'CHECKLIST.FieldGroup_Label_CostsForEmpties' : 'Costs for empties?',
    'CHECKLIST.FieldGroup_Label_PokeStandLoading' : 'Poke stand loading',
    'CHECKLIST.FieldGroup_Label_DetermineTheNumberOfAssemblyWorkersForAssemblyAndDismantling' : 'Determine the number of assembly workers for assembly and dismantling',
    'CHECKLIST.FieldGroup_Label_DetermineTheNumberOfAssemblyStaffRequiredForService' : 'Determine the number of assembly staff required for service',
    'CHECKLIST.FieldGroup_Label_BookAssemblyStaffViaMario' : 'Book assembly staff (via Mario)',
    'CHECKLIST.FieldGroup_Label_InformAssemblyWorkersAboutTheSchedule' : 'Inform assembly workers about the schedule (start of assembly, etc.)',
    'CHECKLIST.FieldGroup_Label_EstimatedAssemblyCostsOrderMario' : 'Estimated assembly costs (order Mario)',
    'CHECKLIST.FieldGroup_Label_AssemblyCostsPercent' : 'Assembly costs %',
    'CHECKLIST.FieldGroup_Label_IdentifyCriticalMaterialQuantitiesAndCoordinateWithWarehouseConstructionManager' : 'Identify critical material quantities and coordinate with warehouse/construction manager (e.g. AAA tower side panels, garnet display cases, AAA built-in showcases, TVs, halopanels, sideboards, doors, etc.)',
    'CHECKLIST.FieldGroup_Label_PrepareAndActivateELFE' : 'Prepare and activate ELFE',
    'CHECKLIST.FieldGroup_Label_ArticleRequirementPreviewWithFurnitureListsAndMPEquipmentListsForConsistency' : 'Article requirement preview with furniture lists and mp equipment lists for consistency',
    'CHECKLIST.FieldGroup_Label_CoordinateSpecialDesignsWithTheManagers' : 'Coordinate special designs with the managers',
    'CHECKLIST.FieldGroup_Label_ReservesForAidsAndMaterialsDetermineStandConstruction' : 'Reserves for aids and materials determine stand construction (with site manager/Mario)',
    'CHECKLIST.FieldGroup_Label_CarpetSupplier' : 'Carpet supplier',
    'CHECKLIST.FieldGroup_Label_StandardColorCarpetExhibitionStands' : 'Standard color carpet exhibition stands',
    'CHECKLIST.FieldGroup_Label_AisleCarpetYesNo' : 'Aisle carpet yes/no',
    'CHECKLIST.FieldGroup_Label_StandardColorAisleCarpet' : 'Standard color aisle carpet',
    'CHECKLIST.FieldGroup_Label_ForecastCommunicateCarpetQuantitiesToSupplier' : 'Forecast communicate carpet quantities to supplier',
    'CHECKLIST.FieldGroup_Label_CarpetListWithHallPlanToSupplier' : 'Carpet list with hall plan to supplier',
    'CHECKLIST.FieldGroup_Label_PlanAndOrderCarpetReserve' : 'Plan and order carpet reserve',
    'CHECKLIST.FieldGroup_Label_CommunicateStartAndOfInstallationDate' : 'Communicate start and of installation date',
    'CHECKLIST.FieldGroup_Label_CommunicateEndOfInstallationDate' : 'Communicate start and of installation date',
    'CHECKLIST.FieldGroup_Label_CommunicateAisleLayingDate' : 'Communicate aisle laying date',
    'CHECKLIST.FieldGroup_Label_CommunicateDateForCarpetCollectionAfterDismantling' : 'Communicate date for carpet collection after dismantling',
    'CHECKLIST.FieldGroup_Label_FurnitureSuppliers' : 'Furniture supplier/s',
    'CHECKLIST.FieldGroup_Label_CommunicateForecastToSuppliersAsEarlyAsPossible' : 'Communicate forecast to suppliers as early as possible',
    'CHECKLIST.FieldGroup_Label_DiscussWithSuppliersWhichFurnitureIsInShortSupply' : 'Discuss with suppliers which furniture is in short supply',
    'CHECKLIST.FieldGroup_Label_FindAlternativeSuppliersIfNecessary' : 'Find alternative suppliers if necessary',
    'CHECKLIST.FieldGroup_Label_DetermineAndOrderReserves' : 'Determine and order reserves',
    'CHECKLIST.FieldGroup_Label_OrderFinalFurnitureQuantitiesUsingFurnitureList' : 'Order final furniture quantities using furniture list',
    'CHECKLIST.FieldGroup_Label_SendDistributionListAndCurrentHallPlanToSuppliers' : 'Send distribution list and current hall plan to suppliers',
    'CHECKLIST.FieldGroup_Label_CommunicateDeliveryAndDistributionStartDate' : 'Communicate delivery and distribution start date',
    'CHECKLIST.FieldGroup_Label_CommunicateFurnitureHandoverDate' : 'Communicate furniture handover date',
    'CHECKLIST.FieldGroup_Label_CommunicateServicePeriodReadinessForComplaints' : 'Communicate service period (readiness for complaints)',
    'CHECKLIST.FieldGroup_Label_CommunicateFurnitureDismantlingStartDate' : 'Communicate furniture dismantling start date',
    'CHECKLIST.FieldGroup_Label_CommunicateFurnitureDismantlingEndDate' : 'Communicate furniture dismantling end date',
    'CHECKLIST.FieldGroup_Label_GraphicsSupplier' : 'Graphics supplier',
    'CHECKLIST.FieldGroup_Label_PossiblySupplyMaterialPlatesForLamination' : 'Possibly supply material / plates for lamination (e.g. tower panels AAA Hamburg)',
    'CHECKLIST.FieldGroup_Label_PossiblySupplyCovers' : 'Possibly supply covers (e.g. for PackLoc trade fairs)',
    'CHECKLIST.FieldGroup_Label_CoordinateDeadlineForGraphicDataLayoutsProduction' : 'Coordinate deadline for graphic data/layouts/production',
    'CHECKLIST.FieldGroup_Label_CurrentHallPlanToSupplier' : 'Current hall plan to supplier',
    'CHECKLIST.FieldGroup_Label_CoordinateDateForOnSiteAssembly' : 'Coordinate date for on-site assembly',
    'CHECKLIST.FieldGroup_Label_CoordinateHandoverDate' : 'Coordinate handover date',
    'CHECKLIST.FieldGroup_Label_CoordinateServicePeriodReadinessForComplaints' : 'Coordinate service period (readiness for complaints)',
    'CHECKLIST.FieldGroup_Label_ElectricalPlanFromEasyfairs' : 'Electrical plan from easyfairs with general block connections and additional customer orders',
    'CHECKLIST.FieldGroup_Label_PlanArrivalDepartureHotel' : 'Plan arrival / departure',
    'CHECKLIST.FieldGroup_Label_PlanArrivalDeparture' : 'Plan arrival / departure',
    'CHECKLIST.FieldGroup_Label_HotelBookedWhichOneAddress' : 'Hotel booked - which one? Address?',
    'CHECKLIST.FieldGroup_Label_MeansOfTransportation' : 'Means of transportation (e.g. train / company car)',
    'CHECKLIST.FieldGroup_Label_HallPlans' : 'Hall plans',
    'CHECKLIST.FieldGroup_Label_CarpetExcelSheetLayingPlanWithColors' : 'Carpet Excel sheet laying plan with colors',
    'CHECKLIST.FieldGroup_Label_CurrentHallPlanWithPaintedCarpetColor' : 'Current hall plan with painted carpet color',
    'CHECKLIST.FieldGroup_Label_FinalFurnitureListPartList' : 'Final furniture list/part list',
    'CHECKLIST.FieldGroup_Label_DistributionListEquipment' : 'Distribution list equipment (TVs, mediawalls, shelves, winnies, counters, showcases, t-connection profiles, shelves, wardrobe rails for exhibits, wardrobe rails for clothing, pedestals, reading desk, etc.)',
    'CHECKLIST.FieldGroup_Label_PlansForLastMinuteChanges' : 'Plans for last minute changes',
    'CHECKLIST.FieldGroup_Label_PlansForSpecialConstructions' : 'Plans for special constructions',
    'CHECKLIST.FieldGroup_Label_NavisionArticleRequirementPreview' : 'Navision article requirement preview',
    'CHECKLIST.FieldGroup_Label_DeliveryBillsWithPackageNumbersFromStock' : 'Delivery bills with package numbers (from stock)',
    'CHECKLIST.FieldGroup_Label_ReserveListsOfStandConstructionMaterialsAids' : 'Reserve lists of stand construction materials / aids',
    'STAND.EquipmentOrder_CollapseLabel_default' : 'Default equipment list',
    'STAND.EquipmentOrder_CollapseLabel_additional' : 'Additional equipment list',
};

export default deutsch;