import React, { JSX } from "react";

import { TimePicker, TimePickerProps } from "antd";

import { ECheckListType } from "../check-list-types";
import CheckListHelpers from "../helpers";

import type { ICheckListItem, IFieldProps } from "../check-list-types";


const CheckListTimePickerField : React.FC<IFieldProps> = ({
        value,
        handleMutate,
        loading,
        placeholder = "—",
        multiField
    }) : JSX.Element => {

    const {
        date : time,
    } = CheckListHelpers.getDateData(value, ECheckListType.TIME);

    const onChange: TimePickerProps['onChange'] = (time, timeString) => {

        if(!loading && value !== timeString && handleMutate !== undefined){
            let listValue = timeString;

            if(multiField){
                listValue = JSON.stringify({
                    ...multiField.structure,
                    [multiField.current] : timeString,
                });
            }

            handleMutate<Pick<ICheckListItem, 'list_value'>>(
                { list_value : listValue }
            );
        }
    };

    return(
        <TimePicker
            defaultValue  = { time }
            placeholder   = { placeholder }
            format        = { "HH:mm" }
            allowClear    = { true }
            onChange      = { onChange }
            minuteStep    = { 15 }
        />

    );
};


export default CheckListTimePickerField;