import { IMessageItem } from "common/types";
import type { TStandComment } from "graphql/type/stand-gql-types";

const standCommentMapHelper = (comments : TStandComment[]) : IMessageItem[] => {

    return comments.map( (item): IMessageItem => {

        return {
            id : item.id,
            user : item.relUser,
            message : item.comment,
            created_at : item.created_at
        };
    });
};

export default standCommentMapHelper;