import React, { JSX } from 'react';

import { useQuery } from "@apollo/client";
import { Table } from 'antd';

import { GET_EXHIBITIONS } from "graphql/query/exhibition-q.gql";
 
import ExhibitionHelpers from "../helpers";
import { Loader } from "components/request-result";

import type { TFilterFields } from "common/types";


type TExhibitionsTableProps = {
    filters : TFilterFields
};

const ExhibitionsTable : React.FC<TExhibitionsTableProps> = ({ filters }) : JSX.Element  => {

    const { data : { exhibitionEventsCursor } = { },
        loading,
        // error
    } = useQuery( GET_EXHIBITIONS, {
        variables : {
            text : filters.text,
            //id: pParams.eeId
            orderBy : [ { column : "ID", order : "DESC" } ]
        },
        fetchPolicy : "cache-and-network"
    });

    const { edges : exhibitions = [] } = exhibitionEventsCursor || {};

    const { columns, data } = ExhibitionHelpers.table;

    return(
        <div className="exhibitions-table-wrap">
            { !!exhibitions.length &&
                <Table
                    className   = "exhibitions-table"
                    columns     = { columns! }
                    dataSource  = { data( exhibitions ) }
                    pagination  = { false }
                />
            }
            { loading && <Loader type={ "block" } /> }
        </div>
    );
};

export default ExhibitionsTable;