import ExhibitionHallPlanFile from "./Exhibition-hall-plan-file";
import ExhibitionHallSelect from "./Exhibition-hall-select";
import ExhibitionOrganizerSelect from "./Exhibition-organizer-select";
import ExhibitionTypeSelect from "./Exhibition-type-select";

import "./exhibition-fields.scss";


const ExhibitionFields = {
    TypeSelect : ExhibitionTypeSelect,
    OrganizerSelect : ExhibitionOrganizerSelect,
    HallPlanFile : ExhibitionHallPlanFile,
    HallSelect : ExhibitionHallSelect
};

export default ExhibitionFields;