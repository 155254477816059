import React, { FunctionComponent, JSX } from 'react';

import Icon from '@ant-design/icons';

import { IIcon } from "./icons-interfaces";


const CommentSvg : React.FC = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M36.6,5.81H11.6c-4.57,0-8.3,3.72-8.3,8.3v16.32c0,4.52,3.68,8.2,8.2,8.2h11.18l12.07,5.91c.28.14.58.2.88.2.37,
                 0,.74-.1,1.06-.3.59-.37.94-1.01.94-1.7v-4.17c4.08-.47,7.26-3.94,7.26-8.15V14.11c0-4.57-3.72-8.3-8.29-8.3ZM40.89,
                 30.43c0,2.32-1.88,4.2-4.2,4.2h-1.06c-1.1,0-2,.9-2,2v2.91l-9.6-4.7c-.27-.13-.58-.2-.88-.2h-11.64c-2.32,
                 0-4.2-1.89-4.2-4.2V14.11c0-2.37,1.93-4.3,4.3-4.3h25c2.37,0,4.29,1.93,4.29,4.3v16.32Z"
        />
        <path d="M17.17,19.66c-1.41,0-2.55,1.14-2.55,2.55s1.14,2.55,2.55,2.55,2.55-1.14,2.55-2.55-1.14-2.55-2.55-2.55Z"/>
        <circle cx="24.53" cy="22.21" r="2.55"/>
        <path d="M31.69,19.66c-1.41,0-2.55,1.14-2.55,2.55s1.14,2.55,2.55,2.55,2.55-1.14,2.55-2.55-1.14-2.55-2.55-2.55Z"/>
    </svg>
);

const CommentIcon: FunctionComponent<IIcon> = (props): JSX.Element => {

    return <Icon component={ CommentSvg } className={ props.className }/>;
};

export default CommentIcon;