import CheckListContactGroupBlock from "./Check-list-contact-group-block";
import CheckListDateRangeGroupBlock from "./Check-list-date-range-group-block";
import CheckListDateTimeGroupBlock from "./Check-list-date-time-group-block";
import CheckListDateTimeSingleBlock from "./Check-list-dateTime-single-block";
import CheckListMoneyDateGroupBlock from "./Check-list-money-date-group-block";
import CheckListMoneySingleBlock from "./Check-list-money-single-block";
import CheckListPlanningGroupBlock from "./Check-list-planning-group-block";
import CheckListTextDateGroupBlock from "./Check-list-text-date-group-block";
import CheckListTextSingleBlock from "./Check-list-text-single-block";


const CheckListFieldGroupBlock = {
    DateTime : CheckListDateTimeGroupBlock,
    MoneyDate : CheckListMoneyDateGroupBlock,
    Planning : CheckListPlanningGroupBlock,
    TextDate : CheckListTextDateGroupBlock,
    DateRange : CheckListDateRangeGroupBlock,
    Contact : CheckListContactGroupBlock,
    SingleDateTime : CheckListDateTimeSingleBlock,
    SingleText : CheckListTextSingleBlock,
    SingleMoney : CheckListMoneySingleBlock
};

export default CheckListFieldGroupBlock;