import React, { JSX } from "react";

import { generatePath, NavLink, useParams } from "react-router-dom";


type TMenuItem = {
    path  : string,
    label : JSX.Element | string
    disabled ?: boolean
    end      ?: boolean
};

type TMenuItems = {
    items : Array<TMenuItem>
};


const ThirdLvlMenu : React.FC<TMenuItems> = ({ items }) : JSX.Element => {

    const pParams = useParams();

    return (
        <nav className="third-lvl-menu-wrap">
            <ul className="third-lvl-menu">
                { items.map(
                    ({
                         path,
                         label,
                         end = false,
                         disabled = false
                     }, idx) => {

                        return(
                            <li key={ idx } >
                                <NavLink end       = { end! }
                                         to        = { generatePath(path,  pParams) }
                                         className = { disabled ? "link-disabled" : "" }
                                         onClick   = { (event) => disabled ? event.preventDefault() : "" }
                                >
                                    { label }
                                </NavLink>
                            </li>
                        );
                    }
                )}
            </ul>
        </nav>
    );
};

export default  React.memo(ThirdLvlMenu);