import deutsch from "./de";
import english from "./en";

import type { TTranslationsContainer } from "common/types";

 
const translation : TTranslationsContainer = {
    de : deutsch,
    en : english,
};

export default translation;