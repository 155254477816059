import CheckListParticipantsMPTeamForm from "./Check-list-participants-mp-team-form";
import CheckListParticipantsOrganizerForm from "./Check-list-participants-organizer-form";
import CheckListParticipantsSubcontractorsForm from "./Check-list-participants-subcontractors-form";


const CheckListParticipantsForm = {
    Organizer : CheckListParticipantsOrganizerForm,
    Subcontractors : CheckListParticipantsSubcontractorsForm,
    MPTeam : CheckListParticipantsMPTeamForm,
};


export default CheckListParticipantsForm;