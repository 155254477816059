import ExhibitionHallSubPage from "./Exhibition-hall-sub-page";
import ExhibitionHallsSubPage from "./Exhibition-halls-sub-page";
import ExhibitionInfoSubPage from "./Exhibition-info-sub-page";
import ExhibitionStatisticsSubPage from "./Exhibition-statistics-sub-page";
import ExhibitionTeamSubPage from "./Exhibition-team-sub-page";


const ExhibitionSub = {
    Details : ExhibitionInfoSubPage,
    Halls : ExhibitionHallsSubPage,
    Hall : ExhibitionHallSubPage,
    Team : ExhibitionTeamSubPage,
    Statistics : ExhibitionStatisticsSubPage
};

export default ExhibitionSub;