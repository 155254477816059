import React, { JSX } from 'react';

import { useMutation } from "@apollo/client";
import { Button, Form, Input } from 'antd';
import { generatePath, useNavigate } from "react-router-dom";

import {
    EE_HALL_CREATE,
    EE_HALL_DELETE,
    EE_HALL_UPDATE
} from "graphql/mutation/exhibition-m.gql";

import { Icons } from "components/layout";
import { ROUTES } from "components/routes";
import { DeleteButton } from "components/service";
import { useNotification } from "components/use-hook";


type TEEHallFormProps = {
    eeId      : number
    hall     ?: any
    isCreate  : boolean
};

const ExhibitionHallEditForm : React.FC<TEEHallFormProps> = ({ isCreate, hall, eeId }) : JSX.Element => {

    const navigate = useNavigate(),
          { nError, nSuccess } = useNotification();

    const [ hallEdit, { loading } ] = useMutation(
        isCreate ? EE_HALL_CREATE : EE_HALL_UPDATE,
        {
            update(cache, { data }) {

                const { [ isCreate ? "hallCreate" : "hallUpdate" ] : {
                    label,
                    message,
                    hall
                }  } = data;

                navigate(
                    generatePath(
                        isCreate ?
                            ROUTES.EXHIBITION_HALL_FULL :
                            ROUTES.EXHIBITION_HALLS_FULL,
                        {
                            hallId : Number(hall.id),
                            eeId : eeId
                        }
                    )
                );

                nSuccess(label, message);

            }
        }
    );

    const [ form ] = Form.useForm();

    return (
        <div className="exhibition-hall-edit-form ">
            <Form
                className     = { "inner-forms" }
                form          = { form }
                layout        = "vertical"
                initialValues ={
                    !isCreate ? {
                        ...hall
                    } : undefined
                }
                onFinish={ (values) => {

                    if( !loading ){
                        hallEdit({
                            variables : {
                                input : {
                                    id : !isCreate ? hall.id : null,
                                    ee_id : eeId,
                                    ...values
                                }
                            }
                        }).catch( nError );
                    }

                } }
            >
                <Form.Item
                    label={ "HALL NAME" }
                    name="title"
                    rules={ [
                        {
                            required : true,
                            message : "required"
                        }
                    ] }
                >
                    <Input name="hall-name" />
                </Form.Item>
                <Form.Item
                    label={ "HALL NUMBER" }
                    name="number"
                    rules={ [
                        {
                            required : true,
                            message : "required"
                        }
                    ] }
                >
                    <Input name="number" />
                </Form.Item>
                <div className="form-actions">
                    <div>
                        { !isCreate &&
                            <DeleteButton
                                className  = { "hall-delete" }
                                buttonType = { "default"  }
                                id         = { Number(hall.id) }
                                query      = { EE_HALL_DELETE }
                                route      = { "EXHIBITION_HALLS_FULL" }
                                routeParams = { { eeId, hallId : Number(hall.id) } }
                            >
                                Delete hall
                            </DeleteButton>
                        }
                    </div>
                    <div>
                        <Button
                            type="primary"
                            htmlType="submit"
                        >
                            <Icons.Edit loading={ loading } />
                            Save changes
                        </Button>
                    </div>
                </div>
            </Form>
        </div>
    );
};


export default React.memo(ExhibitionHallEditForm);