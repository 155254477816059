import StandCadVersionList from "./Stand-cad-version-list";
import StandTypeRadioList from "./Stand-type-radio-list";

import "./stand-list.scss";


const StandLists = {
    TypeRadio : StandTypeRadioList,
    CadVersion : StandCadVersionList
};

export default StandLists;