import React, { FunctionComponent, JSX } from 'react';

import Icon from '@ant-design/icons';

import { IIcon } from "./icons-interfaces";
import { Loader } from "components/request-result";


const SubstituteSvg : React.FC = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M30.3,37.7h-6.2c-7.3,0-13.2-5.9-13.2-13.2v-2.2l2.9,3.1c.4.4.9.6,1.4.6s1-.2,1.4-.6c.8-.8.8-2,0-2.8l-6.4-6.7c-.8-.8-2.1-.8-2.9,0l-6.4,6.7c-.8.8-.7,2.1,0,2.8.8.8,2.1.7,2.8,0l3-3.1v2.3c0,9.5,7.7,17.2,17.2,17.2h6.2c1.1,0,2-.9,2-2s-.9-2-2-2Z"/>
        <path d="M46.9,22.6c-.8-.8-2.1-.7-2.8,0l-3,3.1v-2.3c0-9.5-7.7-17.2-17.2-17.2h-6.2c-1.1,0-2,.9-2,2s.9,2,2,2h6.2c7.3,0,13.2,5.9,13.2,13.2v2.2l-2.9-3.1c-.8-.8-2-.8-2.8,0-.8.8-.8,2,0,2.8l6.4,6.7c.4.4.9.6,1.4.6s1.1-.2,1.4-.6l6.4-6.7c.8-.8.7-2.1,0-2.8Z"/>
    </svg>
);

const SubstituteIcon: FunctionComponent<IIcon> = (props): JSX.Element => {

    if (props.loading) {
        return <Loader/>;
    }

    return <Icon component={SubstituteSvg} className={props.className}/>;

};


export default SubstituteIcon;