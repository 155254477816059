import React, { JSX, useEffect } from "react";

import { Form, Select } from "antd";
import { NamePath } from "rc-field-form/es/interface";

import useOrganizerFragment from "../hooks/useOrganizerFragment";
import { Icons } from "components/layout";

import { EOrganizerOrETStatus } from "common/types";
import type { TID } from "graphql/type/common-gql-types";


const { Option } = Select;

type TExhibitionTypeProps = {
    organizerId       : TID,
    exhibitionTypeId ?: TID,
    id               ?: string
};

const ExhibitionTypeSelect : React.FC<TExhibitionTypeProps> = ({ organizerId, exhibitionTypeId, ...props  } ) : JSX.Element => {

    const form = Form.useFormInstance();

    const organizer = useOrganizerFragment( { orgId : organizerId });

    const {
        et_default_id : etDefault,
        exhibitionTypes
    } = organizer;

    useEffect(() => {
        if(typeof props.id === "string"){
            form.setFieldValue(
                props.id as NamePath,  exhibitionTypeId || etDefault
            );
        }

    }, [ organizer ]);


    return(
        <Select
            { ...props }
            defaultValue={ exhibitionTypeId || etDefault }
            suffixIcon    = { <Icons.Arrow /> }
        >
            { exhibitionTypes.map(
                ({ id, title, status } : { id: number, title: string, status: EOrganizerOrETStatus } ) => {

                    if(status === EOrganizerOrETStatus.CLOSED){
                        return null;
                    }

                    return(
                        <Option key={ id } value={ id } >{ title }</Option>
                    );
                }
            )
            }
        </Select>
    );
};

export default ExhibitionTypeSelect;