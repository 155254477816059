import CheckListCraftsAssemblyForm from "./Check-list-crafts-assembly-form";
import CheckListCraftsDocumentsForm from "./Check-list-crafts-documents-form";
import CheckListCraftsElectricForm from "./Check-list-crafts-electric-form";
import CheckListCraftsFlooringForm from "./Check-list-crafts-flooring-form";
import CheckListCraftsFurnitureForm from "./Check-list-crafts-furniture-form";
import CheckListCraftsGraphicForm from "./Check-list-crafts-graphic-form";
import CheckListCraftsLogisticForm from "./Check-list-crafts-logistic-form";
import CheckListCraftsStandConstructionForm from "./Check-list-crafts-stand-construction-form";
import CheckListCraftsTeamForm from "./Check-list-crafts-team-form";


const CheckListCraftsForm = {
    Team : CheckListCraftsTeamForm,
    Documents : CheckListCraftsDocumentsForm,
    Electric : CheckListCraftsElectricForm,
    Graphic : CheckListCraftsGraphicForm,
    Flooring : CheckListCraftsFlooringForm,
    Furniture : CheckListCraftsFurnitureForm,
    Assembly : CheckListCraftsAssemblyForm,
    StandConstruction : CheckListCraftsStandConstructionForm,
    Logistic : CheckListCraftsLogisticForm
};


export default CheckListCraftsForm;