import React, { JSX } from "react";


type TDOPProps = {
    children : (( props ?: any ) => JSX.Element) | JSX.Element
    data     : any
};

const DataOrPlaceholder: React.FC<TDOPProps> = ({ children, data }) : JSX.Element => {

    if(data){
        if(typeof children === "function"){
            return children(data);
        } else {
            return children;
        }

    }

    return <b>&mdash;</b>;
};

export default DataOrPlaceholder;