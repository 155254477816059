import StandCadInfoBlock from "./Stand-cad-info-block";
import StandCommentsBlock from "./Stand-comments-block";
import StandEquipmentCommentBlock from "./Stand-equipment-comment-block";
import StandEquipmentOrderBlock from "./Stand-equipment-order-block";
import StandPageTitle from "./Stand-page-title";
import StandTypeFilterBlock from "./Stand-type-filter-block";

import "./stand-blocks.scss";


const StandBlocks = {
    CadInfo : StandCadInfoBlock,
    Comments : StandCommentsBlock,
    EquipmentComment : StandEquipmentCommentBlock,
    PageTitle : StandPageTitle,
    TypeFilter : StandTypeFilterBlock,
    Order : StandEquipmentOrderBlock,
};

export default StandBlocks;