import StandTypeFileList from "./Stand-type-file-list";
import StandTypeOrientationsList from "./Stand-type-orientations-list";

import "./stand-type-list.scss";


const StandTypeLists = {
    Orientations : StandTypeOrientationsList,
    File : StandTypeFileList,
};

export default StandTypeLists;