import React from "react";

import { TEquipmentOrderItem, type TEquipmentOrderType } from "graphql/type/equipment-gql-types";

import { EQUIPMENT_ORDER_DELETE, EQUIPMENT_ORDER_UPDATE } from "graphql/mutation/equipment-m.gql";

import EquipmentModals from "../modals";
import { Menus } from "components/layout";
import { CountField, DeleteButton } from "components/service";

import { TLocales } from "common/types";
import type { TID, TNode } from "graphql/type/common-gql-types";


const columns  = [
    {
        title : "EQUIPMENT NAME",
        dataIndex : "title",
        columnIndex : "EQUIPMENT_NAME",
        width : "38%"
    },
    {
        title : "NAVISION №",
        dataIndex : "navisionNumber",
        columnIndex : "NAVISION_NUMBER",
        width : '200px'
    },
    {
        title : "PRICE P/P",
        dataIndex : "price",
        columnIndex : "PRICE",
        width : '120px'
    },
    {
        title : "DEFAULT",
        dataIndex : "defaultQuantity",
        columnIndex : "DEFAULT",
        width : '80px'
    },
    {
        title : "QUANTITY",
        dataIndex : "quantity",
        columnIndex : "QUANTITY",
        width : '150px'
    },
    {
        dataIndex : "action",
        width : "50px",
    }
];


const dataMap = (
    equipmentOrders : TNode<TEquipmentOrderItem>[],
    lang : TLocales,
    etId : TID,
    orgId : TID,
    type : TEquipmentOrderType
) => {

    return equipmentOrders.map( ( { node } ) => {

        const {
            id,
            set_type,
            stand_id,
            equipment_group,
            qty_default,
            qty,
            price,
            relEquipment,
            relEquipmentVariation
        } = node;

        const equipment  = relEquipment.find( ( item ) => item.lang === lang)!;
        const [ img ] = equipment.getImages;

        return{
            key : id,
            title :
                <div className="name">
                    <div className="img-wrap">
                        <img src={ img.sizes["200x200"] } alt={ equipment.title }/>
                    </div>
                    <div className="text">{ equipment.title }</div>
                </div>,
            navisionNumber : equipment.navision_number,
            price : `${ price } €`,
            defaultQuantity :
                <span className="default-qty">{ qty_default } </span>,
            quantity :
                <CountField
                    id       = { id }
                    qty      = { qty }
                    maxQty   = { set_type === 'default' ? qty_default : undefined }
                    mutation = { EQUIPMENT_ORDER_UPDATE }
                />,
            action :
                <Menus.Popover>
                    <EquipmentModals.InfoDrawer
                        variation = { relEquipmentVariation }
                        equipment = { equipment }
                        btnClass  = { '' }

                    />
                    <EquipmentModals.SelectDrawer
                        orderId          = { id }
                        standId          = { stand_id }
                        etId             = { etId }
                        orgId            = { orgId }
                        equipmentGroupId = { equipment_group }
                        isSubstitute     = { true }
                        btnClass         = { '' }
                        type             = { type }
                    />
                    { set_type !== 'default' &&
                        <DeleteButton
                            className  = { "equipment-delete" }
                            buttonType = { "text" }
                            id         = { Number(id) }
                            query      = { EQUIPMENT_ORDER_DELETE }
                            reFetch    = {[ "GetStandEquipmentOrders" ]}
                            confirmText= { `Do you want to delete ${ equipment.title } equipment?` }
                        >
                            Delete
                        </DeleteButton>
                    }
                </Menus.Popover>
        };
    });
};


const EquipmentOrderTableHelper = { columns, dataMap };

export default EquipmentOrderTableHelper;