export const standOrientations = [ {
    "node" : {
        "id" : "143",
        "group_name" : "stand orientation",
        "slug" : "stand_orientation_peninsula",
        "value" : "Kopf",
        "img_path" : "https://api.mystand-project.de/storage/variable-lists/139/upload/kopf.svg"
    }
},
    {
        "node" : {
            "id" : "145",
            "group_name" : "stand orientation",
            "slug" : "stand_orientation_row",
            "value" : "Reihe",
            "img_path" : "https://api.mystand-project.de/storage/variable-lists/141/upload/reihe.svg"
        }
    },
    {
        "node" : {
            "id" : "146",
            "group_name" : "stand orientation",
            "slug" : "stand_orientation_island",
            "value" : "Insel",
            "img_path" : "https://api.mystand-project.de/storage/variable-lists/142/upload/block.svg"
        }
    },
    {
        "node" : {
            "id" : "345",
            "group_name" : "stand orientation",
            "slug" : "stand_orientation_corner_left",
            "value" : "Ecke links",
            "img_path" : "https://api.mystand-project.de/storage/variable-lists/344/upload/ecke.svg"
        }
    },
    {
        "node" : {
            "id" : "347",
            "group_name" : "stand orientation",
            "slug" : "stand_orientation_corner_right",
            "value" : "Ecke rechts",
            "img_path" : "https://api.mystand-project.de/storage/variable-lists/346/upload/ecke.svg"
        }
    } ];