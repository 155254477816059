import React, { JSX }  from "react";


const StandGraphicsSubPage : React.FC = () : JSX.Element => {

    return(
        <div className="page-content">
            STAND GRAPHICS
        </div>
    );
};

export default StandGraphicsSubPage;