import React, { JSX } from "react";

import StandTypeLists from "../lists";
import Localize from "components/service/Localize";

import type { TStandType } from "../stand-type-types";


interface IStandTypeDescriptionProps {
    standType : TStandType
}

const StandTypeDescriptionBlock : React.FC<IStandTypeDescriptionProps> = ({ standType }) : JSX.Element => {

    const { meta_fields, orientations, complexity } = standType;

    return (
        <div className="stand-type-block-description">
            <h3>{ standType.title }</h3>
            <dl>
                <dt className="label">Navision</dt>
                <dd className="navision-number">
                    {standType.navision_number}
                </dd>
                <dt className="label">Possible orientations</dt>
                <dd className="orientations">
                    <StandTypeLists.Orientations
                        orientations = { orientations }
                    />
                </dd>
                { meta_fields.map(
                    ({ id, meta_value, meta_key }) => {
                        return(
                            <React.Fragment key = { id } >
                                <Localize wrap tag="dt" className="label">
                                    {`VARIABLE_ITEM.${meta_key}`}
                                </Localize>
                                <dd>{ meta_value }</dd>
                            </React.Fragment>
                        );
                    }
                )}
                <dt className="label">Complexity level</dt>
                <dd className="complexity">
                    { complexity }
                </dd>
            </dl>
        </div>
    );
};

export default StandTypeDescriptionBlock;