import { ICheckListFieldStatistic, TCheckListGroups, TCheckListSectionNames } from "../check-list-types";

const getFieldStatistic = (structuredCheckList: TCheckListGroups | undefined, name : TCheckListSectionNames) : Partial<ICheckListFieldStatistic> => {

    if(structuredCheckList && structuredCheckList[name]){
        const { fieldStatistic } = structuredCheckList[name];

        return {
            ...fieldStatistic,
            unFinished : fieldStatistic.total - fieldStatistic.finished
        };
    }

    return {
        finished : undefined,
        total : undefined,
        unFinished : undefined
    };
};

export default getFieldStatistic;