import React, { FunctionComponent, JSX } from 'react';

import Icon from '@ant-design/icons';

import { IIcon } from "./icons-interfaces";


const ProfileSvg : React.FC = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <circle cx="24.1" cy="15.4" r="10.2"/>
        <path style={ { opacity : 0.5 } } d="M44.6,44.6c-0.3-12.6-7.1-16.7-20-16.7c-0.1,0-0.2,0-0.4,0c-0.1,0-0.2,0-0.4,0C11,27.9,4.2,32,3.9,44.6H44.6z"/>
        <path d="M19.3,28.2c0,0,3.2-0.5,9.7-0.1l-4.7,10.7L19.3,28.2z"/>
    </svg>
);

const PersonSvg : React.FC = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path
            d="M28.8,22c2.9-1.7,4.9-4.8,4.9-8.5,0-5.4-4.4-9.8-9.7-9.8s-9.7,4.4-9.7,9.8,2,6.8,4.9,8.5c-8,2.3-13.9,10-14,19.3,0,1.1.9,2,2,2h0c1.1,0,2-.9,2-2,0-8.8,6.7-16,14.8-16s14.7,7.2,14.8,16c0,1.1.9,2,2,2h0c1.1,0,2-.9,2-2,0-9.2-6-17-14-19.3ZM24,7.8c3.1,0,5.7,2.6,5.7,5.8s-2.6,5.8-5.7,5.8-5.7-2.6-5.7-5.8,2.6-5.8,5.7-5.8Z"/>
    </svg>
);


const ProfileIcon: FunctionComponent<IIcon> = ({ className, type }): JSX.Element => {

    if (type === 'simple') {
        return <Icon component={PersonSvg} className={className}/>;
    }

    return <Icon component={ProfileSvg} className={className}/>;

};


export default ProfileIcon;