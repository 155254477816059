import React, { JSX } from "react";

import { generatePath, useNavigate } from "react-router-dom";

import { TASK_DELETE } from "graphql/mutation/task-m.gql";

import { ROUTES } from "../../routes";
import TaskModals from "../modals";
import { Menus } from "components/layout";
import { DeleteButton } from "components/service";

import type { TID } from "graphql/type/common-gql-types";
import type { TTask } from "graphql/type/task-gql-types";


interface ITaskMenuItemProps {
    standId : TID
    teamId : TID
    eeId   : TID
    task : TTask
}

const TaskItemMenu : React.FC<ITaskMenuItemProps> = (
    {
        task,
        standId,
        teamId,
        eeId
    }) : JSX.Element => {

    const navigate = useNavigate();

    return(
        <Menus.Popover>
            <TaskModals.Edit
                standId = { standId }
                teamId  = { teamId }
                task    = { task }
            />
            <DeleteButton
                className  = { "task-delete" }
                buttonType = { "text" }
                id         = { Number(task.id) }
                query      = { TASK_DELETE }
                reFetch    = {[ 'GetTasks' ]}
                onSuccess  = { () => {
                    navigate(
                        generatePath(
                            ROUTES.STAND_ITEM_TASKS_FULL,
                            {
                                eeId : Number(eeId),
                                standId : Number(standId)
                            }
                        )
                    );
                }}
                confirmText= { `Do you want to delete task "${ task.title }"?` }
            >
                Delete
            </DeleteButton>
        </Menus.Popover>
    );
};

export default TaskItemMenu;