import React, { JSX } from "react";

import { Link } from "react-router-dom";

import { Icons, Menus } from "components/layout";
import { ROUTES } from "components/routes";


const HeaderBlock : React.FC = () : JSX.Element => {

    return(
        <header>
            <div className="logo">
                <Link to={ ROUTES.HOME } >
                    <Icons.Logo type="full" />
                </Link>
            </div>
            <div className="container">
                <Menus.Main />
                <Menus.User />
            </div>
        </header>
    );
};

export default HeaderBlock;