import React, { JSX } from "react";

import { generatePath, Link }  from "react-router-dom";

import { Icons } from "components/layout";
import { ROUTES } from "components/routes";

import { TRouteParams } from "common/types";


type TLinkIconProps = {
    route        : keyof typeof ROUTES
    children     : JSX.Element | string
    icon        ?: keyof typeof Icons | undefined
    routeParams ?: TRouteParams
    className   ?: string | undefined
};


const LinkWithIcon : React.FC<TLinkIconProps> = ({
        route,
        children,
        icon = "Plus",
        routeParams,
        className = "link-button"
    }) : JSX.Element => {

    const LinkIcon = Icons[icon];

    return (
        <Link
            to={ routeParams ? generatePath(ROUTES[route], routeParams ) : ROUTES[route] }
            className={ className }
        >
            <LinkIcon />
            { children }
        </Link>
    );
};

export default LinkWithIcon;