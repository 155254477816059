import React from "react";

import { useQuery } from "@apollo/client";
import { Radio } from "antd";

import { GET_STAND_TYPES } from "graphql/query/stand-type-q.gql";

import { reactiveVarHelper } from "common/helpers";
import { whereConditionBuilder } from "common/utils";
import { Loader } from "components/request-result";

import type { IStandTypeSelect } from "../stand-types";


export type TStandTypeListProps = {
    eeId          ?: number | undefined
    etId           : number | undefined
    orgId          : number | undefined
    setStandType   : (props : IStandTypeSelect) => void
    standType     ?: IStandTypeSelect | undefined
    text          ?: string | undefined
};

const StandTypeRadioList : React.FC<TStandTypeListProps> = ({
        etId,
        orgId,
        setStandType,
        standType,
        text
    }) => {

    const rVar = reactiveVarHelper(),
        { me } = rVar.get('all');

    const { data, loading } = useQuery( GET_STAND_TYPES, {
        variables : {
            text : text && text.length ? text : undefined,
            where : whereConditionBuilder({
                lang : {
                    column : "LANG",
                    operator : "EQ",
                    value : me.lang_api
                },
            }),
            whereExhibitionTypeStandTypes : whereConditionBuilder({
                exhibitionTypeId : !etId ? {} : {
                    column : "ET_ID",
                    operator : "EQ",
                    value : etId
                },
                organizerId : !orgId ? {} : {
                    column : "ORGANIZER_ID",
                    operator : "EQ",
                    value : orgId
                },
            }),
            orderBy : [ { column : "TITLE", order : "ASC" } ]
        },
        fetchPolicy : "cache-first",
    });

    const {
        standTypesCursor : {
            edges : standTypes = []
        } = {}
    } = data || {};

    return(
        <div className={ `radio-select-wrap ${ loading ? "loading" : "" }`} >
            { loading ?
                <Loader /> :
                <Radio.Group
                    onChange={ (e) => {

                        const { node } = standTypes.find(
                            ({ node } : { node : IStandTypeSelect }) => Number(node.group_id) === Number(e.target.value)
                        );

                        setStandType({ ...node });
                    } }
                    value={ standType ?  standType.group_id : undefined }
                >
                    { standTypes.map( (
                        { node : { id, title, navision_number, group_id, getImages } } : { node : IStandTypeSelect }
                    ) => {

                        const [ img ] = getImages;

                        return(
                            <Radio key={ id } value={ group_id } >
                                <div className="img-wrap">
                                    <img src={ img.sizes['200x200'] } alt={ img.server_name } />
                                </div>
                                <div className="info">
                                    <span>{ title }</span>
                                    <span className="label">{ navision_number} </span>
                                </div>
                                <div className="action"></div>
                            </Radio>
                        );
                    } )}

                </Radio.Group>
            }
        </div>
    );
};

export default StandTypeRadioList;