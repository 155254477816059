import React, { JSX, useEffect } from 'react';

import { FetchPolicy, useQuery } from "@apollo/client";

import { GET_PRIVATE_PARAMS } from "graphql/query/local-store.gql";

import { reactiveVarHelper } from "common/helpers";
import { Blocks } from "components/layout";
import { AppRoutes } from "components/routes";
import { useMe, useTranslation, useVariableLists } from "components/use-hook";

import './app.scss';

export let translations = useTranslation();

const PrivateApp : React.FC = () : JSX.Element | null =>  {

    const { loading, me/*, error */ } = useMe("cache-and-network" as FetchPolicy, true),
          { variableLists, loading : VRLoading } =  useVariableLists("no-cache", true, me && me.lang_app),
          { data : { privateParams } } = useQuery( GET_PRIVATE_PARAMS );

    useEffect( () => {
        if( me ){
            reactiveVarHelper().set({
                defaultLanguage : me.lang_app,
                "me" : me,
                'variableLists' : variableLists
            });

            translations = useTranslation();
        }
    }, [ me, variableLists ]);

    if(loading || VRLoading){
        return null;
    }

    return (
        <div className={ `app ${ privateParams.appBlockClass.join(" ") }` }>

            <Blocks.Header />
            <main className={ `${ privateParams.mainBlockClass.join(" ") }` }>
                <AppRoutes />
            </main>

            {/*<Suspense type="block" state={ { loading, error } }>*/}

            {/*    <main key="main-body">*/}
            {/*        PRIVATE*/}
            {/*    </main>*/}

            {/*</Suspense>*/}
        </div>
    );
};

export default PrivateApp;
