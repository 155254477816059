import React, { JSX }  from "react";

import { useOutletContext } from "react-router-dom";

import { Blocks } from "components/layout";


const GraphicListPage : React.FC = () : JSX.Element => {

    const { ee } = useOutletContext<{ee : any}>();

    return(
        <div className="page check-list-page">
            <Blocks.PageHeader
                title={ `${ ee.title } graphic list`}
            />
            <div className="page-content-wrap">
                GRAPHIC LIST
            </div>
        </div>
    );
};

export default GraphicListPage;