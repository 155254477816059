import { gql, TypedDocumentNode } from "@apollo/client";

import { TEquipmentOrderPayload, TEquipmentOrderType } from "../type/equipment-gql-types";

import type { TID, TPayload } from "../type/common-gql-types";


export const EQUIPMENT_ORDER_CREATE : TypedDocumentNode<
    { standEquipmentOrderCreate : TEquipmentOrderPayload },
    {
        input : {
            stand_id         : TID
            equipment_group  : TID
            set_type         : TEquipmentOrderType
        }
    }
> = gql`
    mutation standEquipmentOrderCreate($input: StandEquipmentOrderFieldsInput!) {
        standEquipmentOrderCreate(input: $input){
            label
            message
            standEquipmentOrder {
                id
                ee_id
                stand_id
                equipment_group
                set_type
                qty_default
                qty
                price
                equipment_variation_id
                relEquipment{
                    id
                    group_id
                    title
                    lang
                    type
                    navision_number
                    octacad_number
                    color
                    supplier_article
                    supplier_id
                    description
                    getImages {
                        id
                        title
                        server_name
                        sizes
                    }
                    updated_at
                    created_at
                }
                relEquipmentVariation{
                    id
                    equipment_group
                    title
                    organizer_id
                    article
                    price
                }
            }
        }
    }
`;

export const EQUIPMENT_ORDER_UPDATE : TypedDocumentNode<
        { standEquipmentOrderUpdate : TEquipmentOrderPayload },
        {
            input : {
                id : TID
                qty : number
            }
        }
    > = gql`
    mutation standEquipmentOrderUpdate($input: StandEquipmentOrderFieldsInput!) {
        standEquipmentOrderUpdate(input: $input){
            label
            message
            standEquipmentOrder {
                id
                ee_id
                stand_id
                equipment_group
                set_type
                qty_default
                qty
                price
                equipment_variation_id
                relEquipment{
                    id
                    group_id
                    title
                    lang
                    type
                    navision_number
                    octacad_number
                    color
                    supplier_article
                    supplier_id
                    description
                    getImages {
                        id
                        title
                        server_name
                        sizes
                    }
                    updated_at
                    created_at
                }
                relEquipmentVariation{
                    id
                    equipment_group
                    title
                    organizer_id
                    article
                    price
                }
            }
        }
    }
`;

export const EQUIPMENT_ORDER_SUBSTITUTE: TypedDocumentNode<
        { standEquipmentOrderChangeSubstitute : TEquipmentOrderPayload },
        { orderId : TID, equipmentGroupId : TID }
    > = gql`
    mutation StandEquipmentOrderChangeSubstitute( 
        $orderId: ID! 
        $equipmentGroupId: ID! 
    ) {
        standEquipmentOrderChangeSubstitute(
            order_id: $orderId
            equipment_group: $equipmentGroupId
        ){
            label
            message
            standEquipmentOrder {
                id
                ee_id
                stand_id
                equipment_group
                set_type
                qty_default
                qty
                price
                equipment_variation_id
                relEquipment{
                    id
                    group_id
                    title
                    lang
                    type
                    navision_number
                    octacad_number
                    color
                    supplier_article
                    supplier_id
                    description
                    getImages {
                        id
                        title
                        server_name
                        sizes
                    }
                    updated_at
                    created_at
                }
                relEquipmentVariation{
                    id
                    equipment_group
                    title
                    organizer_id
                    article
                    price
                }
            }
        }
    }
`;

export const EQUIPMENT_ORDER_DELETE: TypedDocumentNode<
        { standEquipmentOrderDelete : TPayload },
        { id: TID }
    > = gql`
    mutation standEquipmentOrderDelete( $id: ID! ) {
        standEquipmentOrderDelete( id: $id ){
            label
            message
            
        }
    }
`;