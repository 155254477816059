import { gql } from '@apollo/client';


export const EXHIBITION_CREATE = gql`
    mutation ExhibitionCreate($input: EeFieldsInput!) {
        eeCreate(input: $input){
            label
            message
            exhibitionEvent {
                id
                title
                status
                aquise_number
                construction_start_at
                construction_end_at
                running_start_at
                running_end_at
                deconstruction_start_at
                deconstruction_end_at
                organizer_id
                organizer {
                    id
                    title
                    status
                    et_default_id
                    exhibitionTypes {
                        id
                        title
                        status
                        organizer_id
                    }
                }
                exhibition_type_id
                team_id
                team {
                    id
                    title
                }
                project_manager_id
                projectManager{
                    id
                    name
                    surname
                }
                construction_manager_id
                constructionManager{
                    id
                    name
                    surname
                }
            }
        }
    }
`;

export const EXHIBITION_UPDATE = gql`
    mutation ExhibitionUpdate($input: EeFieldsInput!) {
        eeUpdate(input: $input){
            label
            message
            exhibitionEvent {
                id
                title
                status
                aquise_number
                construction_start_at
                construction_end_at
                running_start_at
                running_end_at
                deconstruction_start_at
                deconstruction_end_at
                organizer_id
                organizer {
                    id
                    title
                    status
                    et_default_id
                    exhibitionTypes {
                        id
                        title
                        status
                        organizer_id
                    }
                }
                exhibition_type_id
                team_id
                team {
                    id
                    title
                }
                project_manager_id
                projectManager{
                    id
                    name
                    surname
                }
                construction_manager_id
                constructionManager{
                    id
                    name
                    surname
                }
            }
        }
    }
`;

export const EXHIBITION_DELETE = gql`
    mutation eeDelete($id: ID!) {
        eeDelete(id: $id){
            label
            message
        }
    }
`;

export const EE_HALL_CREATE = gql`
    mutation EEHallCreate($input: HallFieldsInput!) {
        hallCreate(input: $input){
            label
            message
            hall {
                id
                title
                ee_id
                number
                stands_count
                hall_plan
                hall_plan_img
            }
        }
    }
`;

export const EE_HALL_UPDATE = gql`
    mutation EEHallUpdate($input: HallFieldsInput!) {
        hallUpdate(input: $input){
            label
            message
            hall {
                id
                title
                ee_id
                number
                stands_count
                hall_plan
                hall_plan_img
            }
        }
    }
`;

export const EE_HALL_DELETE = gql`
    mutation hallDelete($id: ID!) {
        hallDelete(id: $id){
            label
            message
        }
    }
`;

export const EE_HALL_PLAN_UPLOAD = gql`
    mutation fileUploadModel($input: FileUploadInput!) {
        fileUploadModel(input: $input){
            label
            message
            model {
                ... on Hall{
                    id
                    title
                    hall_plan
                    hall_plan_img
                }
            }
        }
    }
`;