import { useEffect, useRef } from "react";

type Timer = ReturnType<typeof window.setTimeout>;
type SomeFunction = (...args: any[]) => void;


const useDebounce = <Func extends SomeFunction>(
        func: Func,
        delay = 1000
    ) => {

    const timer = useRef<Timer | number>();

    useEffect(() => {
        return () => {
            if (!timer.current) return;
            clearTimeout(timer.current);
        };
    }, []);

    return ((...args) => {

        const newTimer = window.setTimeout(() => {
            func(...args);
        }, delay);

        clearTimeout(timer.current);
        timer.current = newTimer;
    }) as Func;
};


export default useDebounce;