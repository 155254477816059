import { gql, TypedDocumentNode  } from '@apollo/client';

import { TUserData } from "../type/user-gql-types";


export const GET_ME : TypedDocumentNode< TUserData, never > = gql`
    query GetMe {
        me{
            id
            name
            surname
            email
            phone
            status
            lang_api
            lang_app
            job_type
            is_admin
            is_online
            roles {
                id
                title
            }
            permissions {
                id
                slug
            }
            supplier {
                id
                title
                type
                number
            }
            teams {
                id
                title
            }
            last_visit_at
            created_at
            updated_at
        }
    }
`;

export const GET_TEAM_USERS = gql`
    query GetUsers (
        $text             : String
        $whereTeamsUser   : QueryUsersCursorWhereTeamsUserWhereHasConditions
        $orderBy          : [QueryUsersCursorOrderByOrderByClause!]
        $after            : String
    ){
        usersCursor(
            text             : $text
            whereTeamsUser   : $whereTeamsUser
            orderBy          : $orderBy
            first            : 50
            after            : $after
        ) {
            pageInfo {
                hasNextPage
                startCursor
                endCursor
                total
                currentPage
            }
            edges {
                node{
                    id
                    name
                    surname
                    status
                }
            }
        }
    }
`;
