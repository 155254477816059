import React, { JSX } from "react";

import StandForms from "../forms";
import StandLists from "../lists";
import { Blocks } from "components/layout";

import type { TID } from "graphql/type/common-gql-types";

interface IStandCadInfoBlockProps {
    standId : TID
}

const StandCadInfoBlock : React.FC<IStandCadInfoBlockProps> = ({ standId }) : JSX.Element => {
    
    return(
        <Blocks.Card
            Header    = { "Cad Info" }
            className = { "stand-cad-info-block" }
        >
            <StandForms.CadInfo standId = { standId } />

            <div className="cad-versions-wrap">
                <h5 className="label">Changes</h5>
                <StandLists.CadVersion standId = { standId } />
            </div>
        </Blocks.Card>
    );
};

export default StandCadInfoBlock;