import React, { FunctionComponent, JSX } from 'react';

import Icon from '@ant-design/icons';

import { IIcon } from "./icons-interfaces";


interface IIconLogo extends IIcon{
    type ?: "full"
}

type TLogoProps = {
    iconFill : object,
    className ?: string | undefined
};

const mainColor = { fill : "#FFFFFF" };


const LogoJustSign : React.FC<TLogoProps> = ({ iconFill, className } ) => {


    const Svg : React.FC = () => (
        <svg fill="currentColor"  width="1.08em" height="1em" viewBox="0 0 54 50" strokeWidth="1" >
            <path d="M54,50H0V0h44.3C49.5,0,54,4.3,54,9.6V50z"/>
            <g>
                <polygon style={ { ...iconFill,  opacity : 0.5 } } points="25.1,26 10.3,33.3 10.3,16.2 25.1,8.9"/>
                <polygon style={ { ...iconFill, opacity : 0.5 } } points="27.7,26 42.5,33.3 42.5,16.2 27.7,8.9"/>
                <polygon  points="11.6,35.5 26.4,42.8 41.2,35.5 26.4,28.2"/>
            </g>
        </svg>
    );

    return <Icon component={ Svg } className={ className } />;
};

const LogoFull : React.FC<TLogoProps> = ({ iconFill, className } ) => {

    const Svg : React.FC = () => (
        <svg fill="currentColor"  width="4.88em" height="1em" viewBox="0 0 244 50" strokeWidth="1" >
            <path className="icon-bg" d="M54,50H0V0h44.3C49.6,0,54,4.3,54,9.6V50z"/>
            <g>
                <g>
                    <path style={ iconFill } d="M63,19.6h3.1v0.7c0.6-0.3,1-0.6,1.5-0.7c0.4-0.2,0.8-0.2,1.3-0.2c0.6,0,1,0.1,1.6,0.3
			c0.5,0.2,0.9,0.5,1.2,0.8c0.8-0.4,1.4-0.7,2.1-0.8s1.3-0.3,2-0.3c1.4,0,2.4,0.5,3,1.3c0.6,0.8,0.9,2.3,0.9,4.2v6.2h-3.1v-6.1
			c0-1-0.1-1.8-0.4-2.3c-0.2-0.5-0.7-0.7-1.3-0.7c-0.5,0-1,0.1-1.8,0.3l-0.4,0.1c0.1,1,0.1,1.9,0.1,2.6v5.9h-3.1v-5.9
			c0-0.8,0-1.5-0.1-1.9c-0.1-0.5-0.3-0.8-0.5-0.9c-0.3-0.2-0.6-0.3-1-0.3c-0.5,0-1,0.1-1.7,0.3l-0.3,0.1v8.6h-3.1L63,19.6L63,19.6z"
                    />
                    <path style={ iconFill } d="M86.1,31.1H84l-2.8-11.5h3l2.1,8.9h0.6l2.1-8.9h3l-4,16.4h-3L86.1,31.1z"/>
                    <path style={ iconFill } d="M94.1,30.8l-0.8-0.2l0.3-2.4c2.1,0.3,3.7,0.4,4.7,0.4c1.6,0,2.4-0.7,2.4-2.1c0-0.6-0.2-0.9-0.7-1.2
			c-0.5-0.3-1.1-0.6-2.2-0.8c-1.2-0.4-2.2-0.8-2.8-1.1c-0.7-0.4-1.2-0.9-1.5-1.5c-0.3-0.6-0.5-1.3-0.5-2.2c0-1.5,0.5-2.7,1.4-3.5
			c0.9-0.8,2.3-1.1,3.9-1.1c0.7,0,1.3,0,2.1,0.1c0.8,0.1,1.8,0.3,3.1,0.5l-0.3,2.5c-2.1-0.3-3.7-0.4-4.6-0.4c-1.7,0-2.5,0.6-2.5,1.7
			c0,0.4,0.1,0.7,0.4,0.9c0.3,0.3,0.7,0.5,1,0.6c0.4,0.2,1.1,0.4,2.1,0.7c1,0.4,1.9,0.7,2.4,1c0.6,0.4,1,0.8,1.2,1.4
			c0.3,0.6,0.4,1.2,0.4,2.1c0,1.6-0.5,2.8-1.5,3.7c-0.9,0.8-2.3,1.3-3.9,1.3C97.3,31.4,95.8,31.2,94.1,30.8z"/>
                    <path style={ iconFill } d="M108,31c-0.5-0.3-0.8-0.8-1-1.3c-0.2-0.7-0.3-1.5-0.3-2.6v-4.8h-1.3v-2.6h1.3v-3.2h3.1v3.2h2.8v2.6h-2.8V27
			c0,0.6,0.1,1,0.2,1.3c0.1,0.3,0.4,0.4,0.8,0.4h1.8l0.1,2.4c-0.7,0.1-1.1,0.2-1.4,0.3c-0.3,0.1-0.7,0.1-1,0.1
			C109.1,31.4,108.4,31.2,108,31z"/>
                    <path style={ iconFill } d="M113.7,27.6c0-1.2,0.3-2.1,0.9-2.6c0.7-0.6,1.7-0.8,3.1-0.9l2.4-0.2v-0.7c0-0.5-0.1-0.8-0.3-0.9
			c-0.2-0.2-0.6-0.3-1-0.3c-0.5,0-1.2,0-2.1,0.1c-0.8,0-1.6,0.1-2.3,0.1l-0.1-2.2c1.7-0.5,3.2-0.7,4.7-0.7s2.5,0.3,3.2,0.9
			c0.7,0.7,0.9,1.6,0.9,2.9V28c0,0.4,0.1,0.7,0.2,0.8c0.1,0.1,0.3,0.3,0.6,0.3l-0.1,2.3c-0.8,0-1.4-0.1-1.9-0.2
			c-0.5-0.1-0.9-0.3-1.4-0.7c-1.1,0.6-2.3,0.8-3.4,0.8C114.9,31.4,113.7,30.2,113.7,27.6z M119.9,28.6l0.4-0.1V26l-2.1,0.2
			c-0.8,0.1-1.3,0.6-1.3,1.4s0.4,1.2,1.1,1.2C118.6,28.8,119.1,28.8,119.9,28.6z"/>
                    <path style={ iconFill } d="M126.3,19.6h3.1v0.7c0.6-0.4,1.1-0.6,1.6-0.8c0.5-0.1,0.9-0.2,1.5-0.2c1.4,0,2.4,0.5,3,1.3
			c0.6,0.9,0.9,2.3,0.9,4.2v6.2h-3.3V25c0-1-0.1-1.8-0.4-2.3c-0.3-0.5-0.8-0.7-1.4-0.7c-0.5,0-1.1,0.1-1.7,0.3l-0.3,0.1v8.6h-3.1
			V19.6z"/>
                    <path style={ iconFill } d="M139.4,29.9c-0.7-0.9-1-2.4-1-4.4c0-2.2,0.4-3.8,1.1-4.7c0.8-1,2-1.5,3.5-1.5c0.6,0,1.4,0.1,2.6,0.3v-4.6h3.1
			V31h-3.1v-0.5c-0.7,0.3-1.1,0.5-1.6,0.6c-0.5,0.1-0.8,0.2-1.3,0.2C141.2,31.4,140.1,30.9,139.4,29.9z M145.4,28.4l0.3-0.1v-6
			c-0.8-0.2-1.5-0.2-2.1-0.2c-0.8,0-1.2,0.3-1.6,0.8c-0.3,0.6-0.5,1.4-0.5,2.6c0,1.1,0.2,2,0.5,2.4c0.3,0.5,0.8,0.8,1.5,0.8
			C144,28.7,144.6,28.6,145.4,28.4z"/>
                    <path d="M156.9,15.5h5.7c3.8,0,5.5,1.8,5.5,5.5c0,1.9-0.5,3.3-1.4,4.2c-0.9,0.9-2.3,1.4-4.1,1.4h-2.5v4.5h-3.2
			L156.9,15.5L156.9,15.5z M162.7,23.9c1.6,0,2.3-0.9,2.3-2.9c0-0.9-0.2-1.6-0.6-2.1c-0.4-0.5-0.9-0.7-1.8-0.7h-2.5v5.6
			C160.1,23.9,162.7,23.9,162.7,23.9z"/>
                    <path d="M170.2,19.6h3.1v1.2c1.3-0.8,2.5-1.2,3.7-1.5v3.1c-1.1,0.2-2.3,0.5-3.2,0.8l-0.5,0.2v7.7h-3.1V19.6z"/>
                    <path d="M179.5,29.8c-0.9-1-1.3-2.5-1.3-4.5c0-1.9,0.5-3.4,1.3-4.4c0.9-1,2.3-1.6,3.9-1.6c1.7,0,3,0.6,3.9,1.6
			c0.9,1,1.3,2.5,1.3,4.4c0,2-0.5,3.4-1.3,4.5c-0.9,1-2.3,1.6-3.9,1.6C181.7,31.4,180.4,30.8,179.5,29.8z M185.1,27.9
			c0.4-0.6,0.6-1.4,0.6-2.6s-0.2-2.1-0.6-2.6c-0.4-0.6-0.9-0.8-1.6-0.8c-0.8,0-1.3,0.3-1.6,0.8c-0.4,0.6-0.6,1.4-0.6,2.6
			s0.2,2.1,0.6,2.6c0.4,0.6,0.9,0.8,1.6,0.8S184.7,28.5,185.1,27.9z"/>
                    <path d="M188.8,34.1c0.7-0.5,1.2-0.8,1.5-1.1c0.3-0.3,0.5-0.7,0.6-0.9c0.1-0.4,0.2-0.8,0.2-1.4v-11h3.1v11
			c0,1-0.1,2-0.4,2.5c-0.2,0.7-0.7,1.2-1.2,1.7c-0.6,0.5-1.4,1-2.5,1.6L188.8,34.1z M191,15h3.1v3.1H191V15z"/>
                    <path d="M197.4,30.1c-0.8-0.9-1.2-2.3-1.2-4.4c0-2.2,0.4-3.7,1.2-4.7c0.8-1,2.2-1.6,3.9-1.6c1.6,0,2.8,0.5,3.7,1.3
			c0.8,0.8,1.2,2.2,1.2,3.9l-0.2,2.2h-6.8c0,0.6,0.1,0.9,0.3,1.2c0.2,0.3,0.4,0.5,0.8,0.6s0.8,0.2,1.5,0.2c0.5,0,1.1,0,1.8,0
			c0.8,0,1.2-0.1,1.5-0.1l0.7-0.1v2.3c-1.7,0.5-3.3,0.7-4.6,0.7C199.6,31.4,198.3,30.9,197.4,30.1z M203.4,24.3
			c0-0.9-0.2-1.6-0.5-1.9c-0.3-0.4-0.8-0.6-1.5-0.6s-1.2,0.2-1.6,0.6c-0.3,0.4-0.5,1-0.5,1.9H203.4z"/>
                    <path d="M209.2,30c-0.8-0.9-1.1-2.4-1.1-4.6s0.4-3.7,1.1-4.6c0.8-0.9,2-1.4,3.8-1.4c0.5,0,0.9,0,1.5,0.1
			s1.2,0.2,2.2,0.4l-0.1,2.4c-1.3-0.1-2.3-0.2-2.7-0.2c-0.8,0-1.2,0.1-1.6,0.3s-0.7,0.6-0.8,0.9c-0.2,0.5-0.2,1.1-0.2,2
			s0.1,1.5,0.2,2c0.2,0.5,0.4,0.8,0.8,1c0.4,0.2,0.9,0.3,1.7,0.3l2.6-0.2l0.1,2.4c-1.6,0.3-2.9,0.5-3.9,0.5
			C211.1,31.4,209.9,30.9,209.2,30z"/>
                    <path d="M220.7,31c-0.5-0.3-0.8-0.8-1-1.3c-0.2-0.7-0.3-1.5-0.3-2.6v-4.8h-1.3v-2.6h1.3v-3.2h3.1v3.2h2.8v2.6h-2.8V27
			c0,0.6,0.1,1,0.2,1.3c0.1,0.3,0.4,0.4,0.8,0.4h1.8l0.1,2.4c-0.7,0.1-1.1,0.2-1.4,0.3c-0.4,0-0.7,0.1-1,0.1
			C221.9,31.4,221.2,31.2,220.7,31z"/>
                </g>
            </g>
            <g>
                <polygon className="icon" style={ { ...iconFill, opacity : 0.5 } } points="25.2,26 10.4,33.3 10.4,16.2 25.2,8.9 		"/>
                <polygon className="icon" style={ { ...iconFill, opacity : 0.5 } } points="27.8,26 42.6,33.3 42.6,16.2 27.8,8.9 		"/>
                <polygon className="icon" style={ iconFill }  points="11.7,35.5 26.5,42.8 41.2,35.5 26.5,28.2 	"/>
            </g>
        </svg>
    );

    return <Icon component={ Svg } className={ className } />;
};


const LogoIcon : FunctionComponent<IIconLogo> = (
        {
            className,
            type,
            color
        }
    ) : JSX.Element => {

    const iconFill = color ? { fill : color } : mainColor;


    if(type === "full"){
            return <LogoFull className={ className } iconFill={ iconFill }/>;
    }

    return <LogoJustSign className={ className } iconFill={ iconFill }/>;

};


export default LogoIcon;