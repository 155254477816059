import TaskImportanceSelect from "./Task-importance-select";
import TaskStatusSelect from "./Task-status-select";
import TaskTypeSelect from "./Task-type-select";


const TaskFields = {
    Importance : TaskImportanceSelect,
    Status : TaskStatusSelect,
    Type : TaskTypeSelect
};

export default TaskFields;