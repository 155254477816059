import React, { JSX }  from "react";

import { Col, Row } from "antd";
import { useOutletContext, useParams } from "react-router-dom";

import Task from "components/task";

import type { TStandItem } from "graphql/type/stand-gql-types";
import type { TStandType } from "graphql/type/stand-type-gql-types";


const StandTasksSubPage : React.FC = () : JSX.Element => {

    const { stand , ee } = useOutletContext<{ stand: TStandItem<TStandType>,  ee: any }>();

    const pParams = useParams();

    return(
        <Row className="page-content stand-tasks">
            <Col
                {...{ xxl : 6, xl : 8, lg : 6, span : 24 }}
                className = { 'left-col' }
            >
                <Task.Lists.All
                    withHeader = { true }
                    standId    = { stand.id }
                    eeId       = { ee.id }
                    teamId     = { ee.team_id }
                />
            </Col>
            <Col
                {...{ xxl : 12, xl : 16, lg : 18, span : 24 }}
                className={'right-col'}
            >
                { pParams.taskId  &&
                    <Task.Blocks.View
                        taskId     = { pParams.taskId }
                        standId    = { stand.id }
                        eeId       = { ee.id }
                        teamId     = { ee.team_id }
                    />
                }

            </Col>

        </Row>
    );
};

export default StandTasksSubPage;