import { gql } from "@apollo/client";


export const IS_LOGGED_IN = gql`
    query IsUserLoggedIn {
        isLoggedIn @client
    }
`;

export const GET_PUBLIC_PARAMS = gql`
    query GetPublicParams {
        publicParams @client
    }
`;

export const GET_PRIVATE_PARAMS = gql`
    query GetPrivateParams {
        privateParams @client
    }
`;

export const clientSchemaExtensions = gql`

    directive @client on FIELD

    extend type Query {
        isLoggedIn: Boolean
        privateParams: Mixed
        publicParams: Mixed
    }
`;
