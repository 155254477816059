import React, { JSX } from 'react';

import { Form } from 'antd';

import CheckListBlocks from "../../blocks";
import { ECheckListType } from "../../check-list-types";

import type { IFormProps } from "../../check-list-types";

const { FormRow, FieldGroup } = CheckListBlocks;

const CheckListSitePlanningForm : React.FC<IFormProps > = ({ formData, eeId, listGroup }) : JSX.Element => {

    const [ form ] = Form.useForm();

    const defaultFields = {
        ee_id : eeId,
        list_group : listGroup,
        active : false,
        flag : false,
        list_value : ""
    };

    return (
        <div className="check-list-form ">
            <Form
                className={"inner-forms"}
                form={form}
                layout="vertical"
            >
                <FormRow
                    rowData={{
                        title : "Measurement",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "Measurement"),
                        list_type : ECheckListType.PLANNING,
                    }}
                >
                    <FieldGroup.Planning />
                </FormRow>
                <FormRow
                    rowData={{
                        title : "ElectricalConnections",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "ElectricalConnections"),
                        list_type : ECheckListType.PLANNING,
                    }}
                >
                    <FieldGroup.Planning />
                </FormRow>
                <FormRow
                    rowData={{
                        title : "Unloading",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "Unloading"),
                        list_type : ECheckListType.PLANNING,
                    }}
                >
                    <FieldGroup.Planning />
                </FormRow>
                <FormRow
                    rowData={{
                        title : "CarpetLaying",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "CarpetLaying"),
                        list_type : ECheckListType.PLANNING,
                    }}
                >
                    <FieldGroup.Planning />
                </FormRow>
                <FormRow
                    rowData={{
                        title : "EmptiesHandling",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "EmptiesHandling"),
                        list_type : ECheckListType.PLANNING,
                    }}
                >
                    <FieldGroup.Planning />
                </FormRow>
                <FormRow
                    rowData={{
                        title : "EmptiesStorage",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "EmptiesStorage"),
                        list_type : ECheckListType.PLANNING,
                    }}
                >
                    <FieldGroup.Planning />
                </FormRow>
                <FormRow
                    rowData={{
                        title : "Assembly",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "Assembly"),
                        list_type : ECheckListType.PLANNING,
                    }}
                >
                    <FieldGroup.Planning />
                </FormRow>
                <FormRow
                    rowData={{
                        title : "GraphicDesign",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "GraphicDesign"),
                        list_type : ECheckListType.PLANNING,
                    }}
                >
                    <FieldGroup.Planning />
                </FormRow>
                <FormRow
                    rowData={{
                        title : "Furniture",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "Furniture"),
                        list_type : ECheckListType.PLANNING,
                    }}
                >
                    <FieldGroup.Planning />
                </FormRow>
            </Form>
        </div>
    );
};

export default CheckListSitePlanningForm;