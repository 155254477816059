import React, { JSX } from "react";

import { ECheckListType } from "../../check-list-types";
import CheckListFields from "../../fields";
import CheckListHelpers from "../../helpers";

import type {  IFieldBlockProps } from "../../check-list-types";


const fieldStructure = {
    text : undefined,
    date : undefined
};

const { parseValues } = CheckListHelpers;

interface ICheckListTextDateProps extends IFieldBlockProps{
    viceVersa ?: boolean
    placeholder ?: string | {
        text: string
        date: string
    }
}

const CheckListTextDateGroupBlock : React.FC<ICheckListTextDateProps> = ({
        values,
        type,
        viceVersa,
        placeholder,
        ...props
    }) : JSX.Element => {

    const dateType = type === ECheckListType.TEXT_DATE ? ECheckListType.DATE : ECheckListType.DAYDATE;

    const fields = parseValues<typeof values, typeof fieldStructure>(
        {
            values,
            structure : fieldStructure
    });

    return(
        <div className={ `check-list-group grid-two-col ${viceVersa ? 'vice-versa' : ''}`}>
            <div className="col-one">
                <CheckListFields.Text
                    value={ fields.text }
                    placeholder = { typeof placeholder === "object" ? placeholder.text : placeholder }
                    multiField  = {{
                        current : 'text',
                        structure : fields
                    }}
                    { ...props }
                />
            </div>
            <div className="col-two">
                <CheckListFields.DatePicker
                    value       = { fields.date }
                    placeholder = { typeof placeholder === "object" ? placeholder.date : "When" }
                    type        = { dateType }
                    multiField  = {{
                        current : 'date',
                        structure : fields
                    }}
                    { ...props }
                />
            </div>
        </div>
    );
};

export default CheckListTextDateGroupBlock;