import React, { JSX } from "react";

import { useQuery } from "@apollo/client";
import { Select } from "antd";


import { GET_ORGANIZERS } from "graphql/query/organizer-q.gql";

import { Icons } from "components/layout";

import { EOrganizerOrETStatus } from "common/types";


const { Option } = Select;

const ExhibitionOrganizerSelect : React.FC = (props) : JSX.Element => {

    const { data, loading } = useQuery( GET_ORGANIZERS, {
        variables : {
            where : {
                column : "STATUS",
                operator : "EQ",
                value : EOrganizerOrETStatus.ACTIVE
            },
            orderBy : [ { column : "TITLE", order : "ASC" } ]
        },
        fetchPolicy : "cache-first"
    });

    const {
        organizersCursor : {
            edges : organizers = []
        } = {}
    } = data || {};


    return(
        <Select
            { ...props }
            loading={ loading }
            defaultValue={ ""! }
            suffixIcon    = { <Icons.Arrow /> }
        >
            <Option key={ 0 } value={ "" }>Not selected</Option>
            { organizers.map(
                (
                    { node : { id, title } } :
                    { node : { id: number, title: string } }
                ) => {

                        return(
                            <Option key={ id } value={ id } >{ title }</Option>
                        );
                    }
                )
            }
        </Select>
    );
};

export default React.memo(ExhibitionOrganizerSelect);