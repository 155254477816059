import React, { FunctionComponent, JSX } from 'react';

import Icon from '@ant-design/icons';

import { IIcon } from "./icons-interfaces";


const GraphicListSvg : React.FC = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path style={ { opacity : 0.5 } } d="M7.3,36.6c0,2.3,1.9,4.2,4.2,4.2h25.1c2.3,0,4.2-1.9,4.2-4.2v-8l-9.5-10.4l-9.1,11.5l-7.1-6.7l-7.8,8.6V36.6z
			"/>
        <path d="M36.5,7.4H11.5c-2.3,0-4.2,1.9-4.2,4.2v19.9l7.8-8.6l7.1,6.7l9.1-11.5l9.5,10.4V11.7C40.8,9.3,38.9,7.4,36.5,7.4z
			 M17.9,17.5c-1.5,0-2.7-1.2-2.7-2.7s1.2-2.7,2.7-2.7s2.7,1.2,2.7,2.7S19.4,17.5,17.9,17.5z"/>
    </svg>
);


const GraphicListIcon : FunctionComponent<IIcon> = ({ className }) : JSX.Element => {

    return <Icon component={ GraphicListSvg } className={ className } />;

};


export default GraphicListIcon;