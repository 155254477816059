export const prepareEditInitDataHelper = ( stand : any, lang : string ) => {

    const {
        relStandType,
        hall_id,
        flooring_id,
        flooring_title,
        relProjectManager,
        relAutocadEngineer,
        size_width,
        size_height,
        ...standFields
    } = stand;

    const standType = relStandType.find( ( standType : any ) => standType.lang === lang );

    return {
        ...standFields,
        size_width : size_width || 0,
        size_height : size_height || 0,
        hall_id : hall_id || "",
        standType : stand.stand_type_group ? {
            value : standType.group_id,
            label : standType.title,
            orientations : standType.orientations,
            standTypeId : standType.id,
            min : standType.size_min,
            max : standType.size_max,
        } : "",
        flooringType : flooring_id ? {
            value : flooring_id,
            label : flooring_title
        } : "",
        projectManager : stand.project_manager_id ? {
            value : relProjectManager.id,
            label : `${ relProjectManager.name } ${ relProjectManager.surname }`
        } : "",
        autocadEngineer : stand.autocad_engineer_id ? {
            value : relAutocadEngineer.id,
            label : `${ relAutocadEngineer.name } ${ relAutocadEngineer.surname }`
        } : ""
    };
};


export const prepareEditFormDataHelper = ( values : any ) => {

    const {
        standType,
        flooringType,
        projectManager,
        autocadEngineer,
        hall_id,
        ...newValues
    } = values;

    delete newValues['stand_area'];

    return {
        hall_id,
        stand_type_group : standType.value,
        flooring_id : flooringType ? flooringType.value : undefined,
        project_manager_id : projectManager ? projectManager.value : undefined,
        autocad_engineer_id : autocadEngineer ? autocadEngineer.value : undefined,
        ...newValues
    };
};