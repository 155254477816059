import { gql, TypedDocumentNode } from "@apollo/client";

import { IQueryVariables, TWhereCondition } from "../type/common-gql-types";
import { TFlooringCursorData } from "../type/flooring-gql-types";

interface IFlooringQueryVariables extends IQueryVariables{
    whereStandTypeFlooring        ?: TWhereCondition
    whereExhibitionTypeFlooring   ?: TWhereCondition
}

export const GET_FLOORINGS: TypedDocumentNode<{ flooringsCursor: TFlooringCursorData }, IFlooringQueryVariables > = gql`
    query GetFloorings (
        $text    : String
        $where                       : QueryFlooringsCursorWhereWhereConditions
        $whereStandTypeFlooring      : QueryFlooringsCursorWhereStandTypeFlooringWhereHasConditions
        $whereExhibitionTypeFlooring : QueryFlooringsCursorWhereExhibitionTypeFlooringWhereHasConditions
        $orderBy : [QueryFlooringsCursorOrderByOrderByClause!]
        $after   : String
    ){
        flooringsCursor(
            text    : $text
            where                       : $where
            whereStandTypeFlooring      : $whereStandTypeFlooring
            whereExhibitionTypeFlooring : $whereExhibitionTypeFlooring
            orderBy : $orderBy
            first   : 100
            after   : $after
        ) {
            pageInfo {
                hasNextPage
                startCursor
                endCursor
                total
                currentPage
            }
            edges {
                node{
                    id
                    title
                    article
                    type
                    color
                    images {
                        id
                        server_name
                        title
                        sizes
                    }
                    created_at
                    updated_at
                }
            }
        }
    }
`;
