import React from "react";

import { useQuery } from "@apollo/client";
import { Radio } from "antd";

import { GET_TEAM_USERS } from "graphql/query/user-q.gql";

import { whereConditionBuilder } from "common/utils";
import { Loader } from "components/request-result";

import type { TTeamMember } from "../team-types";
import type { ETeamRoles } from "common/types";


type TTeamUserListProps = {
    teamRole        ?: ETeamRoles | undefined,
    teamId          ?: number | undefined,
    setMember        : (props : TTeamMember) => void
    member          ?: TTeamMember | undefined
};

const TeamUserRadioList : React.FC<TTeamUserListProps> = (
        {
            teamRole,
            teamId,
            setMember,
            member
        }
    ) => {

    const { data, loading } = useQuery( GET_TEAM_USERS, {
        variables : {
            whereTeamsUser : whereConditionBuilder({
                teamRole : !teamRole ? {} : {
                    column : "TEAM_ROLE",
                    operator : "EQ",
                    value : teamRole
                },
                teamId : !teamId ? {} : {
                    column : "TEAM_ID",
                    operator : "EQ",
                    value : teamId
                },
            }),
            orderBy : [ { column : "NAME", order : "ASC" } ]
        },
        fetchPolicy : "cache-first",
    });

    const {
        usersCursor : {
            edges : teamUsers = []
        } = {}
    } = data || {};

    return(
        <div className={ `radio-select-wrap ${ loading ? "loading" : "" }`} >
            { loading ?
                <Loader /> :
                <Radio.Group
                    onChange={ (e) => {

                        const { node : {
                            id, name, surname
                        } } = teamUsers.find(
                            ({ node } : { node : TTeamMember }) => node.id === e.target.value
                        );

                        setMember({ id, name, surname });
                    } }
                    value={ member ?  member.id : undefined }
                >
                    { teamUsers.map( (
                        { node : { id, name, surname } } :
                        { node : TTeamMember }
                    ) => {

                        return(
                            <Radio key={ id } value={ id }>
                                { name } { surname }
                            </Radio>
                        );
                    } )}

                </Radio.Group>
            }
        </div>
    );
};

export default TeamUserRadioList;