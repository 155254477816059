import React from "react";

import { useRealWindowHeight, useScrollToTop } from "components/use-hook";


const InitHookSetup : React.FC = () : null => {

    useScrollToTop();
    useRealWindowHeight();

    return null;
};

export default InitHookSetup;