import React, { JSX } from 'react';

import { Form } from 'antd';

import CheckListBlocks from "../../blocks";
import { ECheckListType } from "../../check-list-types";
import Localize from "components/service/Localize";

import type { IFormProps } from "../../check-list-types";


const { FormRow, FieldGroup } = CheckListBlocks;
const CheckListCraftsFurnitureForm : React.FC<IFormProps> = (
    {
        formData,
        eeId,
        listGroup
    }) : JSX.Element => {

    const [ form ] = Form.useForm();

    const defaultFields = {
        ee_id : eeId,
        list_group : listGroup,
        active : false,
        flag : false,
        list_value : ""
    };

    return (
        <div className="check-list-form ">
            <Form
                className     = { "inner-forms" }
                form          = { form }
                layout        = "vertical"
            >
                <Localize tag="h3" wrap>
                    { `CHECKLIST.Form_Title_${listGroup}` }
                </Localize>
                <FormRow
                    label={"Furniture supplier/s"}
                    rowData={{
                        title : "FurnitureSuppliers",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "FurnitureSuppliers"),
                        list_type : ECheckListType.STR,
                    }}
                >
                    <FieldGroup.SingleText
                        placeholder={"Offen"}
                    />
                </FormRow>
                <FormRow
                    rowData={{
                        title : "CommunicateForecastToSuppliersAsEarlyAsPossible",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "CommunicateForecastToSuppliersAsEarlyAsPossible"),
                        list_type : ECheckListType.TEXT_DATE,
                    }}
                >
                    <FieldGroup.TextDate />
                </FormRow>
                <FormRow
                    rowData={{
                        title : "DiscussWithSuppliersWhichFurnitureIsInShortSupply",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "DiscussWithSuppliersWhichFurnitureIsInShortSupply"),
                        list_type : ECheckListType.STR,
                    }}
                >
                    <FieldGroup.SingleText
                        placeholder={"Offen"}
                    />
                </FormRow>
                <FormRow
                    rowData={{
                        title : "FindAlternativeSuppliersIfNecessary",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "FindAlternativeSuppliersIfNecessary"),
                        list_type : ECheckListType.STR,
                    }}
                >
                    <FieldGroup.SingleText
                        placeholder={"Offen"}
                    />
                </FormRow>
                <FormRow
                    rowData={{
                        title : "DetermineAndOrderReserves",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "DetermineAndOrderReserves"),
                        list_type : ECheckListType.STR,
                    }}
                >
                    <FieldGroup.SingleText
                        placeholder={"Offen"}
                    />
                </FormRow>
                <FormRow
                    rowData={{
                        title : "OrderFinalFurnitureQuantitiesUsingFurnitureList",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "OrderFinalFurnitureQuantitiesUsingFurnitureList"),
                        list_type : ECheckListType.DAYDATE_TIME,
                    }}
                >
                    <FieldGroup.DateTime />
                </FormRow>
                <FormRow
                    rowData={{
                        title : "SendDistributionListAndCurrentHallPlanToSuppliers",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "SendDistributionListAndCurrentHallPlanToSuppliers"),
                        list_type : ECheckListType.DAYDATE_TIME,
                    }}
                >
                    <FieldGroup.DateTime />
                </FormRow>
                <FormRow
                    rowData={{
                        title : "CommunicateDeliveryAndDistributionStartDate",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "CommunicateDeliveryAndDistributionStartDate"),
                        list_type : ECheckListType.DAYDATE_TIME,
                    }}
                >
                    <FieldGroup.DateTime />
                </FormRow>
                <FormRow
                    rowData={{
                        title : "CommunicateFurnitureHandoverDate",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "CommunicateFurnitureHandoverDate"),
                        list_type : ECheckListType.DAYDATE_TIME,
                    }}
                >
                    <FieldGroup.DateTime />
                </FormRow>
                <FormRow
                    rowData={{
                        title : "CommunicateServicePeriodReadinessForComplaints",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "CommunicateServicePeriodReadinessForComplaints"),
                        list_type : ECheckListType.DAYDATE_TIME,
                    }}
                >
                    <FieldGroup.DateTime />
                </FormRow>
                <FormRow
                    rowData={{
                        title : "CommunicateFurnitureDismantlingStartDate",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "CommunicateFurnitureDismantlingStartDate"),
                        list_type : ECheckListType.DAYDATE_TIME,
                    }}
                >
                    <FieldGroup.DateTime />
                </FormRow>
                <FormRow
                    rowData={{
                        title : "CommunicateFurnitureDismantlingEndDate",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "CommunicateFurnitureDismantlingEndDate"),
                        list_type : ECheckListType.DAYDATE_TIME,
                    }}
                >
                    <FieldGroup.DateTime />
                </FormRow>
            </Form>
        </div>
    );
};

export default CheckListCraftsFurnitureForm;