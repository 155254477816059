import React, { JSX }  from "react";

import { Col, Row } from "antd";
import { useOutletContext } from "react-router-dom";

import Stand from "components/stand";

import type { TStandItem } from "graphql/type/stand-gql-types";
import type { TStandType } from "graphql/type/stand-type-gql-types";


const StandDetailsSubPage : React.FC = () : JSX.Element => {

    const { ee, stand } = useOutletContext<{ ee : any, stand: TStandItem<TStandType> | undefined }>();

    return(
        <Row className="page-content stand-details" gutter={[ 24, 16 ]}>
            <Col {...{
                xxl : 12, xl : 16, lg : 18, span : 24
            }}>
                <h4 className="blue">Stand info</h4>
                <Stand.Forms.Edit
                    isCreate = { !stand }
                    ee       = { ee }
                    stand    = { stand }
                />
            </Col>
            <Col {...{
                xxl : 6, xl : 8, lg : 6, span : 24
            }}>
                { stand &&
                    <>
                        <Stand.Blocks.CadInfo
                            standId = { stand.id }
                        />
                        <Stand.Blocks.Comments
                            standId = { stand.id }
                            totalComments = { stand.total_comment }
                        />
                    </>
                }
            </Col>
        </Row>
    );
};

export default StandDetailsSubPage;