import React, { JSX } from 'react';

import { Form } from 'antd';

import CheckListBlocks from "../../blocks";
import { ECheckListType } from "../../check-list-types";
import Localize from "components/service/Localize";

import type { IFormProps } from "../../check-list-types";


const { FormRow, FieldGroup } = CheckListBlocks;

const CheckListExpoDatesInternalForm : React.FC<IFormProps> = ({ formData, eeId, listGroup }) : JSX.Element => {

    const [ form ] = Form.useForm();

    const defaultFields = {
        ee_id : eeId,
        list_group : listGroup,
        active : false,
        flag : false,
        list_value : ""
    };

    return (
        <div className="check-list-form ">
            <Form
                className={"inner-forms"}
                form={form}
                layout="vertical"
            >
                <Localize
                    tag="h3"
                    wrap
                >{ `CHECKLIST.Form_Title_${listGroup}` }</Localize>
                <FormRow
                    rowData={{
                        title : "DeadlineForChangesToStandConstruction",
                        list_type : ECheckListType.DATETIME,
                        ...defaultFields,
                        ...formData?.find( item => item.title === "DeadlineForChangesToStandConstruction")
                    }}
                >
                    <FieldGroup.SingleDateTime />
                </FormRow>
                <FormRow
                    rowData={{
                        title : "DeadlineForSubmittingGraphicsDataForExhibitors",
                        list_type : ECheckListType.DATETIME,
                        ...defaultFields,
                        ...formData?.find( item => item.title === "DeadlineForSubmittingGraphicsDataForExhibitors")
                    }}
                >
                    <FieldGroup.SingleDateTime />
                </FormRow>
                <FormRow
                    rowData={{
                        title : "DeadlineForSubmittingGraphicDataForSpecialAreas",

                        ...defaultFields,
                        ...formData?.find( item => item.title === "DeadlineForSubmittingGraphicDataForSpecialAreas"),
                        list_type : ECheckListType.DATETIME,
                    }}
                >
                    <FieldGroup.SingleDateTime />
                </FormRow>
                <FormRow
                    rowData={{
                        title : "ReleasePicking",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "ReleasePicking"),
                        list_type : ECheckListType.DATETIME,
                    }}
                >
                    <FieldGroup.SingleDateTime />
                </FormRow>
                <FormRow
                    rowData={{
                        title : "PickingFromTo",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "PickingFromTo"),
                        list_type : ECheckListType.DATE_RANGE,
                    }}
                >
                    <FieldGroup.DateRange />
                </FormRow>
                <FormRow
                    rowData={{
                        title : "CreateCarnetLists",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "CreateCarnetLists"),
                        list_type : ECheckListType.DATE,
                    }}
                >
                    <FieldGroup.SingleDateTime />
                </FormRow>
                <FormRow
                    rowData={{
                        title : "LIHKAppointment",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "LIHKAppointment"),
                        list_type : ECheckListType.DATE,
                    }}
                >
                    <FieldGroup.SingleDateTime />
                </FormRow>
                <FormRow
                    rowData={{
                        title : "IHKCarnetExhibition",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "IHKCarnetExhibition"),
                        list_type : ECheckListType.DATE,
                    }}
                >
                    <FieldGroup.SingleDateTime />
                </FormRow>
                <FormRow
                    rowData={{
                        title : "CustomsTauchaAppointmentsWithTrucks",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "CustomsTauchaAppointmentsWithTrucks"),
                        list_type : ECheckListType.DATE,
                    }}
                >
                    <FieldGroup.SingleDateTime />
                </FormRow>
                <FormRow
                    rowData={{
                        title : "Loading",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "Loading"),
                        list_type : ECheckListType.DATE,
                    }}
                >
                    <FieldGroup.SingleDateTime />
                </FormRow>
                <FormRow
                    rowData={{
                        title : "ArrivalTruckExhibitionGrounds",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "ArrivalTruckExhibitionGrounds"),
                        list_type : ECheckListType.DATE,
                    }}
                >
                    <FieldGroup.SingleDateTime />
                </FormRow>
                <FormRow
                    rowData={{
                        title : "UnloadingTruckExhibitionTrucks",
                        ...defaultFields,
                        ...formData?.find( item => item.title === "UnloadingTruckExhibitionTrucks"),
                        list_type : ECheckListType.DATE
                    }}
                >
                    <FieldGroup.SingleDateTime />
                </FormRow>
            </Form>
        </div>
    );
};

export default CheckListExpoDatesInternalForm;