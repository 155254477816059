import React from "react";

import { useMutation } from "@apollo/client";
import { TID } from "graphql/type/common-gql-types";

import { TASK_UPDATE_STATUS } from "graphql/mutation/task-m.gql";

import TaskFields from "../fields";
import { useNotification } from "components/use-hook";


interface ITaskChangeStatusFormProps {
    taskId : TID
    status : string
}

const TaskChangeStatusForm : React.FC<ITaskChangeStatusFormProps> = ({ taskId, status }) : JSX.Element => {

    const { nError, nSuccess } = useNotification();

    const [ taskStatusUpdate, { loading } ] = useMutation(
        TASK_UPDATE_STATUS,
        {
            update(cache, { data }) {

                const { 'taskUpdate' : {
                    label,
                    message,
                }  } = data;

                nSuccess(label, message);
            },
            onError(error) {
                console.error(error);
            }
        }
    );

    return(
        <TaskFields.Status
            status   = { status }
            loading  = { loading }
            onChange = { (value: string) => {
                if(!loading){
                    taskStatusUpdate({
                        variables : {
                            input : {
                                id : taskId,
                                status : value
                            }
                        }
                    }).catch(nError);
                }
            }}

        />
    );
};

export default TaskChangeStatusForm;