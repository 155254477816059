import React, { JSX }  from "react";

import { Col, Row } from "antd";
import { useOutletContext } from "react-router-dom";

import Stand from "components/stand";

import type { TStandItem } from "graphql/type/stand-gql-types";
import type { TStandType } from "graphql/type/stand-type-gql-types";


const StandEquipmentSubPage : React.FC = () : JSX.Element => {

    const { stand, ee } = useOutletContext<{ stand: TStandItem<TStandType>, ee: any }>();

    return(
        <Row className="page-content stand-equipments" gutter={[ 24, 16 ]}>
            <Col {...{
                xxl : 12, xl : 16, lg : 18, span : 24
            }}>
                <Stand.Blocks.Order
                    standId = { stand.id }
                    ee      = { ee }
                    type    = {'default'}
                />
                <Stand.Blocks.Order
                    standId = { stand.id }
                    ee      = { ee }
                    type    = {'additional'}
                />
            </Col>
            <Col {...{
                xxl : 6, xl : 8, lg : 6, span : 24
            }}>
                <Stand.Blocks.EquipmentComment
                    standId = { stand.id }
                    message = { stand.relStandCommentEquipment }
                />
            </Col>
        </Row>
    );
};

export default StandEquipmentSubPage;