import React, { JSX } from "react";

import CheckListFields from "../../fields";

import type { IFieldBlockProps } from "../../check-list-types";

const CheckListMoneySingleBlock : React.FC<IFieldBlockProps> = ({ values, placeholder, ...props }) : JSX.Element => {

    return(
        <div className="check-list-group">
            <CheckListFields.Money
                value={ values }
                placeholder={ typeof placeholder === "string" ? placeholder : undefined }
                { ...props }
            />
        </div>
    );
};

export default CheckListMoneySingleBlock;