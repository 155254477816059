import React, { useEffect, useState } from "react";

import { Button } from "antd";

import { Icons } from "components/layout";

import type { ICheckListItem } from "../check-list-types";


interface ICheckListCheckBtnProps {
    checked : boolean
    loading : boolean
    handleOnClick : (props: Pick<ICheckListItem, 'active'>) => void;
}

const CheckListCheckFieldBtn: React.FC<ICheckListCheckBtnProps> = ({ handleOnClick, checked, loading }) => {

    const [ btnLoading, setBtnLoading  ] = useState<boolean>(false);

    useEffect( () => {
        if(!loading){
            setBtnLoading(false);
        }
    }, [ loading ]);

    return(
        <Button
            className={ `check-list-btn check-btn ant-btn-text icon ${ checked ? "checked" : "" }` }
            onClick={ () => {
                setBtnLoading(true);
                handleOnClick({ active : !checked });
            } }
        >
            <Icons.Check loading={ btnLoading } />
        </Button>
    );
};

export default React.memo(CheckListCheckFieldBtn);