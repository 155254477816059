import React, { JSX } from "react";

import type { TStandData } from "graphql/type/stand-gql-types";


interface IStandPageTitleProps {
    stand: TStandData
}

const StandPageTitle : React.FC<IStandPageTitleProps> = ({ stand }) : JSX.Element => {

    const {
        company_name,
        hall_title,
        stand_number,
        block } = stand;

    return (
        <>
            { company_name }
            { (hall_title || stand_number || block) &&
                <span>(
                    { hall_title &&
                        <>Hall : { hall_title }</>
                    }
                    { stand_number &&
                        <>, Stand : { stand_number }</>
                    }
                    { block &&
                        <>, Block : { block }</>
                    }
                )</span>
            }
        </>
    );
};

export default StandPageTitle;