import React, { FunctionComponent, JSX } from 'react';

import Icon from '@ant-design/icons';

import { Loader } from "components/request-result";

import type { IIcon } from "./icons-interfaces";


const FlagSvg : React.FC = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M39.35,42.8c-.33,0-.65-.08-.95-.24l-14.26-7.68-14.26,7.68c-.62.33-1.37.32-1.97-.04-.6-.36-.97-1.01-.97-1.72V8.63c0-1.1.9-2,2-2h30.42c1.1,0,2,.9,2,2v32.17c0,.7-.37,1.36-.97,1.72-.32.19-.67.28-1.03.28Z"/>
    </svg>
);

const FlagOutlineSvg: React.FC = () => (
    <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1">
        <path d="M39.35,42.8c-.33,0-.65-.08-.95-.24l-14.26-7.68-14.26,7.68c-.62.33-1.37.32-1.97-.04-.6-.36-.97-1.01-.97-1.72V8.63c0-1.1.9-2,2-2h30.42c1.1,0,2,.9,2,2v32.17c0,.7-.37,1.36-.97,1.72-.32.19-.67.28-1.03.28ZM24.14,30.61c.33,0,.65.08.95.24l12.26,6.6V10.63H10.93v26.83l12.26-6.6c.3-.16.62-.24.95-.24Z"/>
    </svg>
);


const FlagIcon: FunctionComponent<IIcon> = ({ className, type, loading }): JSX.Element => {

    if (loading) {
        return <Loader/>;
    }

    if (type === "outline") {
        return <Icon component={FlagOutlineSvg} className={className}/>;
    }

    return <Icon component={FlagSvg} className={ className } />;

};


export default FlagIcon;