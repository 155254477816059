import React, { FunctionComponent, JSX } from 'react';

import Icon from '@ant-design/icons';

import { Loader } from "components/request-result";

import type { IIcon } from "./icons-interfaces";


const MinusSvg : React.FC = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path className="cls-1" d="M40.7,25.9H7c-1.1,0-2-.9-2-2s.9-2,2-2h33.7c1.1,0,2,.9,2,2s-.9,2-2,2Z"/>
    </svg>
);

const MinusIcon: FunctionComponent<IIcon> = (props): JSX.Element => {

    if(props.loading){
        return <Loader />;
    }

    return <Icon component={ MinusSvg } className={ props.className } />;

};


export default MinusIcon;