import React, { JSX } from "react";

import { Link, NavLink } from "react-router-dom";

import { ROUTES } from "components/routes";


const MainMenu : React.FC = () : JSX.Element => {

    return (
        <nav className="main-menu-wrap">
            <ul className="main-menu">
                <li>
                    <NavLink to={ ROUTES.EXHIBITION_LIST }>
                        Exhibitions
                    </NavLink>
                </li>
                <li>
                    <Link to={ ROUTES.LIBRARY_STAND_TYPES }>
                        Library
                    </Link>
                </li>
                <li>
                    <Link to={ ROUTES.MY_TASKS }>
                        My tasks
                    </Link>
                </li>
            </ul>
        </nav>
    );
};

export default MainMenu;