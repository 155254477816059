import { useEffect } from "react";


const useRealWindowHeight = () : null => {

    useEffect( () => {

        function setVH(){
            document.documentElement
                    .style
                    .setProperty('--vh', `${ window.innerHeight * 0.01 }px`);
        }

        setVH();

        window.addEventListener('resize', setVH);
        window.addEventListener('orientationchange', setVH);

    },[]);

    return null;
};

export default useRealWindowHeight;