import React, { JSX, ReactNode } from "react";

import { PopoverMenuHolder } from "components/service";


const PopoverMenu : React.FC< { children : ReactNode } > = ({ children }) : JSX.Element => {

    return(
        <PopoverMenuHolder>
            <ul
                onClick   = {
                    () => {
                        const element = document.querySelector(".ant-popover-open") as HTMLElement;

                        if(element){
                            element.click();
                        }
                    }
                }
                className="popover-menu"
            >
                { Array.isArray(children) ?
                    children.map(
                        (item, idx) => <li key={ idx }>{ item }</li>
                    ) :
                    children
                }
            </ul>
        </PopoverMenuHolder>
    );
};

export default PopoverMenu;