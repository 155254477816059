import React, { JSX, useState } from "react";

import { useMutation } from "@apollo/client";
import { Button, Input } from "antd";

import { STAND_COMMENT_CREATE, STAND_COMMENT_UPDATE } from "graphql/mutation/stand-m.gql";

import { Icons } from "components/layout";
import ModalStandard from "components/service/Modal-standard";
import { useNotification } from "components/use-hook";

import type { ApolloCache } from "@apollo/client/cache";
import type { TID } from "graphql/type/common-gql-types";
import type { TStandComment, TStandCommentType } from "graphql/type/stand-gql-types";


interface StandCommentEditModalProps {
    standId    : TID
    comment   ?: TStandComment
    type       : TStandCommentType
    onUpdate  ?:  (id : TID, comment : TStandComment, cache: ApolloCache<any>) => void
    btnClass  ?: string
}

const { TextArea } = Input;

const StandCommentEditModal : React.FC<StandCommentEditModalProps> = ({
        standId,
        comment,
        type,
        onUpdate,
        btnClass = 'blue',
    }) : JSX.Element => {

    const { nError, nSuccess } = useNotification();

    const [ standCommentEdit, { loading } ] = useMutation(
        !comment ? STAND_COMMENT_CREATE : STAND_COMMENT_UPDATE,
        {
            update(cache, { data }) {

                const { [!comment ? "standCommentCreate" : "standCommentUpdate" ] : {
                    label,
                    message,
                    standComment
                }  } = data;


                if(onUpdate){
                    onUpdate(standId, standComment, cache);
                }

                nSuccess(label, message);

            },
            onError(error) {
                console.error(error);
            }
        }
    );

    const isCommentExist = comment && comment.comment !== null;

    const [ openModal, setOpenModal ] = useState( false ),
          [ commentText, setCommentText ] = useState( !comment ? '' : comment.comment);

    return (
        <>
            <Button
                type      = "text"
                className = { btnClass }
                onClick   = { () => setOpenModal(true) }
            >
                { isCommentExist ? <Icons.Edit /> : <Icons.Plus /> }
                { isCommentExist ? 'Edit' : 'Add' }
            </Button>
            <ModalStandard
                title      = { <h2>{ isCommentExist ? 'Edit Comment' : 'Add comment' }</h2> }
                isOpen     = { openModal }
                extraClass = { "stand-comment-edit-modal" }
                close      = { () => {
                    if(!comment || comment.comment === ''){
                        setCommentText('');
                    }

                    setOpenModal(false);
                }}
                okText     = { isCommentExist ? 'Save' :  'Add' }
                confirm    = {
                    () => {
                        standCommentEdit( {
                            variables : {
                                input : {
                                    id : comment && comment.id || null,
                                    stand_id : standId,
                                    type,
                                    comment : commentText
                                }
                            } 
                        }).catch( nError );


                        setOpenModal(false);
                    }
                }
            >
                <TextArea
                    placeholder = "Type comment text"
                    autoSize    = {{ minRows : 6, maxRows : 6 }}
                    value       = { commentText }
                    onChange    = { (e) => !loading && setCommentText(e.target.value) }
                />
            </ModalStandard>
        </>

    );
};

export default StandCommentEditModal;