import { ApolloError, useQuery } from "@apollo/client";
import { WatchQueryFetchPolicy } from "@apollo/client/core/watchQueryOptions";

import { GET_VARIABLE_LISTS } from "graphql/query/common-q.gql";

import type { TLocales } from "common/types";
import type { TVariableItem } from "graphql/type/common-gql-types";


interface IUseVariableLists {
    variableLists : TVariableItem[] | null
    loading ?: boolean
    error ?: ApolloError
}

const useVariableLists = (
    fetchPolicy : WatchQueryFetchPolicy = "cache-only",
    withStatus  : boolean = false,
    lang        : TLocales | undefined | null = "en"
) : IUseVariableLists  => {

    const {
        data,
        loading,
        error
    } = useQuery( GET_VARIABLE_LISTS, {
        variables : {
            where : {
                column : "LANG",
                operator : "EQ",
                value : lang,
                AND : [ {
                    column : "GROUP_NAME",
                    operator : "NOT_IN",
                    value : [ "role", "supplier type", "job type" ]
                } ]
            },
            orderBy : [ { column : "ID", order : "ASC" } ]
        },
        skip : lang === undefined || lang === null,
        fetchPolicy
    });

    const variableLists = data ?
        data.variableListsCursor.edges.map(( { node } ) => node) : null;


    if(withStatus)
        return {
            variableLists,
            loading,
            error
        };

    return { variableLists };


};

export default useVariableLists;