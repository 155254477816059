import StandDetailsSubPage from "./Stand-details-sub-page";
import StandEquipmentSubPage from "./Stand-equipments-sub-page";
import StandGraphicsSubPage from "./Stand-graphics-sub-page";
import StandTasksSubPage from "./Stand-tasks-sub-page";
import StandTypeInfoSubPage from "./Stand-type-info-sub-page";


const StandSub = {
    Details : StandDetailsSubPage,
    Equipment : StandEquipmentSubPage,
    Graphics : StandGraphicsSubPage,
    Tasks : StandTasksSubPage,
    TypeInfo : StandTypeInfoSubPage
};

export default StandSub;