import React, { JSX, useState } from "react";

import { Select } from "antd";

import { Fields, Icons } from "../layout";


import { IFilterOption } from "common/types";


export interface IFilterDrawerProps<TFilterProps> {
    filterOptions : IFilterOption[]
    //propsFilter: <T ,>(props: T) => T
    drawContent: (props: TFilterProps) => JSX.Element
    filterProps: TFilterProps
}
const DrawerFilter  = <TFilterProps ,>(
    {
        filterOptions,
        filterProps,
        drawContent
    } : IFilterDrawerProps<TFilterProps>) : JSX.Element => {

    const [ filterType,  setFilterType ] = useState(0),
          [ searchText, setSearchText ] = useState({ text : "" });

    const filteredProps = { ...filterProps, ...searchText } as TFilterProps;

    filterOptions[filterType]!.filterFields!.forEach( ( item ) => {
            delete filteredProps[item as keyof TFilterProps];
        }
    );

    return (
        <>
            <div className="drawer-filter">
                <Select
                    popupClassName = "drawer-filter-dropdown"
                    suffixIcon  = { <Icons.Arrow /> }
                    onChange    = { (value) => setFilterType(value) }
                    value       = { filterType! }
                    options     = { filterOptions! }
                />
            </div>
            <div className="drawer-search">
                <Fields.Search filters    = { searchText }
                               setFilters = { setSearchText }
                />
            </div>
            <div className="drawer-content">
                { drawContent(filteredProps) }
            </div>
        </>
    );
};


export default DrawerFilter;