import StandCadInfoForm from "./Stand-cad-info-form";
import StandEditForm from "./Stand-edit-form";

import "./stand-forms.scss";


const StandForms = {
    Edit : StandEditForm,
    CadInfo : StandCadInfoForm,
};

export default StandForms;