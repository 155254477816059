import React, { JSX, useEffect, useState } from "react";

import { Button, Input } from "antd";

import CheckListButtons from "../buttons";
import { Icons } from "components/layout";

import type { ICheckListItem } from "../check-list-types";


const { TextArea } = Input;

interface ICheckListCommentProps {
    handleOnBlur : (props: Pick<ICheckListItem, 'comment'>) => void;
    comment: string
}

const CheckListCommentField: React.FC<ICheckListCommentProps> = ({
        comment,
        handleOnBlur
    }) : JSX.Element => {

    const [ showComment, setShowComment ] = useState<boolean>( !!comment && comment.length > 1 );
    const [ commentText, setCommentText ]  = useState<string>(comment);
    const [ loading, setLoading ]  = useState<boolean>(false);

    useEffect(() => {
        setLoading(false);
    }, [ comment ]);

    return(
        <div className="check-list-comment-field">
            { !showComment ?
                <Button
                    className="show-comment-btn check-list-btn ant-btn-text icon"
                    onClick={ () => setShowComment(true) }
                >
                    <Icons.Comment />
                </Button> :
                <div className="comment-field-wrap">
                    <div className="comment-data">
                        <TextArea
                            placeholder = "Type comment text"
                            autoSize    = {{ minRows : 1, maxRows : 4 }}
                            value       = { commentText }
                            onBlur      = {
                                (e) => {
                                    if( e.target.value !== comment && !loading){
                                        setLoading(true);
                                        handleOnBlur({ comment : e.target.value });
                                    }
                                }
                            }
                            onChange    = { (e) => !loading && setCommentText(e.target.value) }
                        />
                    </div>
                    <div className="remove-comment-wrap">
                        <CheckListButtons.DeleteComment
                            loading  = { loading }
                            callback = { () => {
                                if(!loading){
                                    if(comment !== ""){
                                        handleOnBlur({ comment : "" });
                                    }
                                    setCommentText('');
                                    setShowComment(false);
                                }
                            }}
                        />
                    </div>
                </div>
            }
        </div>
    );
};

export default CheckListCommentField;