//import StandButtons from "./buttons";
import StandTypeBlocks from "./blocks";
//import StandFields from "./fields";
import StandTypeLists from "./lists";


const StandType  = {
    Blocks : StandTypeBlocks,
    //Fields : StandFields,
    //Buttons : StandButtons,
    Lists : StandTypeLists
};

export default StandType;