import React from "react";

import { Button } from "antd";

import { Icons } from "components/layout";


interface ICheckListDeleteBtnProps {
    callback : () => void
    loading: boolean
}

const CheckListDeleteCommentBtn: React.FC<ICheckListDeleteBtnProps> = ({ callback, loading }) => {

    return(
        <Button
            className="check-list-btn delete-comment-btn ant-btn-text icon"
            onClick={ () => {
                callback();
            }}
        >
            <Icons.Cross loading={ loading } />
        </Button>
    );
};

export default CheckListDeleteCommentBtn;