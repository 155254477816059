//import FlooringButtons from "./buttons";
import FlooringBlocks from "./blocks";
import FlooringFields from "./fields";
//import FlooringForms from "./forms";
//import FlooringHelpers from "./helpers";
//import FlooringTables from "./tables";
import FlooringLists from "./lists";


const Flooring  = {
    //Forms : FlooringForms,
    Blocks : FlooringBlocks,
    Fields : FlooringFields,
    //Buttons : FlooringButtons,
    //Helpers : FlooringHelpers,
    //Tables : FlooringTables
    Lists : FlooringLists
};

export default Flooring;