import React, { useEffect, useState } from "react";

import { Button } from "antd";

import { Icons } from "components/layout";

import type { ICheckListItem } from "../check-list-types";


interface ICheckListImportantFieldBtnProps {
    handleOnClick : (props: Pick<ICheckListItem, 'flag'>) => void;
    flag : boolean
    loading : boolean
}

const CheckListImportantFieldBtn: React.FC<ICheckListImportantFieldBtnProps> = ({ flag, handleOnClick, loading }) => {

    const [ btnLoading, setBtnLoading  ] = useState<boolean>(false);

    useEffect( () => {
        if(!loading){
            setBtnLoading(false);
        }
    }, [ loading ]);

    return(
        <Button
            className={ `check-list-btn ant-btn-text icon important-btn ${ flag ? "important" : "" }` }
            onClick={ () => {
                setBtnLoading(true);
                handleOnClick({ flag : !flag });
            } }
        >
            { flag ?
                <Icons.Flag loading={ btnLoading } /> :
                <Icons.Flag type="outline" loading={ btnLoading } />
            }
        </Button>
    );
};

export default React.memo(CheckListImportantFieldBtn);