import React, { FC, JSX,  } from "react";

import { Button } from "antd";


import { EE_HALL_PLAN_UPLOAD } from "graphql/mutation/exhibition-m.gql";

import { Icons } from "components/layout";
import { DeleteButton, UploadFile } from "components/service";

import { EFileUploadModel, EFileUploadModelField } from "common/types";


interface IExhibitionHallPlanFileProps{
    withLabel     ?: boolean
    uploadBtnType ?: "primary" | "text"
    hall           : {
        id            : number
        hall_plan    ?: string
    }
}

const ExhibitionHallPlanFile : FC<IExhibitionHallPlanFileProps> = ({ withLabel, hall, uploadBtnType }) : JSX.Element => {

    const uploadVars = {
        input : {
            model : EFileUploadModel.HALL,
            model_id : hall.id,
            model_field : EFileUploadModelField.HALL_PLAN
        }
    };

    return(
        <div className="hall-plan">
            { withLabel && <h5 className="label">Hall plan</h5>}
            { !hall.hall_plan ?
                <UploadFile
                    variables={{
                        input : {
                            model : EFileUploadModel.HALL,
                            model_id : hall.id,
                            model_field : EFileUploadModelField.HALL_PLAN
                        }
                    }}
                    mutation = { EE_HALL_PLAN_UPLOAD }
                    accept   = { "application/pdf" }
                >
                    {( { loading } ) => {
                        return(
                            <Button type={ uploadBtnType || "primary" }>
                                <Icons.Upload loading={ loading }/>
                                Upload plan
                            </Button>
                        );
                    }}
                </UploadFile> :
                <div className="uploaded-file">
                    <a href={ hall.hall_plan }
                       target={ "_blank" }
                    >
                        <Icons.Download />
                        <span>Download</span>
                    </a>
                    <DeleteButton
                        id         = { hall.id }
                        buttonType = { "text" }
                        query      = { EE_HALL_PLAN_UPLOAD }
                        variables  = {{ ...uploadVars }}
                    />
                </div>
            }
        </div>
    );
};

export default ExhibitionHallPlanFile;