import useExhibitionTypeFragment from "./useExhibitionTypeFragment";
import useOrganizerFragment from "./useOrganizerFragment";


const ExhibitionHooks = {
    exhibitionType : useExhibitionTypeFragment,
    organizer : useOrganizerFragment,
};

export default ExhibitionHooks;
