import TaskCommentsBlock from "./Task-comments-block";
import TaskListHeaderBlock from "./Task-list-header-block";
import TaskViewBlock from "./Task-view-block";

import "./task-blocks.scss";


const TaskBlocks = {
    View : TaskViewBlock,
    ListHeader : TaskListHeaderBlock,
    Comments : TaskCommentsBlock
};


export default TaskBlocks;