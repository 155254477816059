import EquipmentFilterBlock from "./Equipment-filter-block";
import EquipmentInfoBlock from "./Equipment-info-block";

import "./equipment-blocks.scss";


const EquipmentBlocks = {
    info : EquipmentInfoBlock,
    Filter : EquipmentFilterBlock,
};

export default EquipmentBlocks;