import StandTypeDescriptionBlock from "./Stand-type-description-block";
import StandTypeFilesBlock from "./Stand-type-files-block";
import StandTypeNotesBlock from "./Stand-type-notes-block";

import "./stand-type-blocks.scss";


const StandTypeBlocks = {
    Files : StandTypeFilesBlock,
    Notes : StandTypeNotesBlock,
    Description : StandTypeDescriptionBlock,
};

export default StandTypeBlocks;