import React from "react";

import { Navigate, Route, Routes } from 'react-router-dom';

import ROUTES from "./routes-constants";
import { Pages, Sections } from "components/pages";
//import { useMe, useFilterStorage } from "components/use-hooks";


const AppRoutes = () => {
    /*
    const { me } = useMe(),
        filterStorage = useFilterStorage();

    if(hasActiveEvent){
        filterStorage.restore("all");
    }
    */
    return(
        <Routes>
            <Route path={ ROUTES.HOME } element={
                <Navigate to={ ROUTES.EXHIBITION_LIST } /> }
            />
            <Route path={ `${ ROUTES.EXHIBITION_LIST }/*` }>
                <Route index
                       element={ <Pages.Exhibitions /> }
                />
                <Route
                    path={ `${ ROUTES.EXHIBITION_ITEM }` }
                    element={ <Sections.Exhibition /> }
                >
                    <Route index element={
                        <Navigate to={ ROUTES.EXHIBITION_DETAILS } /> }
                    />
                    <Route path={ `${ ROUTES.EXHIBITION_DETAILS }` }
                           element={ <Pages.ExhibitionDetails /> }
                    >
                        <Route index
                               element={ <Pages.ExhibitionSub.Details /> }
                        />
                        <Route path = { `${ ROUTES.EXHIBITION_HALLS }/*` }>
                            <Route index
                                   element={ <Pages.ExhibitionSub.Halls /> }
                            />
                            <Route path = { `${ ROUTES.EXHIBITION_HALL }` }
                                   element={ <Pages.ExhibitionSub.Hall /> }
                            />
                        </Route>

                        <Route path    = { `${ ROUTES.EXHIBITION_TEAM }` }
                               element = { <Pages.ExhibitionSub.Team /> }
                        />
                        <Route path    = { `${ ROUTES.EXHIBITION_STATISTICS }` }
                               element = { <Pages.ExhibitionSub.Statistics /> }
                        />
                    </Route>
                    <Route
                        path={ `${ ROUTES.STAND_LIST }` }
                    >
                        <Route index
                               element = { <Pages.StandList /> }
                        />
                        <Route path    = { ROUTES.STAND_ITEM }
                               element = { <Pages.Stand /> }
                        >
                            <Route index
                                   element = { <Pages.StandSub.Details /> }
                            />
                            <Route path    = { `${ ROUTES.STAND_ITEM_EQUIPMENT }` }
                                   element = { <Pages.StandSub.Equipment /> }
                            />
                            <Route path    = { `${ ROUTES.STAND_ITEM_GRAPHIC }` }
                                   element = { <Pages.StandSub.Graphics /> }
                            />
                            <Route path    = { `${ ROUTES.STAND_ITEM_TASKS }` }
                                   element = { <Pages.StandSub.Tasks /> }
                            >
                                <Route path    = { `${ ROUTES.STAND_ITEM_TASK }` }
                                       element = { <Pages.StandSub.Tasks /> }
                                />
                            </Route>


                            <Route path    = { `${ ROUTES.STAND_ITEM_TYPE_INFO }` }
                                   element = { <Pages.StandSub.TypeInfo /> }
                            />
                        </Route>
                    </Route>
                    <Route path={ `${ ROUTES.GRAPHIC_LIST }`} element={ <Pages.GraphicList /> }/>
                    <Route path={ `${ ROUTES.CHECK_LIST }` }
                           element={ <Pages.CheckList /> }
                    >
                        <Route index element={
                            <Navigate to={ ROUTES.CHECK_LIST_EXPO_DATES } replace /> }
                        />
                        <Route path = { ROUTES.CHECK_LIST_EXPO_DATES }
                               element={ <Pages.CheckListSub.ExpoDates /> }
                        />
                        <Route path = { `${ ROUTES.CHECK_LIST_CRAFTS }` }
                               element={ <Pages.CheckListSub.Crafts /> }
                        />
                        <Route path = { `${ ROUTES.CHECK_LIST_SITE_PLANNING }` }
                               element={ <Pages.CheckListSub.SitePlanning /> }
                        />
                        <Route path = { `${ ROUTES.CHECK_LIST_PARTICIPANTS }` }
                               element={ <Pages.CheckListSub.Participants /> }
                        />
                        <Route path = { `${ ROUTES.CHECK_LIST_INVOICE }` }
                               element={ <Pages.CheckListSub.Invoice /> }
                        />
                        <Route path = { `${ ROUTES.CHECK_LIST_RECALCULATION }` }
                               element={ <Pages.CheckListSub.Recalculation /> }
                        />
                    </Route>
                </Route>
            </Route>

            <Route path="*" element={ <Pages.Page404 /> } />

        </Routes>
    );
};

export default AppRoutes;