import Page404 from "./404";
import { CheckListPage, CheckListSub } from "./check-list";
import {
    ExhibitionDetailsPage,
    ExhibitionSection,
    ExhibitionsPage,
    ExhibitionSub
} from "./exhibition";
import { GraphicListPage } from "./graphic";
import { StandListPage, StandPage, StandSub } from "./stand";

import "./pages.scss";

export const Sections = {
    Exhibition : ExhibitionSection
};

export const Pages = {
    Exhibitions : ExhibitionsPage,
    ExhibitionDetails : ExhibitionDetailsPage,
    ExhibitionSub,
    StandList : StandListPage,
    Stand : StandPage,
    StandSub,
    GraphicList : GraphicListPage,
    CheckList : CheckListPage,
    CheckListSub,
    Page404
};