import React, { JSX } from "react";

import { Drawer  } from "antd";

import { Icons } from "components/layout";

import { OutsideControlledDrawer } from "common/types";


const DrawerStandard : React.FC<OutsideControlledDrawer> = ({
        isOpen,
        close,
        extraClass = "",
        children,
        ...props
    }) : JSX.Element => {


    return(
        <Drawer
            closeIcon      = { <Icons.Cross /> }
            destroyOnClose = { true }
            className      = { `drawer-standard ${ extraClass } ${ props.extra ? "withExtra" : "" }` }
            open           = { isOpen }
            width          = { "auto" }
            onClose        = { () =>  close() }
            { ...props }
        >
            { children }
        </Drawer>
    );
};

export default DrawerStandard;