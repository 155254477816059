import dayjs, { Dayjs, OpUnitType } from "dayjs";

import { EDateType } from "../types";

import type { RangePickerProps } from "antd/es/date-picker";


const getDateObj = (dateStr : string, type : EDateType): Dayjs => {

    return dayjs(dateStr, getDateFormat(type));
};

type TCompareProps = {
    searchDate   : string
    currentDate ?: string
    unit        ?: OpUnitType
    type        ?: "same" | "before" | "after"
};

const compareDates = ({
        searchDate,
        currentDate,
        unit,
        type,
    }: TCompareProps): boolean => {

    let result = false;

    switch(type){
        case 'before':
            result = dayjs(currentDate).isBefore(searchDate, unit);
        break;

        case 'after':
            result = dayjs(currentDate).isAfter(searchDate, unit);
        break;

        case 'same':
        default:
            result = dayjs(currentDate).isSame(searchDate, unit);
    }

    return  result;
};

const getFormatedDate = (dateStr : string, type ?: EDateType) : string => {
    return dayjs(dateStr).format(getDateFormat(type || EDateType.DATE)).toString();
};

const getDateFormat = (type : EDateType) : string => {

    let format;

    switch (type){
        case EDateType.TIME:
            format = "HH:mm";
        break;

        case EDateType.DATETIME:
            format = "DD.MM.YYYY, hh:mm";
        break;

        case EDateType.DAYDATE:
            format = "ddd, DD.MM.YYYY";
        break;

        case EDateType.DAYDATETIME:
            format = "ddd, DD.MM.YYYY, hh:mm";
        break;

        case EDateType.MONTHDAYYEARTIME:
            format = "MMMM DD YYYY | hh:mm";
        break;

        case EDateType.MONTHDAYTIME:
            format = "MMMM DD | hh:mm";
        break;

        case EDateType.DATE:
        default : format = "DD.MM.YYYY";
    }

    return format;
};

const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current < dayjs().startOf('day');
};

const getDate = {
    fromStr : getDateObj,
    formated : getFormatedDate,
    format : getDateFormat,
    compare : compareDates,
    disabled : disabledDate,
    dateTypes : EDateType
};

export default getDate;