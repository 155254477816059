import React, { JSX } from "react";

import { TEquipmentItem, TEquipmentVariationItem } from "graphql/type/equipment-gql-types";

import { PhotoCarousel } from "components/service";


interface IEquipmentInfoBlockProps{
    equipment  : TEquipmentItem
    variation ?: TEquipmentVariationItem
}

const EquipmentInfoBlock : React.FC<IEquipmentInfoBlockProps> = ({ equipment, variation }) : JSX.Element => {

    return(
        <div className="equipment-info-block">
            <PhotoCarousel
                photos={equipment.getImages}
            />
            <h3>{equipment.title}</h3>
            { variation &&
                <div className="attr-grid grid-two-col">
                    {variation.title &&
                        <div>
                            <h4 className="label">Organizer name:</h4>
                            <p>{variation.title}</p>
                        </div>
                    }
                    <div>
                        <h4 className="label">Price:</h4>
                        <p>{variation.price} €</p>
                    </div>
                    <div>
                        <h4 className="label">Organizer №:</h4>
                        <p>{variation.article}</p>
                    </div>
                </div>
            }

            <div className="attr-grid grid-two-col">
                <div>
                    <h4 className="label">Navision №:</h4>
                    <p>{equipment.navision_number}</p>
                </div>
                <div>
                    <h4 className="label">Octacad №:</h4>
                    <p>{equipment.octacad_number}</p>
                </div>
                <div>
                    <h4 className="label">Supplier:</h4>
                    <p>{equipment.supplier.title}</p>
                </div>
                <div>
                <h4 className="label">Supplier №:</h4>
                    <p>{equipment.supplier_article}</p>
                </div>
            </div>
            {equipment.description &&
                <div className="description">
                    {equipment.description}
                </div>
            }
        </div>

    );
};


export default EquipmentInfoBlock;